import { gql } from "@apollo/client";
import React from "react";
import dayjs from "dayjs_with_plugins";
import styled from "styled-components";
import { cloneDeep } from "lodash";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { Typography, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { pink } from "@mui/material/colors";

const OWO = gql`
  query LunchOrdersByDate($nurseryId: ID!, $date: Date!) {
    oneWeeksOrders(date: $date, nurseryId: $nurseryId) {
      id
      lunchOrders {
        ... on EditableLunchOrder {
          id
          date
          totalMeals
          closed
        }
        ... on NonEditableLunchOrder {
          id
          date
          totalMeals
          closed
        }
      }
      teaOrders {
        ... on EditableTeaOrder {
          id
          date
          totalMeals
          closed
        }
        ... on NonEditableTeaOrder {
          id
          date
          totalMeals
          closed
        }
      }
    }
  }
`;

const Phone = styled(PhoneIcon)`
  position: relative;
  top: 6px;
  height: 18px;
`;

const Location = styled(LocationOnIcon)`
  position: relative;
  top: 6px;
  height: 18px;
`;

const SignatureCell = (props) => (
  <TableCell {...props}>
    <div style={{ border: "black solid 1px", height: "50px" }} />
  </TableCell>
);

export const PrintableDeliveryForm = (props) => {
  const { nursery, nurseryId, date } = props;

  return (
    <section style={{ breakAfter: "page", marginBottom: "30px" }} key={nursery.id}>
      <Typography variant="h5" style={{ marginBottom: "10px" }}>
        {nursery.name} - Delivery Form - {dayjs(date, "YYYY-MM-DD", true).format("dddd Do MMM")} -{" "}
        {dayjs(date, "YYYY-MM-DD", true).add(4, "day").format("dddd Do MMM")}
      </Typography>
      <div style={{ display: "flex", marginBottom: "30px" }}>
        <Typography component="p" style={{ marginRight: "10px" }}>
          <Phone />
          &nbsp;{nursery.phoneNumber.replace(/\s+/g, "")}
        </Typography>
        <Typography component="p">
          <Location />
          &nbsp;{nursery.address}
        </Typography>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              Total Lunches Received
            </TableCell>
            <TableCell style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              Total Teas Received
            </TableCell>
            <TableCell>Time of Delivery</TableCell>
            <TableCell>Signature</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <GraphqlQuery query={OWO} variables={{ nurseryId, date }} withError withloading>
            {({ data, error }) => {
              if (error) return <CentredErrorIcon />;
              /* We have to clone deep because something (apollo?) was complaining about
                mutation. The array.sort is destructive YAY. BUT I basically have no faith
                in the other available sorting options. Lodash doesn't allow a sorting fn
                which is silly, so I'm not 100% if the date casting will work as expected...
                Like I want the comparator to be isSameOrAfter. So I clone it. This is
                obviously bad but meh.
            */
              const sortedLunches = cloneDeep(data.oneWeeksOrders.lunchOrders).sort(
                (left, right) => (dayjs(left.date).isSameOrAfter(dayjs(right.date)) ? 1 : -1),
              );
              const sortedTeas = cloneDeep(data.oneWeeksOrders.teaOrders).sort((left, right) =>
                dayjs(left.date).isSameOrAfter(dayjs(right.date)) ? 1 : -1,
              );

              const mon_l = sortedLunches[0];
              const tues_l = sortedLunches[1];
              const weds_l = sortedLunches[2];
              const thurs_l = sortedLunches[3];
              const fri_l = sortedLunches[4];

              const mon_t = sortedTeas[0];
              const tues_t = sortedTeas[1];
              const weds_t = sortedTeas[2];
              const thurs_t = sortedTeas[3];
              const fri_t = sortedTeas[4];

              const monDate = dayjs(date, "YYYY-MM-DD", true).startOf("isoWeek");
              const tuesDate = dayjs(date, "YYYY-MM-DD", true).startOf("isoWeek").add(1, "day");
              const wedsDate = dayjs(date, "YYYY-MM-DD", true).startOf("isoWeek").add(2, "day");
              const thursDate = dayjs(date, "YYYY-MM-DD", true).startOf("isoWeek").add(3, "day");
              const friDate = dayjs(date, "YYYY-MM-DD", true).startOf("isoWeek").add(4, "day");
              return (
                <React.Fragment>
                  <TableRow>
                    <TableCell>{monDate.format("dddd Do")}</TableCell>
                    <TableCell style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                      <Typography variant="h5">
                        {mon_l && !mon_l.closed ? mon_l.totalMeals : " - "}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                      <Typography variant="h5">
                        {mon_t && !mon_t.closed ? mon_t.totalMeals : " - "}
                      </Typography>
                    </TableCell>
                    <SignatureCell />
                    <SignatureCell style={{ width: "350px" }} />
                  </TableRow>
                  <TableRow>
                    <TableCell>{tuesDate.format("dddd Do")}</TableCell>
                    <TableCell style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                      <Typography variant="h5">
                        {tues_l && !tues_l.closed ? tues_l.totalMeals : " - "}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                      <Typography variant="h5">
                        {tues_t && !tues_t.closed ? tues_t.totalMeals : " - "}
                      </Typography>
                    </TableCell>
                    <SignatureCell />
                    <SignatureCell style={{ width: "350px" }} />
                  </TableRow>
                  <TableRow>
                    <TableCell>{wedsDate.format("dddd Do")}</TableCell>
                    <TableCell style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                      <Typography variant="h5">
                        {weds_l && !weds_l.closed ? weds_l.totalMeals : " - "}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                      <Typography variant="h5">
                        {weds_t && !weds_t.closed ? weds_t.totalMeals : " - "}
                      </Typography>
                    </TableCell>
                    <SignatureCell />
                    <SignatureCell style={{ width: "350px" }} />
                  </TableRow>
                  <TableRow>
                    <TableCell>{thursDate.format("dddd Do")}</TableCell>
                    <TableCell style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                      <Typography variant="h5">
                        {thurs_l && !thurs_l.closed ? thurs_l.totalMeals : " - "}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                      <Typography variant="h5">
                        {thurs_t && !thurs_t.closed ? thurs_t.totalMeals : " - "}
                      </Typography>
                    </TableCell>
                    <SignatureCell />
                    <SignatureCell style={{ width: "350px" }} />
                  </TableRow>
                  <TableRow>
                    <TableCell>{friDate.format("dddd Do")}</TableCell>
                    <TableCell style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                      <Typography variant="h5">
                        {fri_l && !fri_l.closed ? fri_l.totalMeals : " - "}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                      <Typography variant="h5">
                        {fri_t && !fri_t.closed ? fri_t.totalMeals : " - "}
                      </Typography>
                    </TableCell>
                    <SignatureCell />
                    <SignatureCell style={{ width: "350px" }} />
                  </TableRow>
                </React.Fragment>
              );
            }}
          </GraphqlQuery>
        </TableBody>
      </Table>
      <section style={{ marginTop: "30px" }}>
        <Typography style={{ marginBottom: "15px" }}>
          Hungry Monsters acknowledges that the quantity & condition of tubs and tins within the
          insulated boxes can&apos;t be checked until the boxes are unsealed, just before serving.
        </Typography>
        <Typography style={{ marginBottom: "15px" }}>
          By signing above you are agreeing to check that the number of tubs, tins etc. within the
          boxes tally with the card affixed to the box, as this will be what we expect to collect.
          If you feel there is a discrepancy, please let us know as soon as possible.
        </Typography>
        <Typography style={{ marginBottom: "15px", color: pink[500] }}>
          Any left equipment which is returned damamged or not returned at all will charged at
          cost.
        </Typography>
      </section>
    </section>
  );
};
