import { gql } from "@apollo/client";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useGraphqlSubscription } from "graphqlUtils/useGraphqlSubscription";
import {
  Button,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  Chip,
  TableRow,
  Switch,
  TableCell,
  Typography,
  InputLabel,
  Paper,
} from "@mui/material";
import { COLOURS, SHADES } from "containers/menus/colours";
import withStyles from "@mui/styles/withStyles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const useNotServedStyles = makeStyles({
  icon: { height: "20px", position: "relative" },
  info: { marginBottom: "30px" },
  chip: { marginRight: "10px", marginTop: "5px", marginBottom: "5px" },
  moveIcon: {
    height: "15px",
    position: "relative",
    top: "4px",
    cursor: "move",
  },
  alignTop: { verticalAlign: "top" },
});

const HM_CLOSED_SUB = gql`
  subscription IsHmClosedSubscription($date: Date!) {
    isHmClosedSubscription(date: $date)
  }
`;

const HM_CLOSED = gql`
  query IsClosed($date: Date!) {
    isHmClosed(date: $date)
  }
`;

const ColouredSwitch = withStyles({
  switchBase: {
    color: (props) => props.colour,
    "&.checked": {
      color: (props) => props.colour,
    },
    "&.checked + .track": {
      backgroundColor: (props) => props.colour,
    },
  },
  checked: {
    color: (props) => props.colour,
  },
  track: {
    color: (props) => props.colour,
  },
})(Switch);

/*
  This is explicitly for when creating / editing a menu, we want to be able to
  see how many unserved children there would be for a given menu, given the
  orders for that given week. This uses the query "what would it look like"
  for a given menu for the week for a given day. We look at the available meals
  on the menu as say if there are any non-served people.
*/

export const ChildrenNotServedTable = (props) => {
  const {
    tableStyles,
    onChildHighlight,
    highlightedChildren,
    filteredChildren,
    date,
    filterBy,
    filterByValue,
    setDays,
    days,
  } = props;
  const { chip, table, alignTop } = useNotServedStyles();
  useGraphqlSubscription({
    subscription: HM_CLOSED_SUB,
    variables: { date: date.format("YYYY-MM-DD") },
    onSubscriptionData: ({ client, subscriptionData }) => {
      client.writeQuery({
        query: HM_CLOSED,
        variables: { date },
        data: { isHmClosed: subscriptionData.data.isHmClosedSubscription },
      });
    },
  });

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "rgba(246, 247, 248, 0.5)",
        border: "#f5f5f5 solid 2px",
        borderRadius: "3px",
        padding: "30px",
        marginBottom: "15px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "30px",
        }}
      >
        <FormControl style={{ marginRight: "15px" }}>
          <InputLabel id="demo-simple-select-label">Filter By Meal Type</InputLabel>
          <Select
            value={filterByValue}
            label="Filter By Meal Type"
            onChange={(event) => {
              filterBy(event.target.value);
            }}
          >
            <MenuItem value={"lunch"}>Lunch</MenuItem>
            <MenuItem value={"tea"}>Tea</MenuItem>
            <MenuItem value={"tea_and_lunch"}>Tea and Lunch</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="button" style={{ marginRight: "15px", marginTop: "0px" }}>
          Filter By Day
        </Typography>
        <Button
          onClick={() => {
            setDays({ ...days, Monday: !days["Monday"] });
          }}
          variant="outlined"
          color={days["Monday"] ? "primary" : "secondary"}
        >
          Mon
        </Button>
        <Button
          onClick={() => {
            setDays({ ...days, Tuesday: !days["Tuesday"] });
          }}
          variant="outlined"
          color={days["Tuesday"] ? "primary" : "secondary"}
          style={{ marginLeft: "10px" }}
        >
          Tues
        </Button>
        <Button
          onClick={() => {
            setDays({ ...days, Wednesday: !days["Wednesday"] });
          }}
          variant="outlined"
          color={days["Wednesday"] ? "primary" : "secondary"}
          style={{ marginLeft: "10px" }}
        >
          Weds
        </Button>
        <Button
          onClick={() => {
            setDays({ ...days, Thursday: !days["Thursday"] });
          }}
          variant="outlined"
          color={days["Thursday"] ? "primary" : "secondary"}
          style={{ marginLeft: "10px" }}
        >
          Thurs
        </Button>
        <Button
          onClick={() => {
            setDays({ ...days, Friday: !days["Friday"] });
          }}
          variant="outlined"
          color={days["Friday"] ? "primary" : "secondary"}
          style={{ marginLeft: "10px" }}
        >
          Fri
        </Button>
      </div>

      <TableContainer className={tableStyles} component={Paper}>
        <Table stickyHeader size="small" className={table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "145px" }}>Nursery</TableCell>
              <TableCell style={{ width: "120px" }}>Name</TableCell>
              <TableCell>Sensitivities</TableCell>
              <TableCell>Highlight Inedible Components</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredChildren.map((child, index) => {
              const { id, name, nursery, sensitivities, inedibleComponentsForChildOnMenu } =
                child;

              // This essentially cycles through the list of colours, so even if two children
              // had the same colour they would be spaced by other colors in between. Like a rainbow
              const colourIndex = index % COLOURS.length;
              const shadesIndex = index % SHADES.length;
              const childColour = COLOURS[colourIndex][SHADES[shadesIndex]];
              const onClick = () => {
                onChildHighlight(
                  id,
                  inedibleComponentsForChildOnMenu.map(({ name }) => name),
                  childColour,
                );
              };
              return (
                <TableRow key={id}>
                  <TableCell className={alignTop}>{nursery.name}</TableCell>
                  <TableCell className={alignTop}>{name}</TableCell>
                  <TableCell>
                    {sensitivities.map((sensitivity) => {
                      return (
                        <Chip
                          size="small"
                          className={chip}
                          key={sensitivity.id}
                          color="secondary"
                          variant="outlined"
                          label={sensitivity.name}
                        />
                      );
                    })}
                  </TableCell>
                  <TableCell align="left" className={alignTop}>
                    <ColouredSwitch
                      colour={childColour}
                      checked={Boolean(highlightedChildren[id])}
                      onClick={onClick}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
