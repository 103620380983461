import React from "react";
import dayjs from "dayjs_with_plugins";
import { Routes, Route, Navigate } from "react-router-dom";
import { RequireAuth } from "containers/routes/LoggedInRoute";
import { FourOhFour } from "components/FourOhFour";
import { TemperaturesPage } from "pages/deliveryDriver/TemperaturesPage";
import { DeliveryFormsPage } from "pages/deliveryDriver/DeliveryFormsPage";
import { PrintableDietAdvice } from "pages/admin/PrintableDietAdvice";
import { PackingReport } from "pages/deliveryDriver/PackingReport";
import { KitchenDistributionReport } from "pages/deliveryDriver/KitchenDistributionReport";

const RedirectToToday = () => {
  return <Navigate to={`/temperatures/date/${dayjs().format("YYYY-MM-DD")}`} />;
};

export const DeliveryDriverHome = () => {
  return (
    <Routes>
      <Route
        exact
        path="*"
        element={
          <RequireAuth>
            <RedirectToToday />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/temperatures/date/:date"
        element={
          <RequireAuth>
            <TemperaturesPage />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/delivery-forms/date/:date"
        element={
          <RequireAuth>
            <DeliveryFormsPage />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/kitchen-distribution-report/date/:date"
        element={
          <RequireAuth>
            <KitchenDistributionReport />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/packing-reports/date/:date"
        element={
          <RequireAuth>
            <PackingReport />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/printable-diet-advice/date/:date"
        element={
          <RequireAuth>
            <PrintableDietAdvice />
          </RequireAuth>
        }
      />
      <Route element={<FourOhFour />} />
    </Routes>
  );
};
