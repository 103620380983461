import React from "react";
import { gql } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { H3 } from "components/typography/H3";
import { TextInput } from "components/inputs/Inputs";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  Typography,
  useMediaQuery,
} from "@mui/material";

const UPDATE_TIN = gql`
  mutation UpdateTin($input: UpdateTinInput!) {
    updateTin(input: $input) {
      id
      name
    }
  }
`;

const useStyles = makeStyles({
  dialogContent: { display: "flex", flexDirection: "column" },
  inputStyle: { width: "100%", maxWidth: "350px", marginBottom: "15px" },
  heading: { marginLeft: "30px", marginBottom: "15px" },
});

export const EditTinModal = (props) => {
  const { open, onCancel, refetchQueries, onSubmit, tin } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { inputStyle, dialogContent, heading } = useStyles();
  const [name, updateName] = React.useState();

  const resetForm = () => {
    updateName();
  };

  React.useEffect(() => {
    updateName(tin.name);
  }, [tin.name]);

  return (
    <Dialog scroll="paper" disableEscapeKeyDown fullScreen={fullScreen} fullWidth open={open}>
      <DialogTitle>
        <Typography variant="h4" component="h4">
          Edit Tin
        </Typography>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <H3 className={heading}>Nursery</H3>
        <TextInput
          required
          autoFocus
          variant="outlined"
          className={inputStyle}
          label="Name..."
          value={name}
          onChange={(event) => updateName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            onCancel();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <GraphqlMutation mutation={UPDATE_TIN} withError>
          {(updateTin) => {
            const submit = async () => {
              if (!name) return;
              const variables = { input: { id: tin.id, name } };
              const result = await updateTin({ variables, refetchQueries });
              if (result.data) {
                resetForm();
                onSubmit();
              }
              return result;
            };

            return (
              <Button disabled={!name} onClick={submit} color="primary">
                Save
              </Button>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </Dialog>
  );
};
