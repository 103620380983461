import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { ulid } from "ulid";
import { isEmpty } from "lodash";
import { IconButton, Button, Grid } from "@mui/material";
import { ComponentSelect } from "containers/menus/ComponentSelect";
import OpenWithIcon from "@mui/icons-material/OpenWith";

const useSlotStyles = makeStyles({
  input: { marginBottom: "15px" },
  btn: { marginRight: "15px" },
});

const SlowMealComponentSlot = (props) => {
  const {
    options,
    onMealComponentChange,
    columnNumber,
    onSubstitutionChange,
    mealComponent,
    componentBorders,
    updateBorders,
  } = props;
  const { input, btn } = useSlotStyles();

  const selectMealComponent = (updated) => {
    if (updated) {
      // add // update
      onMealComponentChange({ ...mealComponent, ...updated });
    } else {
      // Delete
      onMealComponentChange(null);
    }
  };

  const selectSubstitution = (existing, order) => (substitution) => {
    const existingSubstitutions = mealComponent.substitutions;
    const newSubs = existingSubstitutions.map((sub) => {
      if (sub.id === existing.id) {
        return {
          id: existing.id,
          substitutionId: substitution.id,
          substitution: substitution,
          order,
        };
      } else {
        return sub;
      }
    });
    onSubstitutionChange({ ...mealComponent, substitutions: newSubs });
  };

  const addSubstitution = () => {
    const existingSubstitutions = mealComponent.substitutions;
    const newSubs = [...existingSubstitutions, { id: ulid() }];
    onSubstitutionChange({ ...mealComponent, substitutions: newSubs });
  };

  const removeSubstitute = (substitution) => () => {
    const newSubs = mealComponent.substitutions.filter(({ id: i }) => i !== substitution.id);
    onSubstitutionChange({ ...mealComponent, substitutions: newSubs });
  };

  return (
    <React.Fragment>
      <OpenWithIcon />
      <ComponentSelect
        updateBorders={updateBorders}
        componentBorders={componentBorders}
        label="Choose a meal component"
        placeholder="Type to filter the list..."
        className={input}
        options={options}
        value={mealComponent.mealComponent || null}
        onChange={selectMealComponent}
      />
      {mealComponent.substitutions &&
        mealComponent.substitutions.map((substitution, index) => {
          return (
            <Grid container key={substitution.id}>
              <Grid item xs={11}>
                <ComponentSelect
                  updateBorders={updateBorders}
                  componentBorders={componentBorders}
                  label="Choose a substitute"
                  placeholder="Type to filter the list..."
                  className={input}
                  // If it's just been created then it wont have a name but will have an id.
                  value={substitution.substitution || null}
                  options={options}
                  variant="outlined"
                  onChange={selectSubstitution(substitution, index + columnNumber)}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={removeSubstitute(substitution)} className={btn} size="large">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}

      <Button
        // If we haven't selected a mealComponent or if we have used up all available options.
        // Or if it's disabled like in a tea menu.
        disabled={isEmpty(mealComponent.mealComponent) || isEmpty(options)}
        size="small"
        color="primary"
        variant="outlined"
        onClick={addSubstitution}
      >
        Substitution&nbsp;&nbsp;
        <AddIcon />
      </Button>
    </React.Fragment>
  );
};
// Also does nothing it seems..
export const MealComponentSlot = React.memo((props) => {
  return <SlowMealComponentSlot {...props} />;
});
