import { gql } from "@apollo/client";
import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { InfoNextToText } from "components/InfoNextToText";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Chip,
  Tooltip,
  TextField,
  Typography,
} from "@mui/material";
import { some, orderBy } from "lodash";

const UPDATE_CHILD = gql`
  mutation UpdateChildWithSensitivities($input: UpdateChildInput!) {
    updateChildWithSensitivities(input: $input) {
      id
      name
      sensitivities {
        id
        name
        order
      }
    }
  }
`;

export const ACTIVE_SENSITIVITIES = gql`
  query Sensitivities($active: Boolean!) {
    sensitivities(active: $active) {
      id
      name
      order
    }
  }
`;

const useStyles = makeStyles({
  inputStyles: { marginBottom: "15px", width: "66%" },
});

export const EditChildModal = (props) => {
  const { open, onCancel, onSubmit, refetchQueries, child } = props;

  const { inputStyles } = useStyles();
  const [currentName, updateName] = React.useState("");
  const [sensitivities, updateSensitivities] = React.useState([]);
  React.useEffect(() => updateName(child.name), [child.name, updateName]);
  React.useEffect(
    () => updateSensitivities(child.sensitivities),
    [child.sensitivities, updateSensitivities],
  );
  const readOnlySensitivities = sensitivities.filter((sen) => sen.readOnly);
  return (
    <Dialog maxWidth="md" disableEscapeKeyDown fullWidth open={open}>
      <DialogTitle>
        <Typography variant="h4" component="h4">
          Edit a child
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          required
          className={inputStyles}
          label="name"
          value={currentName}
          onChange={(event) => updateName(event.target.value)}
        />
      </DialogContent>
      <DialogContent>
        <Typography variant="body1" component="p">
          Please add a name and an initial. If there are two children with the same name and
          initial, you can use two letters as the second initial.
        </Typography>
        <Typography variant="body2" component="p">
          <InfoNextToText /> Please don’t give the child’s full last name.
        </Typography>
      </DialogContent>
      <DialogContent>
        <Typography variant="h4" component="h4">
          Sensitivities
        </Typography>
      </DialogContent>
      <DialogContent>
        <Typography style={{ marginBottom: "10px" }} variant="body1" component="p">
          The sensitivities marked represent foods that the child cannot eat. If you change a
          sensitivity, this will also update the sensitivities for all your future orders, for any
          child whose name exactly matches the name given above.
        </Typography>
        {readOnlySensitivities.length > 0 && (
          <Typography style={{ marginBottom: "10px" }} variant="body1" component="p">
            The grey sensitivities below has been specially added for this child and will be
            checked for when choosing a meal for them.
          </Typography>
        )}
      </DialogContent>
      <DialogContent>
        <GraphqlQuery
          fetchPolicy="network-only"
          withLoading
          withError
          query={ACTIVE_SENSITIVITIES}
          variables={{ active: true }}
        >
          {({ data, error }) => {
            if (error) {
              return <CentredErrorIcon />;
            }

            return (
              <Autocomplete
                multiple
                blurOnSelect
                filterSelectedOptions
                value={orderBy(sensitivities, ["readOnly"], ["desc"])}
                options={data.sensitivities}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => {
                  // We will still get a warning when there are readOnly options because
                  // they wont be in the list of options for sensitivities, but they will still
                  // be in the value. I think it's fine though.
                  return option.name === value.name;
                }}
                renderTags={(selectedValues, getTagProps) => {
                  return selectedValues.map((option, index) => {
                    const isReadOnly = some(readOnlySensitivities, (s) => s.id === option.id);
                    if (isReadOnly) {
                      return (
                        <Tooltip
                          key={option.id}
                          title={`This has been specially added for ${currentName}`}
                        >
                          <Chip
                            // This prop comes first because we override some of them.
                            {...getTagProps({ index })}
                            variant="outlined"
                            color="default"
                            label={option.name}
                          />
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Chip
                          key={option.id}
                          variant="outlined"
                          color="secondary"
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      );
                    }
                  });
                }}
                onChange={(_, values) => {
                  updateSensitivities(values);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      required
                      label="Choose all sensitivities that apply"
                      variant="outlined"
                      placeholder="Type to filter the list..."
                    />
                  );
                }}
                slotProps={{
                  chip: { variant: "outlined", color: "secondary" },
                }}
              />
            );
          }}
        </GraphqlQuery>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()} color="primary">
          Cancel
        </Button>
        <GraphqlMutation mutation={UPDATE_CHILD} withError>
          {(updateChild) => {
            const variables = {
              input: {
                id: child.id,
                name: currentName,
                sensitivities: sensitivities.map(({ id, name, order }) => ({ id, name, order })),
              },
            };

            const submit = async () => {
              const result = await updateChild({ variables, refetchQueries });
              if (result.data) {
                onSubmit();
              }
            };

            return (
              <Button onClick={submit} color="primary">
                Save
              </Button>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </Dialog>
  );
};
