import React from "react";
import { gql } from "@apollo/client";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { PageHeading } from "components/PageHeading";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import AddIcon from "@mui/icons-material/Add";
import { AddTinModal } from "containers/tins/AddTinModal";
import { AddPortionUnit } from "containers/tins/AddPortionUnit";
import { EditPortionUnit } from "containers/tins/EditPortionUnit";
import { EditTinModal } from "containers/tins/EditTinModal";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { Icons } from "icons/Icons";
import { H3 } from "components/typography/H3";
import {
  Typography,
  Link as MaterialUiLink,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  IconButton,
  Button,
} from "@mui/material";

const TINS = gql`
  query Tins {
    tins {
      id
      name
    }
  }
`;

const DELETE_TIN = gql`
  mutation DeleteTin($id: ID!) {
    deleteTin(id: $id) {
      id
    }
  }
`;

const PORTION_UNITS = gql`
  query PortionUnits {
    portionUnits {
      id
      name
    }
  }
`;

const DELETE_UNIT = gql`
  mutation DeletePortionUni($id: ID!) {
    deletePortionUnit(id: $id) {
      id
      name
    }
  }
`;

const useStyles = makeStyles({
  icon: { height: "20px", position: "relative" },
  deleteBtn: { marginRight: "8px" },
});

const PortionUnits = () => {
  const classes = useStyles();
  const [open, setModalOpen] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [editingUnit, setUnitToEdit] = React.useState("");
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const closeEdit = () => setEditModalOpen(false);

  const openEdit = (tin) => {
    setUnitToEdit(tin);
    setEditModalOpen(true);
  };

  const refetchQueries = [{ query: PORTION_UNITS }];

  return (
    <React.Fragment>
      <H3 style={{ marginBottom: "30px" }}>Portion Units</H3>
      <Typography variant="body1" style={{ marginBottom: "30px" }}>
        Portion units are the units that a meal component can be served in. For example pasta
        might be in &quot;grams&quot; but a cake might be a &quot;slice&quot;.
      </Typography>
      <GraphqlQuery fetchPolicy="network-only" query={PORTION_UNITS} variables={{}} withError>
        {({ data, error }) => {
          if (error) return <CentredErrorIcon />;
          const { portionUnits } = data;
          return (
            <Table style={{ marginBottom: "15px" }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Portion Unit Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {portionUnits.map((unit) => {
                  const { name, id } = unit;
                  return (
                    <TableRow key={id}>
                      <TableCell>
                        <GraphqlMutation mutation={DELETE_UNIT}>
                          {(mutation) => {
                            return (
                              <IconButton
                                className={classes.deleteBtn}
                                aria-label="delete"
                                onClick={async () => {
                                  await mutation({ variables: { id }, refetchQueries });
                                }}
                                size="large"
                              >
                                <Icons.DeleteIcon className={classes.icon} />
                              </IconButton>
                            );
                          }}
                        </GraphqlMutation>
                        <MaterialUiLink
                          style={{ cursor: "pointer" }}
                          onClick={() => openEdit(unit)}
                          component="span"
                        >
                          {name}
                        </MaterialUiLink>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          );
        }}
      </GraphqlQuery>
      <Button
        variant="outlined"
        color="primary"
        aria-label="add"
        onClick={openModal}
        style={{ marginBottom: "30px" }}
      >
        Add Tin&nbsp;&nbsp;
        <AddIcon />
      </Button>
      <AddPortionUnit
        open={open}
        onCancel={closeModal}
        onSubmit={closeModal}
        refetchQueries={refetchQueries}
      />
      {editModalOpen && (
        <EditPortionUnit
          unit={editingUnit}
          open={editModalOpen}
          onCancel={closeEdit}
          onSubmit={closeEdit}
          refetchQueries={refetchQueries}
        />
      )}
    </React.Fragment>
  );
};

export const Tins = () => {
  const classes = useStyles();
  const [open, setModalOpen] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [editingTin, setTinToEdit] = React.useState("");
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const closeEdit = () => setEditModalOpen(false);

  const openEdit = (tin) => {
    setTinToEdit(tin);
    setEditModalOpen(true);
  };

  const refetchQueries = [{ query: TINS }];

  return (
    <PageHeading maxWidth="md" heading="Tins And Portion Units">
      <H3 style={{ marginBottom: "30px" }}>Tins</H3>
      <Typography variant="body1" style={{ marginBottom: "30px" }}>
        Standard sized tins are used to package up hot meal components. You can create tins and
        name them here then specify how many of a given meal component fits into each of the tins
        on the{" "}
        <Link to="/portion-sizes">
          <MaterialUiLink component="span">Portion Sizes page.</MaterialUiLink>
        </Link>
      </Typography>
      <GraphqlQuery fetchPolicy="network-only" query={TINS} variables={{}} withError>
        {({ data, error }) => {
          if (error) return <CentredErrorIcon />;

          const { tins } = data;
          return (
            <Table style={{ marginBottom: "15px" }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Tin Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tins.map((tin) => {
                  const { name, id } = tin;
                  return (
                    <TableRow key={id}>
                      <TableCell>
                        <GraphqlMutation mutation={DELETE_TIN}>
                          {(mutation) => {
                            return (
                              <IconButton
                                className={classes.deleteBtn}
                                aria-label="delete"
                                onClick={async () => {
                                  await mutation({ variables: { id }, refetchQueries });
                                }}
                                size="large"
                              >
                                <Icons.DeleteIcon className={classes.icon} />
                              </IconButton>
                            );
                          }}
                        </GraphqlMutation>
                        <MaterialUiLink
                          style={{ cursor: "pointer" }}
                          onClick={() => openEdit(tin)}
                          component="span"
                        >
                          {name}
                        </MaterialUiLink>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          );
        }}
      </GraphqlQuery>
      <Button
        variant="outlined"
        color="primary"
        aria-label="add"
        onClick={openModal}
        style={{ marginBottom: "30px" }}
      >
        Add Tin&nbsp;&nbsp;
        <AddIcon />
      </Button>
      <AddTinModal
        open={open}
        onCancel={closeModal}
        onSubmit={closeModal}
        refetchQueries={refetchQueries}
      />
      {editModalOpen && (
        <EditTinModal
          tin={editingTin}
          open={editModalOpen}
          onCancel={closeEdit}
          onSubmit={closeEdit}
          refetchQueries={refetchQueries}
        />
      )}
      <PortionUnits />
    </PageHeading>
  );
};
