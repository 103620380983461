import React from "react";
import { NavBar } from "components/navigation/NavBar";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { Loading } from "components/Loading";
import { gql } from "@apollo/client";
import styled from "styled-components";
import { Typography } from "@mui/material";

const HOME_PDF = gql`
  query HomePdf {
    homePdf
  }
`;

const Centre = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // navbar height
  height: calc(100vh - 64px);
`;

const AlsoCentered = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PDFHome = () => {
  return (
    <GraphqlQuery query={HOME_PDF} variables={{}} withLoading={false}>
      {({ data, loading, error }) => {
        if (error) return <CentredErrorIcon />;
        if (loading)
          return (
            <Centre>
              <AlsoCentered>
                <Loading />
                <Typography variant="h5">Loading Hungry Monsters...</Typography>
              </AlsoCentered>
            </Centre>
          );
        const { homePdf } = data;
        const url = `${process.env.REACT_APP_BASE_BACKEND_URL}${homePdf}`;

        return (
          <React.Fragment>
            {/* We dont want to show the navbar until the PDF is loaded. */}
            <NavBar />
            <img
              style={{
                width: "100%",
                padding: "0px",
                margin: "0 auto",
                display: "block",
                maxWidth: "3840px",
              }}
              src={url}
              alt="homepage"
            />
          </React.Fragment>
        );
      }}
    </GraphqlQuery>
  );
};
