import { gql } from "@apollo/client";
import React from "react";
import dayjs from "dayjs_with_plugins";
import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import { NumberInput } from "components/inputs/Inputs";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const TableWrapper = styled.div`
  background-color: #fafafa;
  margin-bottom: 30px;
`;

const Div = styled.div`
  padding-top: 30px;
`;

const useStyles = makeStyles({
  tableStyles: {
    maxWidth: "750px",
  },
  inputStyles: {
    maxWidth: "60px",
    textAlign: "center",
  },
});

const UPDATE_REGULAR_NUMBERS = gql`
  mutation UpdateRegularNumbers($input: UpdateRegularLunchNumbersInput!) {
    updateRegularLunchNumbers(input: $input) {
      ... on EditableLunchOrder {
        id
        regularMeals
        specialMeals
        totalMeals
      }
      ... on NonEditableLunchOrder {
        id
        date
        regularMeals
        specialMeals
        totalMeals
      }
    }
  }
`;

// This is always regularMeals as that's the only one we input data into.
const InputCell = (props) => {
  const { lunchOrder } = props;
  const { closed, regularMeals } = lunchOrder;
  switch (lunchOrder.__typename) {
    case "EditableLunchOrder":
      return lunchOrder.submittedDatetime ? (
        <NumberCell closed={closed} value={regularMeals} />
      ) : (
        <EditableRegularLunchMeals lunchOrder={lunchOrder} />
      );
    case "NonEditableLunchOrder":
      return <NumberCell closed={closed} value={regularMeals} />;
    default:
      return null;
  }
};

const NumberCell = (props) => {
  const { value, closed } = props;

  return (
    <TableCell>
      {closed ? <Typography>&nbsp;-</Typography> : <Typography>{value}</Typography>}
    </TableCell>
  );
};

const EditableRegularLunchMeals = (props) => {
  const { lunchOrder } = props;
  const { inputStyles } = useStyles();
  const { closed, regularMeals, id } = lunchOrder;

  if (closed) {
    return (
      <TableCell>
        <Typography>Closed</Typography>
      </TableCell>
    );
  }

  return (
    <TableCell>
      <GraphqlMutation withError mutation={UPDATE_REGULAR_NUMBERS}>
        {(mutation) => {
          return (
            <NumberInput
              dataTestid={`regular-meals-input-${id}`}
              margin="dense"
              className={inputStyles}
              value={regularMeals}
              onBlur={async (state) => {
                await mutation({
                  // This makes it easy to roll back the change in case of failure
                  optimisticResponse: {
                    __typename: "Mutation",
                    updateRegularLunchNumbers: {
                      // the old one...
                      ...lunchOrder,
                      // Plus the new from state. But where is the state?
                      // It's in the number component, gets passed to the onBlur
                      regularMeals: state,
                    },
                  },
                  variables: {
                    input: {
                      lunchOrderId: id,
                      regularMeals: state,
                    },
                  },
                });
              }}
            />
          );
        }}
      </GraphqlMutation>
    </TableCell>
  );
};

export const OrderNumbersTable = (props) => {
  const {
    oneWeeksOrders: { lunchOrders },
  } = props;
  const { tableStyles } = useStyles();

  return (
    <Div>
      <TableContainer component={TableWrapper} className={tableStyles}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              {lunchOrders.map((lunchOrder) => {
                return (
                  <TableCell key={lunchOrder.id}>
                    {dayjs(lunchOrder.date, "YYYY-MM-DD").format("ddd")}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <TableCell>Regular Meals</TableCell>
              {lunchOrders.map((lunchOrder) => {
                return <InputCell key={lunchOrder.id} lunchOrder={lunchOrder} />;
              })}
            </TableRow>
            <TableRow>
              <TableCell>Special Meals</TableCell>
              {lunchOrders.map((lunchOrder) => {
                return (
                  <NumberCell
                    key={lunchOrder.id}
                    closed={lunchOrder.closed}
                    value={lunchOrder.specialMeals}
                  />
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell>Total Meals</TableCell>
              {lunchOrders.map((lunchOrder) => {
                return (
                  <NumberCell
                    key={lunchOrder.id}
                    closed={lunchOrder.closed}
                    value={lunchOrder.totalMeals}
                  />
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Div>
  );
};
