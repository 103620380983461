import { gql } from "@apollo/client";
import React from "react";
import dayjs from "dayjs_with_plugins";
import { useParams } from "react-router-dom";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { PageHeading } from "components/PageHeading";
import { DatePickerSection } from "components/DatePickerSection";
import { NumberInput } from "components/inputs/Inputs";
import {
  Typography,
  TableBody,
  TableRow,
  TextField,
  TableCell,
  Table,
  TableHead,
} from "@mui/material";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { TimePicker } from "@mui/x-date-pickers";

const LUNCH_ITEMS = gql`
  query UniqueLunchDeliveryComponents($date: Date!) {
    uniqueLunchDeliveryComponents(date: $date) {
      id
      name
      temperature(date: $date)
      packedAt(date: $date)
      packedBy(date: $date)
    }
  }
`;

const TEA_ITEMS = gql`
  query UniqueTeaDeliveryComponents($date: Date!) {
    uniqueTeaDeliveryComponents(date: $date) {
      id
      name
      temperature(date: $date)
      packedAt(date: $date)
      packedBy(date: $date)
    }
  }
`;

const RECORD_LUNCH_DELIVERY_TEMP = gql`
  mutation RecordDeliveryTemperature($input: RecordDeliveryTempInput!, $date: Date!) {
    recordLunchDeliveryTemperature(input: $input, date: $date) {
      id
      temperature(date: $date)
      packedAt(date: $date)
      packedBy(date: $date)
    }
  }
`;

const RECORD_TEA_DELIVERY_TEMP = gql`
  mutation RecordDeliveryTemperature($input: RecordDeliveryTempInput!, $date: Date!) {
    recordTeaDeliveryTemperature(input: $input, date: $date) {
      id
      temperature(date: $date)
      packedAt(date: $date)
      packedBy(date: $date)
    }
  }
`;

const Row = (props) => {
  const { date, mealComponent, mutation } = props;
  const [packedAt, setPacked] = React.useState(mealComponent.packedAt);
  const [temperature, setTemp] = React.useState(mealComponent.temperature);
  const [packedBy, setPackedBy] = React.useState(mealComponent.packedBy);
  const { name, id } = mealComponent;

  React.useEffect(() => {
    setPackedBy(mealComponent.packedBy);
    setTemp(mealComponent.temperature);
    setPacked(mealComponent.packedAt);
  }, [mealComponent, setPacked, setTemp, setPackedBy]);

  const onBlur = (mutation) => async () => {
    await mutation({
      variables: {
        date,
        input: {
          date,
          mealComponentId: id,
          packedAt,
          packedBy,
          temperature,
        },
      },
    });
  };

  return (
    <TableRow key={id}>
      <TableCell>{name}</TableCell>
      <TableCell>
        <GraphqlMutation mutation={mutation} withError>
          {(recordPackedAt) => {
            return (
              <TimePicker
                required
                ampmInClock={false}
                closeOnSelect={false}
                margin="dense"
                label="Packed At"
                onBlur={onBlur(recordPackedAt)}
                onChange={(date) => setPacked(date)}
                value={dayjs(packedAt, true)}
                onAccept={onBlur(recordPackedAt)}
              />
            );
          }}
        </GraphqlMutation>
      </TableCell>
      <TableCell>
        <GraphqlMutation mutation={mutation} withError>
          {(recordPackedBy, { loading }) => {
            return (
              <TextField
                required
                disabled={loading}
                label="Packed by"
                onChange={(e) => setPackedBy(e.target.value || "")}
                value={packedBy || ""}
                onBlur={onBlur(recordPackedBy)}
              />
            );
          }}
        </GraphqlMutation>
      </TableCell>
      <TableCell>
        <GraphqlMutation mutation={mutation} withError>
          {(recordTemp, { loading }) => {
            const onBlur = async (value) => {
              await recordTemp({
                variables: {
                  date,
                  input: {
                    date,
                    mealComponentId: id,
                    packedAt,
                    packedBy,
                    temperature: value,
                  },
                },
              });
            };
            return (
              <NumberInput
                disabled={loading}
                style={{ maxWidth: "56px" }}
                value={temperature}
                onBlur={onBlur}
              />
            );
          }}
        </GraphqlMutation>
      </TableCell>
    </TableRow>
  );
};

export const TemperaturesPage = () => {
  const { date } = useParams();
  return (
    <PageHeading heading="Temperatures">
      {/* Random earliest date but not wrong. No significance, just better to pick something. */}
      <DatePickerSection type="daily" earliestDate="2020-03-23" disableArrowKeys={false} />
      <Typography style={{ marginBottom: "30px" }}>
        Here you can record the temperatures for all food items being delivered today. Each food
        item should have a temperature and you should mark who is recording the temperatures and
        at what time the food items were packed. Past temperatures are not editable.
      </Typography>
      <GraphqlQuery query={LUNCH_ITEMS} variables={{ date }} withLoading withError>
        {({ data: lunchData, error }) => {
          if (error) return <CentredErrorIcon />;

          return (
            <GraphqlQuery query={TEA_ITEMS} variables={{ date }} withLoading withError>
              {({ data: teaData, error }) => {
                if (error) return <CentredErrorIcon />;

                return (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Meal Component</TableCell>
                        <TableCell>Packed At</TableCell>
                        <TableCell>Packed By</TableCell>
                        <TableCell>Temp.</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lunchData.uniqueLunchDeliveryComponents.map((mealComponent) => {
                        return (
                          <Row
                            mutation={RECORD_LUNCH_DELIVERY_TEMP}
                            date={date}
                            key={mealComponent.id}
                            mealComponent={mealComponent}
                          />
                        );
                      })}
                      <TableRow>
                        <TableCell>
                          <Typography
                            style={{ padding: "10px 0px", display: "inline-block" }}
                            variant="button"
                          >
                            Tea Components
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      {teaData.uniqueTeaDeliveryComponents.map((mealComponent) => {
                        return (
                          <Row
                            mutation={RECORD_TEA_DELIVERY_TEMP}
                            date={date}
                            key={mealComponent.id}
                            mealComponent={mealComponent}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                );
              }}
            </GraphqlQuery>
          );
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};
