import React from "react";
import { Routes, Route, BrowserRouter, useNavigate } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { ApolloSetup } from "apollo_client.js";
import { HomePage } from "pages/Home";
import { theme } from "theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { RequireAuth } from "containers/routes/LoggedInRoute";
import { Auth0Provider } from "@auth0/auth0-react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile, isTablet } from "react-device-detect";
import { CssBaseline } from "@mui/material";

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;

// I'm sure this isn't perfect, but it doesn't have to be
const backend = isMobile || isTablet ? TouchBackend : HTML5Backend;

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  };
  // None of this seems great. Without a custom domain Auth0 uses 3rd party cookies
  // and I don't know if there is a way to avoid that?
  return (
    <Auth0Provider
      useRefreshTokensFallback={true}
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
      {...props}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback
          domain={auth0Domain}
          clientId={auth0ClientId}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: auth0Audience,
          }}
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              {/* Effectively an opinionated CSS Reset from Material UI  */}
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DndProvider backend={backend}>
                  <Routes>
                    <Route
                      path="*"
                      element={
                        <RequireAuth>
                          <ApolloSetup>
                            <HomePage />
                          </ApolloSetup>
                        </RequireAuth>
                      }
                    ></Route>
                  </Routes>
                </DndProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
