import { gql } from "@apollo/client";
import React from "react";
import dayjs from "dayjs_with_plugins";
import { PageHeading } from "components/PageHeading";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import makeStyles from "@mui/styles/makeStyles";
import { lowerCase, groupBy, chunk } from "lodash";
import {
  Typography,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { pink, blue } from "@mui/material/colors";

const MENU = gql`
  query Menus($date: Date!) {
    currentMenu(date: $date) {
      id
      subMenus {
        id
        name
        currentlyActive
        meals {
          ... on LunchMeal {
            id
            type
            dayName
            componentsWithSubstitutions {
              id
              mealComponent {
                id
                name
                sensitivities {
                  id
                  name
                }
              }
            }
          }
          ... on TeaMeal {
            id
            type
            dayName
            componentsWithSubstitutions {
              id
              mealComponent {
                id
                name
                sensitivities {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  space: { marginBottom: "15px" },
  heading: { marginBottom: "15px", fontSize: "20px", fontWeight: "400" },
});

export const CurrentMenu = () => {
  const date = dayjs().format("YYYY-MM-DD");
  const { space, heading } = useStyles();
  return (
    <PageHeading maxWidth="md" heading="Current Menu">
      <Typography variant="body1" className={space}>
        Menus are made up of a number of sub menus. Each sub menu lasts for one week. We cycle
        through all of the sub menus in turn. The sub menu in use for the current week is
        highlighted below, as is today&apos;s meals. Substitutions are provided for components
        that a child cannot eat.
      </Typography>
      <GraphqlQuery fetchPolicy="network-only" withError query={MENU} variables={{ date }}>
        {({ data, error }) => {
          if (error) {
            return <CentredErrorIcon />;
          }
          if (!data.currentMenu) {
            return (
              <Typography
                variant="button"
                style={{ fontSize: "16px", display: "block", textAlign: "center" }}
              >
                No Menu is set for today!
              </Typography>
            );
          }
          return data.currentMenu.subMenus.map((subMenu) => {
            const grouped = groupBy(subMenu.meals, "__typename");
            return (
              <Accordion
                key={subMenu.id}
                style={{
                  border: subMenu.currentlyActive ? `${pink[200]} dashed 3px` : "none",
                  marginBottom: "15px",
                }}
              >
                <AccordionSummary expandIcon={<ExpandLessIcon />}>
                  <Typography style={{ fontSize: "20px", fontWeight: "400", color: blue[500] }}>
                    {subMenu.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: "block", padding: "0px 30px 0px 30px" }}>
                  <Typography style={{ color: pink[500] }} className={heading}>
                    Lunch Meals
                  </Typography>
                  {Object.entries(groupBy(grouped["LunchMeal"], "dayName")).map(
                    ([dayName, meals]) => {
                      return <Day type="Lunch" key={dayName} dayName={dayName} meals={meals} />;
                    },
                  )}
                </AccordionDetails>
                <AccordionDetails style={{ display: "block", padding: "15px 30px 30px 30px" }}>
                  <Typography style={{ color: pink[500] }} className={heading}>
                    Tea Meals
                  </Typography>
                  {Object.entries(groupBy(grouped["TeaMeal"], "dayName")).map(
                    ([dayName, meals]) => {
                      return <Day type="Tea" key={dayName} dayName={dayName} meals={meals} />;
                    },
                  )}
                </AccordionDetails>
              </Accordion>
            );
          });
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};

const Day = (props) => {
  const today = dayjs().format("dddd");
  const { dayName, meals } = props;
  const border = lowerCase(dayName) === lowerCase(today) ? `${pink[200]} dashed 2px` : "none";

  return (
    <div style={{ marginBottom: "15px", border: border, padding: "3px" }}>
      <Typography style={{ color: blue[500] }} variant="button">
        {dayName}
      </Typography>
      {meals.map((meal) => {
        const meals = meal.componentsWithSubstitutions;
        // Keeps the line length sensible.
        const chunks = chunk(meals, 2);
        return chunks.map((chunk) => {
          const name = chunk.map((c) => c.mealComponent.name).join(" • ");
          const sensitivities = chunk.flatMap((c) =>
            c.mealComponent.sensitivities.map((s) => s.name),
          );

          const title =
            sensitivities.length > 0
              ? `Contains: ${sensitivities.join(", ")}`
              : "These components contain no sensitivities";

          return (
            <Tooltip key={name} title={title}>
              <Typography style={{ fontSize: "16px" }} variant="body1">
                {name}
              </Typography>
            </Tooltip>
          );
        });
      })}
    </div>
  );
};
