import { gql } from "@apollo/client";
import React from "react";
import dayjs from "dayjs_with_plugins";
import styled from "styled-components";
import { PageHeading } from "components/PageHeading";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { Link } from "react-router-dom";
import { DatePickerSection } from "components/DatePickerSection";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { useParams } from "react-router-dom";
import { Loading } from "components/Loading";
import { useTheme } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Typography,
  Link as MaterialUiLink,
} from "@mui/material";
import { FilterableNurseriesTable } from "containers/nurseries/FilterableNurseriesTable";

const TableWrapper = styled.div`
  background-color: white;
  margin-bottom: 30px;
  height: 650px;
  overflow-y: scroll;
`;

const NURSERIES = gql`
  query Nurseries($date: Date!) {
    nurseries {
      id
      name
      isActive
      teaStatus(date: $date)
      lunchStatus(date: $date)
    }
  }
`;

const chipFromStatus = (status, colorMap) => {
  switch (status) {
    case "not submitted":
      return (
        <Chip
          size="small"
          variant="outlined"
          label={status}
          style={{ color: colorMap[status], border: `1px solid ${colorMap[status]}` }}
        />
      );
    case "auto submitted":
      return (
        <Chip
          size="small"
          variant="outlined"
          label={status}
          style={{ color: colorMap[status], border: `1px solid ${colorMap[status]}` }}
        />
      );
    case "submitted":
      return (
        <Chip
          size="small"
          variant="outlined"
          label={status}
          style={{ color: colorMap[status], border: `1px solid ${colorMap[status]}` }}
        />
      );
    case "closed":
      return <Chip disabled size="small" variant="outlined" label="closed" color="default" />;
    case null:
      return " - ";
    default:
      throw new Error("Unsupported Status");
  }
};

export const NurseryOrdersList = () => {
  const { date } = useParams();
  const theme = useTheme();

  const colorMap = {
    submitted: theme.palette.success.main,
    "not submitted": theme.palette.error.main,
    "auto submitted": theme.palette.info.main,
  };
  const TableHeadBorder = "1px solid rgb(224, 224, 224)";

  return (
    <PageHeading maxWidth="md" heading="Nursery Orders">
      <DatePickerSection
        type="weekly"
        disableArrowKeys={false}
        maxDate={dayjs().add(6, "month").startOf("month")}
      />
      <Typography style={{ marginBottom: "30px" }}>
        Below you can see a summary of the orders for the given week. The status indicates whether
        a nursery has submitted their order for the week. Click the nursery&apos;s name below to
        go to their order for the given week.
      </Typography>
      <GraphqlQuery query={NURSERIES} variables={{ date }} withError withLoading={false}>
        {({ data, error, loading }) => {
          if (error) return <CentredErrorIcon />;
          if (loading) return <Loading />;
          const { nurseries } = data;
          return (
            <FilterableNurseriesTable data={nurseries}>
              {({ nurseriesList }) => {
                return (
                  <TableContainer component={TableWrapper}>
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ borderTop: TableHeadBorder, borderLeft: TableHeadBorder }}
                          >
                            Nursery
                          </TableCell>
                          <TableCell style={{ borderTop: TableHeadBorder }}>
                            Lunch Status
                          </TableCell>
                          <TableCell
                            style={{ borderTop: TableHeadBorder, borderRight: TableHeadBorder }}
                          >
                            Tea Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {nurseriesList.map((nursery) => {
                          const { name, id, teaStatus, lunchStatus } = nursery;
                          return (
                            <TableRow key={name} hover>
                              <TableCell>
                                <Link key={id} to={`/nursery/${id}/date/${date}`}>
                                  <MaterialUiLink component="p">{name}</MaterialUiLink>
                                </Link>
                              </TableCell>
                              <TableCell>{chipFromStatus(lunchStatus, colorMap)}</TableCell>
                              <TableCell>{chipFromStatus(teaStatus, colorMap)}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              }}
            </FilterableNurseriesTable>
          );
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};
