import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { ulid } from "ulid";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { isEmpty } from "lodash";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import { MealComponentSlot } from "containers/menus/MealComponentSlot";
import { DragAndDropTable } from "components/DragAndDropTable";

const useDaySectionStyles = makeStyles({
  componentAndDelete: { maxWidth: "400px" },
  group: { maxWidth: "1200px" },
  border: { border: "2px red solid" },
});

const SlowDaySection = (props) => {
  const {
    label,
    options,
    onChange,
    componentsWithSubstitutions,
    highlight,
    componentBorders,
    updateBorders,
    isTea = false,
  } = props;
  const { componentAndDelete, group, border } = useDaySectionStyles();

  const onMealComponentChange = (id) => (mealComponent) => {
    const newComponents = componentsWithSubstitutions.map((component) => {
      if (component.id === id) {
        return { ...component, mealComponent };
      } else {
        return component;
      }
    });

    onChange(newComponents);
  };

  const onSubstitutionChange = (id) => (newComponentWithSubs) => {
    const newComponents = componentsWithSubstitutions.map((component) => {
      if (component.id === id) {
        return newComponentWithSubs;
      } else {
        return component;
      }
    });

    onChange(newComponents);
  };

  const addSlot = () => {
    const newId = ulid();
    onChange([...componentsWithSubstitutions, { id: newId, substitutions: [] }]);
  };

  const removeSlot = (id) => () => {
    const newComponents = componentsWithSubstitutions.filter(({ id: i }) => i !== id);

    if (isEmpty(newComponents)) {
      return;
    } else {
      onChange(newComponents);
    }
  };

  const saveOrder = (stuff) => {
    // Just save the new order to state.
    onChange(stuff);
  };

  return (
    <Accordion elevation={2} className={highlight ? border : ""}>
      <AccordionSummary expandIcon={<ExpandLessIcon />}>
        <Typography variant="button">{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <DragAndDropTable
            dropComponent={Grid}
            data={componentsWithSubstitutions}
            saveOrder={saveOrder}
            // If it is isTea it's the tea menu.
            cardType={`${label}-${isTea ? "tea" : "lunch"}`}
            // These will be passed on to the dropComponent
            container
            item
            xs={12}
            spacing={2}
            className={group}
            renderAfterList={() => {
              return (
                <Grid item xs={2} sm={2}>
                  <Button size="small" color="primary" variant="outlined" onClick={addSlot}>
                    component&nbsp;&nbsp;
                    <AddIcon />
                  </Button>
                </Grid>
              );
            }}
          >
            {({ datum: mealComponent, index, isDragging, dragRef }) => {
              const opacity = isDragging ? 0 : 1;

              return (
                <Grid container item xs={8} sm={5} className={componentAndDelete}>
                  <Grid item xs={11} style={{ opacity }} ref={dragRef}>
                    <MealComponentSlot
                      updateBorders={updateBorders}
                      componentBorders={componentBorders}
                      onMealComponentChange={onMealComponentChange(mealComponent.id)}
                      onSubstitutionChange={onSubstitutionChange(mealComponent.id)}
                      mealComponent={mealComponent}
                      options={options}
                      columnNumber={index}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      disabled={componentsWithSubstitutions.length === 1}
                      onClick={removeSlot(mealComponent.id)}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            }}
          </DragAndDropTable>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

// We need to return FALSE if we want to render, and TRUE if we don't want to re-render.
// this does nothing FWIW need to make it work somehow
export const DaySection = React.memo((props) => {
  return <SlowDaySection {...props} />;
  // return (
  //   // prevProps.label === nextProps.label &&
  //   // prevProps.highlight === nextProps.highlight &&
  //   // prevProps.isTea === nextProps.isTea &&
  //   isEqual(prevProps.componentsWithSubstitutions, nextProps.componentsWithSubstitutions)
  //   // isEqual(prevProps.options, nextProps.options)
  // );
});
