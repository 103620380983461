import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import { FourOhFour } from "components/FourOhFour";
import { OrderNumbersTable } from "containers/orders/OrderNumbersTable";
import { ChildrenWithSensitivities } from "containers/orders/ChildrenWithSensitivities";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { CreateNewLunchOrder } from "containers/orders/CreateNewLunchOrder";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { useGraphqlSubscription } from "graphqlUtils/useGraphqlSubscription";
import { Paper, Button, Typography } from "@mui/material";
import { every, some } from "lodash";
import { H3 } from "components/typography/H3";
import { InfoNextToText } from "components/InfoNextToText";

const SubmitCancelButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 20px;
`;

const useStyles = makeStyles({
  paperStyles: { padding: "30px" },
  submitButton: { marginLeft: "20px" },
  spiel: { marginBottom: "30px" },
});

const SUBMIT_ORDER = gql`
  mutation SubmitLunchOrders($input: WeekDateNurseryInput!) {
    submitLunchOrders(input: $input) {
      ... on EditableLunchOrder {
        __typename
        id
        submittedDatetime
      }
      ... on NonEditableLunchOrder {
        __typename
        id
        submittedDatetime
      }
    }
  }
`;

const UNSUBMIT_ORDER = gql`
  mutation UnsubmitOrders($input: WeekDateNurseryInput!) {
    unsubmitLunchOrders(input: $input) {
      ... on EditableLunchOrder {
        __typename
        id
        submittedDatetime
      }
      ... on NonEditableLunchOrder {
        __typename
        id
        submittedDatetime
      }
    }
  }
`;

const DELETE_ORDER = gql`
  mutation DeleteLunchOrders($input: WeekDateNurseryInput!) {
    deleteLunchOrders(input: $input) {
      ... on EditableLunchOrder {
        id
      }
    }
  }
`;

const LUNCH_ORDERS = gql`
  query LunchOrdersByDate($nurseryId: ID!, $date: Date!) {
    oneWeeksOrders(date: $date, nurseryId: $nurseryId) {
      id
      childrenWithSensitivities {
        id
        name
        sensitivities {
          id
          name
          order
          readOnly
        }
      }
      lunchOrders {
        ... on EditableLunchOrder {
          id
          date
          regularMeals
          specialMeals
          totalMeals
          closed
          submittedDatetime
          attendingChildren {
            id
            name
            closed
            childWithSensitivityId
          }
        }
        ... on NonEditableLunchOrder {
          id
          date
          regularMeals
          specialMeals
          totalMeals
          closed
          submittedDatetime
          attendingChildren {
            id
            name
            closed
            childWithSensitivityId
          }
        }
      }
    }
  }
`;

const OPEN_TIMES_SUBSCRIPTION = gql`
  subscription closed($nurseryId: ID!, $date: Date!) {
    openingTimes(nurseryId: $nurseryId, date: $date) {
      lunchOrders {
        __typename
        ... on EditableLunchOrder {
          id
          closed
          regularMeals
          specialMeals
          totalMeals
          submittedDatetime
          attendingChildren {
            id
            closed
          }
        }
        ... on NonEditableLunchOrder {
          id
          closed
          regularMeals
          specialMeals
          totalMeals
          submittedDatetime
          attendingChildren {
            id
            closed
          }
        }
      }
    }
  }
`;

const UPDATE_CHILD_ATTENDANCE = gql`
  mutation UpdateLunchAttendance($input: UpdateLunchAttendanceInput!) {
    updateLunchAttendance(input: $input) {
      id
      name
      closed
      childWithSensitivityId
    }
  }
`;

export const LunchOrder = (props) => {
  const { params, onCloseModal, onOpenModal } = props;
  const { paperStyles, submitButton, spiel } = useStyles();
  const refetchQueries = [{ query: LUNCH_ORDERS, variables: params }];

  useGraphqlSubscription({
    subscription: OPEN_TIMES_SUBSCRIPTION,
    variables: { nurseryId: params.nurseryId, date: params.date },
  });

  return (
    <GraphqlQuery query={LUNCH_ORDERS} variables={params} withLoading withError>
      {({ data, error }) => {
        if (error) {
          return <FourOhFour />;
        }
        const { oneWeeksOrders } = data;

        const allPast = every(
          oneWeeksOrders.lunchOrders,
          (order) => order.__typename === "NonEditableLunchOrder",
        );

        const isSubmitted =
          some(
            oneWeeksOrders.lunchOrders.filter(
              ({ __typename }) => __typename === "EditableLunchOrder",
            ),
            (order) => {
              // Admins need to be able to unsubmit once a thing has been auto-submitted to edit
              // it later. When we auto-submit we also put in a submitted datetime, so we can just
              // check for submittedDatetime here.
              return Boolean(order.submittedDatetime);
            },
          ) || allPast;

        const createPage = oneWeeksOrders.lunchOrders.length === 0;
        const uniqueChildren = oneWeeksOrders.childrenWithSensitivities;
        const orderDays = ["Mon", "Tues", "Weds", "Thurs", "Fri"];

        const rows = uniqueChildren.map(({ id: childId, name, sensitivities }) => {
          const weekDays = oneWeeksOrders.lunchOrders.reduce(
            (acc, { attendingChildren, id, closed, __typename, submittedDatetime }, index) => {
              const isIn = Boolean(
                attendingChildren.find((child) => {
                  return child.childWithSensitivityId === childId;
                }),
              );
              const weekday = orderDays[index];

              return {
                ...acc,
                [weekday]: {
                  isIn,
                  id,
                  closed,
                  __typename,
                  isSubmitted: Boolean(submittedDatetime),
                },
              };
            },
            {},
          );
          return { id: childId, sensitivities, name, ...weekDays };
        });

        return (
          <Paper elevation={1} square className={paperStyles}>
            {createPage ? (
              <CreateNewLunchOrder params={params} refetchQueries={refetchQueries} />
            ) : (
              <React.Fragment>
                <OrderNumbersTable oneWeeksOrders={oneWeeksOrders} />
                <H3>Children With Sensitivities</H3>
                <Typography className={spiel} variant="body2" component="p">
                  <InfoNextToText />
                  &nbsp; Below you may mark which children will be eating on which days. If you
                  click the pencil you can edit a child&apos;s name and sensititivites. Ticking a
                  box marks them as attending lunch for that day.
                </Typography>
                <GraphqlMutation mutation={UPDATE_CHILD_ATTENDANCE} withError>
                  {(mutation, { loading }) => {
                    const onAttendanceUpdate = async (lunchOrderId, childId) => {
                      const response = await mutation({
                        refetchQueries,
                        variables: { input: { lunchOrderId, childId } },
                      });
                      return response;
                    };
                    return (
                      <ChildrenWithSensitivities
                        params={params}
                        loading={loading}
                        refetchQueries={refetchQueries}
                        rows={rows}
                        oneWeeksOrders={oneWeeksOrders}
                        onCloseModal={onCloseModal}
                        onOpenModal={onOpenModal}
                        onAttendanceUpdate={onAttendanceUpdate}
                        allPast={isSubmitted}
                      />
                    );
                  }}
                </GraphqlMutation>
                {isSubmitted ? (
                  <SubmitCancelButtons>
                    <GraphqlMutation mutation={UNSUBMIT_ORDER} withError>
                      {(unSubmitOrder, { loading }) => {
                        const variables = {
                          input: { nurseryId: params.nurseryId, startDate: params.date },
                        };
                        return (
                          <Button
                            disabled={allPast || loading}
                            className={submitButton}
                            variant="contained"
                            color="primary"
                            aria-label="add"
                            onClick={() => unSubmitOrder({ variables, refetchQueries })}
                          >
                            Undo Submission
                          </Button>
                        );
                      }}
                    </GraphqlMutation>
                  </SubmitCancelButtons>
                ) : (
                  <SubmitCancelButtons>
                    <GraphqlMutation mutation={DELETE_ORDER} withError>
                      {(deleteOrder, { loading }) => {
                        const variables = {
                          input: { nurseryId: params.nurseryId, startDate: params.date },
                        };
                        return (
                          <Button
                            disabled={allPast || loading}
                            variant="outlined"
                            color="secondary"
                            aria-label="add"
                            onClick={() => {
                              if (window.confirm("Are you sure you want to delete the order?")) {
                                return deleteOrder({ variables, refetchQueries });
                              }
                            }}
                          >
                            Delete Order
                          </Button>
                        );
                      }}
                    </GraphqlMutation>
                    <GraphqlMutation mutation={SUBMIT_ORDER} withError>
                      {(submitOrder, { loading }) => {
                        const variables = {
                          input: { nurseryId: params.nurseryId, startDate: params.date },
                        };
                        return (
                          <Button
                            className={submitButton}
                            variant="outlined"
                            color="primary"
                            aria-label="add"
                            disabled={loading}
                            onClick={async () => await submitOrder({ variables, refetchQueries })}
                          >
                            Submit Order
                          </Button>
                        );
                      }}
                    </GraphqlMutation>
                  </SubmitCancelButtons>
                )}
              </React.Fragment>
            )}
          </Paper>
        );
      }}
    </GraphqlQuery>
  );
};
