import { gql } from "@apollo/client";
import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { InfoNextToText } from "components/InfoNextToText";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Typography,
} from "@mui/material";

const CREATE_CHILD = gql`
  mutation CreateChildWithSensitivities($input: CreateChildInput!) {
    createChildWithSensitivities(input: $input) {
      id
      name
      sensitivities {
        id
        name
      }
    }
  }
`;

const SENSITIVITIES = gql`
  query Sensitivities($active: Boolean!) {
    sensitivities(active: $active) {
      id
      name
      order
      readOnly
    }
  }
`;

const useStyles = makeStyles({
  inputStyles: { marginBottom: "15px", width: "66%" },
  nameBlurb: { marginBottom: "20px" },
});

export const AddChildModal = (props) => {
  const { nameBlurb, inputStyles } = useStyles();
  const [childName, updateName] = React.useState("");
  const [sensitivities, updateSensitivities] = React.useState([]);

  const { open, onCancel, onSubmit, refetchQueries, date: startDate, nurseryId } = props;

  return (
    <Dialog disableEscapeKeyDown fullWidth open={open}>
      <DialogTitle>
        <Typography variant="h4" component="h4">
          Add a child
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          required
          className={inputStyles}
          label="Enter a name..."
          value={childName}
          onChange={(event) => updateName(event.target.value)}
        />
      </DialogContent>
      <DialogContent className={nameBlurb}>
        <Typography variant="body1" component="p">
          Please add a name and an initial. If there are two children with the same name and
          initial, you can use two letters as the second initial.
        </Typography>
        <Typography variant="body2" component="p">
          <InfoNextToText /> Please don’t give the child’s full last name.
        </Typography>
      </DialogContent>
      <DialogContent>
        <Typography variant="h4" component="h4">
          Sensitivities
        </Typography>
      </DialogContent>
      <DialogContent>
        <Typography variant="body1" component="p">
          The sensitivities marked represent foods that the child cannot eat. If you change a
          sensitivity, this will also update the sensitivities for all your future orders, for any
          child whose name exactly matches the name given above.
        </Typography>
      </DialogContent>
      <DialogContent>
        <GraphqlQuery withLoading withError query={SENSITIVITIES} variables={{ active: true }}>
          {({ data, error }) => {
            if (error) {
              return <CentredErrorIcon />;
            }

            const { sensitivities: sensitivityOptions } = data;
            return (
              <Autocomplete
                multiple
                blurOnSelect
                filterSelectedOptions
                options={data.sensitivities.map(({ name }) => name)}
                getOptionLabel={(option) => option}
                onChange={(_, values) => {
                  const selections = values.map((selected) => {
                    const selectedOption = sensitivityOptions.find(
                      ({ name }) => name === selected,
                    );
                    return {
                      id: selectedOption.id,
                      name: selectedOption.name,
                      order: selectedOption.order,
                    };
                  });
                  updateSensitivities(selections);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      required
                      label="Choose all sensitivities that apply"
                      variant="outlined"
                      placeholder="Type to filter the list..."
                    />
                  );
                }}
                slotProps={{
                  chip: { variant: "outlined", color: "secondary" },
                }}
              />
            );
          }}
        </GraphqlQuery>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <GraphqlMutation mutation={CREATE_CHILD} withError>
          {(createChild, { loading }) => {
            const variables = { input: { startDate, nurseryId, childName, sensitivities } };
            const submit = async () => {
              const result = await createChild({ variables, refetchQueries });
              if (result.data) {
                onSubmit();
              }
              return result;
            };

            return (
              <Button
                disabled={sensitivities.length === 0 || !childName || loading}
                onClick={submit}
                color="primary"
              >
                Create
              </Button>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </Dialog>
  );
};
