import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import { PageHeading } from "components/PageHeading";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { Link } from "react-router-dom";
import dayjs from "dayjs_with_plugins";
import AddIcon from "@mui/icons-material/Add";
import { AddNurseryModal } from "containers/nurseries/AddNurseryModal";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { Icons } from "icons/Icons";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import { FilterableNurseriesTable } from "containers/nurseries/FilterableNurseriesTable";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  TableRow,
  Link as MaterialUiLink,
  Chip,
  Button,
  IconButton,
} from "@mui/material";
import { DragAndDropTable } from "components/DragAndDropTable";

const useStyles = makeStyles({
  icon: { height: "20px", position: "relative" },
  deleteBtn: { paddingTop: "0px", paddingBottom: "0px" },
  moveIcon: { height: "15px", position: "relative", top: "4px", cursor: "move" },
  tableRoot: { width: "1250px" },
  containerRoot: { maxWidth: "1250px", width: "80vw" },
});

const TableWrapper = styled.div`
  background-color: white;
  margin-bottom: 30px;
  max-height: 66vh;
  width: 1250px;
`;

const NURSERIES = gql`
  query Nurseries {
    nurseries {
      id
      name
      address
      phoneNumber
      isActive
    }
  }
`;

const DELETE_NURSERY = gql`
  mutation DeleteNursery($id: ID!) {
    deleteNursery(id: $id) {
      id
      isActive
    }
  }
`;

const ORDER_NURSERIES = gql`
  mutation Nurseries($input: OrderNurseryInput!) {
    orderNurseries(input: $input) {
      id
      name
      address
      phoneNumber
      isActive
    }
  }
`;

const NurseriesList = (props) => {
  const { nurseries, refetchQueries, isFiltering } = props;
  const classes = useStyles();

  const prompt = (name) => `WARNING! This will permanently delete ${name} nursery and ALL data\
 including any past orders.

Only delete a nursery if you added them by mistake. If they are no longer using HM you should change their\
 contract type to Temporary instead.

If you are certain type DELETE (in capitals) below to confirm`;

  return (
    <GraphqlMutation mutation={ORDER_NURSERIES}>
      {(reorder) => {
        const saveOrder = async (nurseries) => {
          const nurseryIds = nurseries.map(({ id }) => id);
          await reorder({
            variables: { input: { nurseryIds } },
            update: (store, { data: { orderNurseries } }) => {
              // This may spit out a warning in the console about a merge function being needed.
              // In this case we actually do want to completely replace what is in the cache
              // with this response as it is faster than trying to sort the stuff in the cache
              // in the right order, and this query always returns all nurseries.
              store.writeQuery({
                query: NURSERIES,
                variables: {},
                data: { nurseries: orderNurseries },
              });
            },
          });
        };

        return (
          <DragAndDropTable
            dropComponent={TableBody}
            data={nurseries}
            saveOrder={saveOrder}
            cardType="nursery"
          >
            {({ datum: nursery, isDragging, dragRef }) => {
              const { name, id, address, phoneNumber } = nursery;
              const opacity = isDragging ? 0 : 1;
              // We need to disable dragging / dropping if the table is showing less than
              // the full number of nurseries because re-ordering at that point would lead
              // to surprising results. We use the Nursery's index in the list of all nurseries
              // as the order, so if this list is shorter than all nurseries we'll have a bad time.

              // We can drag/drop if we are not showing inactive because inactive nurseries
              // don't really matter (and otherwise we'd never be allowed to drag /drop)

              // Anyway this is the simplest way to disable drag / drop.
              const ref = isFiltering ? null : dragRef;
              return (
                <TableRow style={{ opacity }} ref={ref} key={id} hover>
                  <TableCell style={{ cursor: "move", opacity, width: "56px" }}>
                    <OpenWithIcon className={classes.moveIcon} />
                  </TableCell>
                  <TableCell>
                    <Link to={`/nursery/${id}`}>
                      <MaterialUiLink component="p">{name}</MaterialUiLink>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Chip
                      variant="outlined"
                      size="small"
                      color={nursery.isActive ? "primary" : "secondary"}
                      label={nursery.isActive ? "Active" : "Inactive"}
                    />
                  </TableCell>
                  <TableCell>
                    <Link to={`/diet-advice/${id}/date/${dayjs().format("YYYY-MM-DD")}`}>
                      <MaterialUiLink component="p">Today&apos;s diet advice</MaterialUiLink>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link to={`/delivery-forms/${id}/date/${dayjs().format("YYYY-MM-DD")}`}>
                      <MaterialUiLink component="p">Today&apos;s forms</MaterialUiLink>
                    </Link>
                  </TableCell>
                  <TableCell>{phoneNumber}</TableCell>
                  <TableCell>{address}</TableCell>
                  <TableCell>
                    <GraphqlMutation mutation={DELETE_NURSERY}>
                      {(mutation) => {
                        return (
                          <IconButton
                            className={classes.deleteBtn}
                            aria-label="delete"
                            onClick={() => {
                              const confirm = window.prompt(prompt(name));
                              if (confirm === "DELETE") {
                                mutation({ variables: { id }, refetchQueries });
                              }
                            }}
                            size="large"
                          >
                            <Icons.DeleteIcon className={classes.icon} />
                          </IconButton>
                        );
                      }}
                    </GraphqlMutation>
                  </TableCell>
                </TableRow>
              );
            }}
          </DragAndDropTable>
        );
      }}
    </GraphqlMutation>
  );
};

export const Nurseries = () => {
  const classes = useStyles();
  const [open, setModalOpen] = React.useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const refetchQueries = [{ query: NURSERIES }];
  const TableHeadBorder = "1px solid rgb(224, 224, 224)";

  return (
    <PageHeading heading="Nurseries" maxWidth="lg" footer={false}>
      <Typography style={{ marginBottom: "30px" }}>
        Click the nursery&apos;s name below to go to their settings page. You can filter the
        nurseries by name using the text box. By default we exclude inactive nurseries but you an
        click the checkbox to include them.
      </Typography>
      <GraphqlQuery query={NURSERIES} fetchPolicy="network-only" withError withLoading={false}>
        {({ data, loading }) => {
          if (loading) return;
          return (
            <FilterableNurseriesTable data={data.nurseries}>
              {({ nurseriesList, isFiltering }) => {
                return (
                  <TableContainer
                    component={TableWrapper}
                    classes={{ root: classes.containerRoot }}
                  >
                    <Table stickyHeader size="small" classes={{ root: classes.tableRoot }}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ borderTop: TableHeadBorder, borderLeft: TableHeadBorder }}
                          ></TableCell>
                          <TableCell style={{ borderTop: TableHeadBorder }}>Nursery</TableCell>
                          <TableCell style={{ borderTop: TableHeadBorder }}>Active?</TableCell>
                          <TableCell style={{ borderTop: TableHeadBorder }}>
                            Diet Advice
                          </TableCell>
                          <TableCell style={{ borderTop: TableHeadBorder }}>
                            Delivery Forms
                          </TableCell>
                          <TableCell style={{ borderTop: TableHeadBorder }}>
                            Phone Number
                          </TableCell>
                          <TableCell style={{ borderTop: TableHeadBorder }}>Address</TableCell>
                          <TableCell
                            style={{ borderTop: TableHeadBorder, borderRight: TableHeadBorder }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <NurseriesList
                        isFiltering={isFiltering}
                        nurseries={nurseriesList}
                        refetchQueries={refetchQueries}
                      />
                    </Table>
                  </TableContainer>
                );
              }}
            </FilterableNurseriesTable>
          );
        }}
      </GraphqlQuery>
      <Button variant="outlined" color="primary" aria-label="add" onClick={openModal}>
        Add Nursery&nbsp;&nbsp;
        <AddIcon />
      </Button>
      <AddNurseryModal
        open={open}
        onCancel={closeModal}
        onSubmit={closeModal}
        refetchQueries={refetchQueries}
      />
    </PageHeading>
  );
};
