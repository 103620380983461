import React from "react";
import { Route, Routes } from "react-router-dom";
import { NurseryOrderPage } from "pages/nursery/Order";
import { RequireAuth } from "containers/routes/LoggedInRoute";
import { FourOhFour } from "components/FourOhFour";
import { Settings } from "pages/nursery/Settings";
import { NurseryDietAdvicePage } from "pages/nursery/NurseryDietAdvicePage";
import { DeliveryFormPage } from "pages/nursery/DeliveryFormPage";
import { NurseryPortionInfo } from "pages/admin/NurseryPortionInfo";
import { ReadOnlyTemperaturesPage } from "pages/deliveryDriver/ReadOnlyTemperaturesPage";
import { CurrentMenu } from "containers/menus/CurrentMenu";
import { HMClosures } from "pages/nursery/HMClosures";
import { PDFHome } from "containers/PDFHome";
import { Faqs } from "pages/nursery/Faqs";

export const NurseryHome = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <RequireAuth>
            <PDFHome />
          </RequireAuth>
        }
      />
      <Route
        path="/faqs"
        element={
          <RequireAuth>
            <Faqs />
          </RequireAuth>
        }
      />
      <Route
        path="/nursery/:nurseryId/date/:date"
        element={
          <RequireAuth>
            <NurseryOrderPage />
          </RequireAuth>
        }
      />
      <Route
        path="/temperatures/date/:date"
        element={
          <RequireAuth>
            <ReadOnlyTemperaturesPage />
          </RequireAuth>
        }
      />
      <Route
        path="/diet-advice/:nurseryId/date/:date"
        element={
          <RequireAuth>
            <NurseryDietAdvicePage />
          </RequireAuth>
        }
      />
      <Route
        path="/delivery-forms/:nurseryId/date/:date"
        element={
          <RequireAuth>
            <DeliveryFormPage />
          </RequireAuth>
        }
      />
      <Route
        path="/hungry-monsters-closures"
        element={
          <RequireAuth>
            <HMClosures />
          </RequireAuth>
        }
      />
      <Route
        path="/nursery/:nurseryId/portion-information/date/:date"
        element={
          <RequireAuth>
            <NurseryPortionInfo />
          </RequireAuth>
        }
      />
      <Route
        path="/current-menu"
        element={
          <RequireAuth>
            <CurrentMenu />
          </RequireAuth>
        }
      />
      <Route
        path="/settings"
        element={
          <RequireAuth>
            <Settings />
          </RequireAuth>
        }
      />
      <Route element={<FourOhFour />} />
    </Routes>
  );
};
