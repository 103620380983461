import React from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs_with_plugins";
import { media } from "media";
import makeStyles from "@mui/styles/makeStyles";
import { H3 } from "components/typography/H3";
import { MobileDatePicker } from "@mui/x-date-pickers";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { Button, IconButton } from "@mui/material";

const DateDiv = styled.div`
  margin-bottom: 30px;
  max-width: 755px;
  ${media.sm`
  display: flex;
  justify-content: space-between;
  align-items: center;
  `}
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles({
  chevron: {
    padding: "0px",
  },
  chevronRight: {
    padding: "0px",
    paddingLeft: "5px",
    marginRight: "15px",
  },
  submitButton: {
    marginLeft: "20px",
  },
});

/**
 * Provides the date shifting capabilities you see on a few different pages.
 * Relies on the url where this component is being used having the date at the
 * end of it, e.g.:  nursery/my_nursery_id/date/2020-03-23
 *
 * Allows navigating left and right through the weeks using arrow keys and
 * by clicking the chevrons. Accepts an optional minimum date which we will
 * not be able to scroll back past.
 *
 *
 */
export const DatePickerSection = (props) => {
  const { type = "daily" } = props;

  switch (type) {
    case "daily":
      return <DailyDatePickerSection {...props} />;
    case "monthly":
      return <MonthlyDatePickerSection {...props} />;
    case "weekly":
      return <WeeklyDatePickerSection {...props} />;
    default:
      throw new Error("Unsupported Date Picker Type :(");
  }
};

const MonthlyDatePickerSection = (props) => {
  const { chevron, chevronRight } = useStyles();
  // We may have to change this if we want to input old data...
  const { earliestDate = "2020-03-23", disableArrowKeys, maxDate } = props;
  const location = useLocation();
  const { date: paramDate } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const date = dayjs(paramDate, "YYYY-MM-DD", true);
  const minDate = dayjs(earliestDate, "YYYY-MM-DD", true).startOf("month");
  const startOfMaxWeek = dayjs(maxDate, "YYYY-MM-DD", true).startOf("month");
  const showBackChevron = date.isAfter(minDate, true);
  const beforeMaxDate = date.startOf("month").isBefore(startOfMaxWeek);

  const handleDateChange = React.useCallback(
    (newDate) => {
      navigate(
        location.pathname.split("/").slice(0, -1).join("/") + `/${newDate.format("YYYY-MM-DD")}`,
      );
    },
    [navigate, location],
  );

  const previousOrder = React.useCallback(() => {
    const lastWeek = date.subtract(1, "month").startOf("month");
    if (lastWeek.isSameOrAfter(minDate)) {
      handleDateChange(lastWeek);
    }
  }, [minDate, date, handleDateChange]);

  const nextOrder = React.useCallback(() => {
    const nextWeek = date.add(1, "month").startOf("month");
    if (nextWeek.isSameOrBefore(startOfMaxWeek)) {
      handleDateChange(nextWeek);
    }
  }, [date, startOfMaxWeek, handleDateChange]);

  const jumpToToday = () => {
    const today = dayjs().startOf("month");
    handleDateChange(today);
  };

  // Add event listeners for key presses so we can left / right arrow through orders
  React.useEffect(() => {
    function downHandler({ key }) {
      if (open) return;
      if (key === "ArrowLeft") {
        if (showBackChevron) {
          previousOrder();
        }
      }
      if (key === "ArrowRight") {
        if (beforeMaxDate) {
          nextOrder();
        }
      }
    }

    if (!disableArrowKeys) {
      window.addEventListener("keydown", downHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
      };
    }
  }, [previousOrder, beforeMaxDate, nextOrder, showBackChevron, disableArrowKeys, open]);

  return (
    <DateDiv className="hide-for-print">
      <H3 className="hide-for-print">
        {date.startOf("month").format("Do MMM")} - {date.endOf("month").format("Do MMM YYYY")}
      </H3>
      <ButtonGroup>
        {showBackChevron && (
          <IconButton
            className={`${chevron} hide-for-print`}
            onClick={previousOrder}
            size="large"
          >
            <ArrowBackIosIcon />
          </IconButton>
        )}
        <Button color="primary" className="hide-for-print" onClick={jumpToToday}>
          Jump to Today
        </Button>

        <IconButton className={`${chevronRight} hide-for-print`} onClick={nextOrder} size="large">
          {beforeMaxDate && <ArrowForwardIosIcon />}
        </IconButton>

        <MobileDatePicker
          className="hide-for-print"
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          minDate={minDate}
          maxDate={maxDate}
          format="MMMM Do YYYY"
          value={date.startOf("month")}
          // this ensures the start of the week is Monday and not Sunday like a psychopath.
          onChange={(date) => {
            handleDateChange(date.startOf("month"));
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </ButtonGroup>
    </DateDiv>
  );
};

const DailyDatePickerSection = (props) => {
  const { chevron, chevronRight } = useStyles();
  const { earliestDate = "2020-03-23", disableArrowKeys, maxDate } = props;
  const location = useLocation();
  const { date: paramDate } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const date = dayjs(paramDate, "YYYY-MM-DD", true);
  const minDate = dayjs(earliestDate, "YYYY-MM-DD", true);
  const showBackChevron = date.isAfter(minDate, true);

  const handleDateChange = React.useCallback(
    (newDate) => {
      navigate(
        location.pathname.split("/").slice(0, -1).join("/") + `/${newDate.format("YYYY-MM-DD")}`,
      );
    },
    [navigate, location],
  );

  const previousOrder = React.useCallback(() => {
    const yesterday = date.subtract(1, "day");
    if (yesterday.isSameOrAfter(minDate)) {
      handleDateChange(yesterday);
    }
  }, [minDate, date, handleDateChange]);

  const nextOrder = React.useCallback(() => {
    const tomorrow = date.add(1, "day");
    handleDateChange(tomorrow);
  }, [date, handleDateChange]);

  const jumpToToday = () => {
    const today = dayjs();
    handleDateChange(today);
  };

  // Add event listeners for key presses so we can left / right arrow through orders
  React.useEffect(() => {
    function downHandler({ key }) {
      // Prevents arrow keys if the date picker is open.
      if (open) return;
      if (key === "ArrowLeft") {
        if (showBackChevron) {
          previousOrder();
        }
      }
      if (key === "ArrowRight") {
        nextOrder();
      }
    }

    if (!disableArrowKeys) {
      window.addEventListener("keydown", downHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
      };
    }
  }, [previousOrder, nextOrder, showBackChevron, disableArrowKeys, open]);

  return (
    <DateDiv className="hide-for-print">
      <H3>{date.format("dddd Do MMM")}</H3>
      <ButtonGroup>
        {showBackChevron && (
          <IconButton
            className={`${chevron} hide-for-print`}
            onClick={previousOrder}
            size="large"
          >
            <ArrowBackIosIcon />
          </IconButton>
        )}
        <Button color="primary" className="hide-for-print" onClick={jumpToToday}>
          Jump to Today
        </Button>
        <IconButton className={`${chevronRight} hide-for-print`} onClick={nextOrder} size="large">
          <ArrowForwardIosIcon />
        </IconButton>

        <MobileDatePicker
          className="hide-for-print"
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          minDate={minDate}
          maxDate={maxDate}
          // format={"YYYY-MM-DD"}
          format="MMMM Do YYYY"
          value={date}
          onChange={(date) => {
            handleDateChange(date);
          }}
        />
      </ButtonGroup>
    </DateDiv>
  );
};

const WeeklyDatePickerSection = (props) => {
  const { chevron, chevronRight } = useStyles();
  // We may have to change this if we want to input old data...
  const { earliestDate = "2020-03-23", disableArrowKeys, maxDate } = props;
  const location = useLocation();
  const { date: paramDate } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const date = dayjs(paramDate, "YYYY-MM-DD", true);
  const minDate = dayjs(earliestDate, "YYYY-MM-DD", true).startOf("isoWeek");
  const startOfMaxWeek = dayjs(maxDate, "YYYY-MM-DD", true).startOf("isoWeek");
  const showBackChevron = date.isAfter(minDate, true);
  const beforeMaxDate = date.startOf("isoWeek").isBefore(startOfMaxWeek);

  const handleDateChange = React.useCallback(
    (newDate) => {
      navigate(
        location.pathname.split("/").slice(0, -1).join("/") + `/${newDate.format("YYYY-MM-DD")}`,
      );
    },
    [navigate, location],
  );

  const previousOrder = React.useCallback(() => {
    const lastWeek = date.subtract(7, "day").startOf("isoWeek");
    if (lastWeek.isSameOrAfter(minDate)) {
      handleDateChange(lastWeek);
    }
  }, [minDate, date, handleDateChange]);

  const nextOrder = React.useCallback(() => {
    const nextWeek = date.add(7, "day").startOf("isoWeek");
    if (nextWeek.isSameOrBefore(startOfMaxWeek)) {
      handleDateChange(nextWeek);
    }
  }, [date, startOfMaxWeek, handleDateChange]);

  const jumpToToday = () => {
    const today = dayjs().startOf("isoWeek");
    handleDateChange(today);
  };

  // Add event listeners for key presses so we can left / right arrow through orders
  React.useEffect(() => {
    function downHandler({ key }) {
      if (open) return;
      if (key === "ArrowLeft") {
        if (showBackChevron) {
          previousOrder();
        }
      }
      if (key === "ArrowRight") {
        if (beforeMaxDate) {
          nextOrder();
        }
      }
    }

    if (!disableArrowKeys) {
      window.addEventListener("keydown", downHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
      };
    }
  }, [previousOrder, beforeMaxDate, nextOrder, showBackChevron, disableArrowKeys, open]);

  return (
    <DateDiv className="hide-for-print">
      <H3 className="hide-for-print">
        {date.startOf("isoWeek").format("Do MMM")} - {date.endOf("isoWeek").format("Do MMM")}
      </H3>
      <ButtonGroup>
        {showBackChevron && (
          <IconButton
            className={`${chevron} hide-for-print`}
            onClick={previousOrder}
            size="large"
          >
            <ArrowBackIosIcon />
          </IconButton>
        )}
        <Button color="primary" className="hide-for-print" onClick={jumpToToday}>
          Jump to Today
        </Button>

        <IconButton className={`${chevronRight} hide-for-print`} onClick={nextOrder} size="large">
          {beforeMaxDate && <ArrowForwardIosIcon />}
        </IconButton>

        <MobileDatePicker
          className="hide-for-print"
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          minDate={minDate}
          maxDate={maxDate}
          format="MMMM Do YYYY"
          value={date.startOf("isoWeek")}
          // this ensures the start of the week is Monday and not Sunday like a psychopath.
          onChange={(date) => {
            handleDateChange(date.startOf("isoWeek"));
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </ButtonGroup>
    </DateDiv>
  );
};
