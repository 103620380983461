import React from "react";
import { Routes, Route } from "react-router-dom";
import { NurseryOrderPage } from "pages/nursery/Order";
import { RequireAuth } from "containers/routes/LoggedInRoute";
import { Nurseries } from "pages/admin/Nurseries";
import { NurserySettings } from "pages/admin/NurserySettings";
import { FourOhFour } from "components/FourOhFour";
import { Sensitivities } from "pages/admin/Sensitivities";
import { SensitivitiesCheck } from "pages/admin/SensitivitiesCheck";
import { MealComponents } from "pages/admin/MealComponents";
import { UserManagement } from "pages/admin/UserManagement";
import { Menus } from "pages/admin/Menus";
import { PastMenus } from "pages/admin/PastMenus";
import { OrdersSummary } from "pages/admin/OrdersSummary";
import { DeliveryFormPage } from "pages/nursery/DeliveryFormPage";
import { PDFHome } from "containers/PDFHome";
import { HungryMonstersSettings } from "pages/admin/HungryMonstersSettings";
import { NurseryDietAdvicePage } from "pages/nursery/NurseryDietAdvicePage";
import { Invoices } from "pages/admin/Invoices";
import { NurseryInvoice } from "pages/admin/NurseryInvoice";
import { NurserySettingsList } from "pages/admin/NurserySettingsList";
import { NurseryOrdersList } from "pages/admin/NurseryOrdersList";
import { NurseryDietAdviceList } from "pages/admin/NurseryDietAdviceList";
import { InvoiceGroups } from "pages/admin/InvoiceGroups";
import { DeliveryFormsPage } from "pages/deliveryDriver/DeliveryFormsPage";
import { PrintableDietAdvice } from "pages/admin/PrintableDietAdvice";
import { Tins } from "pages/admin/Tins";
import { PortionSizes } from "pages/admin/PortionSizes";
import { NurseryPortionInfoList } from "pages/admin/NurseryPortionInfoList";
import { NurseryPortionInfo } from "pages/admin/NurseryPortionInfo";
import { PDFUpload } from "pages/admin/PDFUpload";
import { Faqs } from "pages/nursery/Faqs";
import { SetFaqs } from "pages/admin/SetFaqs";
import { PackingReport } from "pages/deliveryDriver/PackingReport";
import { KitchenDistributionReport } from "pages/deliveryDriver/KitchenDistributionReport";

export const AdminHome = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <RequireAuth>
            <PDFHome />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/upload-pdf"
        element={
          <RequireAuth>
            <PDFUpload />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/nurseries"
        element={
          <RequireAuth>
            <Nurseries />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/sensitivities"
        element={
          <RequireAuth>
            <Sensitivities />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/sensitivities-check"
        element={
          <RequireAuth>
            <SensitivitiesCheck />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/menus"
        element={
          <RequireAuth>
            <Menus />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/tins"
        element={
          <RequireAuth>
            <Tins />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/kitchen-distribution-report/date/:date"
        element={
          <RequireAuth>
            <KitchenDistributionReport />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/packing-reports/date/:date"
        element={
          <RequireAuth>
            <PackingReport />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/preview-faqs"
        element={
          <RequireAuth>
            <Faqs />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/faqs"
        element={
          <RequireAuth>
            <SetFaqs />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/portion-sizes"
        element={
          <RequireAuth>
            <PortionSizes />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/nurseries/portion-information/date/:date"
        element={
          <RequireAuth>
            <NurseryPortionInfoList />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/nursery/:nurseryId/portion-information/date/:date"
        element={
          <RequireAuth>
            <NurseryPortionInfo />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/settings"
        element={
          <RequireAuth>
            <HungryMonstersSettings />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/orders-summary/date/:date"
        element={
          <RequireAuth>
            <OrdersSummary />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/past-menus/date/:date"
        element={
          <RequireAuth>
            <PastMenus />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/meal-components"
        element={
          <RequireAuth>
            <MealComponents />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/nursery/:nurseryId"
        element={
          <RequireAuth>
            <NurserySettings />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/user-management"
        element={
          <RequireAuth>
            <UserManagement />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/delivery-forms/date/:date"
        element={
          <RequireAuth>
            <DeliveryFormsPage />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/printable-diet-advice/date/:date"
        element={
          <RequireAuth>
            <PrintableDietAdvice />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/invoice-groups"
        element={
          <RequireAuth>
            <InvoiceGroups />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/invoices/date/:date"
        element={
          <RequireAuth>
            <Invoices />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/nurseries/settings"
        element={
          <RequireAuth>
            <NurserySettingsList />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/nurseries/orders/date/:date"
        element={
          <RequireAuth>
            <NurseryOrdersList />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/nurseries/diet-advice/date/:date"
        element={
          <RequireAuth>
            <NurseryDietAdviceList />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/invoices/nursery/:nurseryId/date/:date"
        element={
          <RequireAuth>
            <NurseryInvoice />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/diet-advice/:nurseryId/date/:date"
        element={
          <RequireAuth>
            <NurseryDietAdvicePage />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/nursery/:nurseryId/date/:date"
        element={
          <RequireAuth>
            <NurseryOrderPage />
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/delivery-forms/:nurseryId/date/:date"
        element={
          <RequireAuth>
            <DeliveryFormPage />
          </RequireAuth>
        }
      />
      <Route element={<FourOhFour />} />
    </Routes>
  );
};
