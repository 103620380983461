import React from "react";
import styled from "styled-components";
import WarningIcon from "@mui/icons-material/Warning";

const Centre = styled.div`
  display: flex;
  justify-content: center;
`;

export const CentredErrorIcon = () => {
  return (
    <Centre>
      <WarningIcon />
    </Centre>
  );
};
