import { gql } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs_with_plugins";
import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { PageHeading } from "components/PageHeading";
import { DatePickerSection } from "components/DatePickerSection";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { some, groupBy } from "lodash";
import { InfoNextToText } from "components/InfoNextToText";
import DoneIcon from "@mui/icons-material/Done";
import { Alerts } from "components/alerts/Alerts";
import {
  Paper,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Checkbox,
  FormControlLabel,
  TableRow,
} from "@mui/material";
import { OrderTabs } from "components/navigation/OrderTabs";
import { green, pink } from "@mui/material/colors";

const LUNCH_MEAL = gql`
  query LunchOrder($date: Date!, $nurseryId: ID!) {
    oneWeeksOrders(date: $date, nurseryId: $nurseryId) {
      id
      lunchOrders {
        ... on EditableLunchOrder {
          id
          date
          closed
          submittedDatetime
          specialMeals
          regularMeal {
            id
            availableMealComponents {
              id
              mealComponent {
                id
                name
                sensitivities {
                  id
                  name
                }
              }
            }
          }
          attendingChildren {
            id
            name
            sensitivities {
              id
              name
            }
            childWithSensitivityId
            meal {
              id
              pastAvailableMealComponents {
                id
                mealComponent {
                  id
                  name
                  sensitivities {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        ... on NonEditableLunchOrder {
          id
          date
          closed
          submittedDatetime
          specialMeals
          regularMeal {
            id
            pastAvailableMealComponents {
              id
              mealComponent {
                id
                name
                sensitivities {
                  id
                  name
                }
              }
            }
          }
          attendingChildren {
            id
            name
            sensitivities {
              id
              name
            }
            childWithSensitivityId
            meal {
              id
              pastAvailableMealComponents {
                id
                mealComponent {
                  id
                  name
                  sensitivities {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const TEA_MEAL = gql`
  query TeaOrder($date: Date!, $nurseryId: ID!) {
    oneWeeksOrders(date: $date, nurseryId: $nurseryId) {
      id
      teaOrders {
        ... on EditableTeaOrder {
          id
          date
          closed
          submittedDatetime
          specialMeals
          regularMeal {
            id
            availableMealComponents {
              id
              mealComponent {
                id
                name
                sensitivities {
                  id
                  name
                }
              }
            }
          }
          attendingChildren {
            id
            name
            sensitivities {
              id
              name
            }
            childWithSensitivityId
            meal {
              id
              pastAvailableMealComponents {
                id
                mealComponent {
                  id
                  name
                  sensitivities {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        ... on NonEditableTeaOrder {
          id
          date
          closed
          submittedDatetime
          specialMeals
          regularMeal {
            id
            pastAvailableMealComponents {
              id
              mealComponent {
                id
                name
                sensitivities {
                  id
                  name
                }
              }
            }
          }
          attendingChildren {
            id
            name
            sensitivities {
              id
              name
            }
            childWithSensitivityId
            meal {
              id
              pastAvailableMealComponents {
                id
                mealComponent {
                  id
                  name
                  sensitivities {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  closedSpiel: { textAlign: "center", margin: "0px 0px 15px 0px" },
  table: { margin: "15px 0px 15px 0px" },
  paper: {
    borderRadius: "0px",
    padding: "25px 50px",
    "@media print": {
      boxShadow: "none",
      padding: "0px",
    },
  },
  printFontSize: { "@media print": { fontSize: "12px" } },
});

export const DietAdviceDayPanelLunch = (props) => {
  const { label, lunch, regularMealComponents, maxComponentCount } = props;
  const { closedSpiel, table, printFontSize } = useStyles();
  // Could be like if total meals == regular meals then there is no diet advice because
  // everyone is having regular meal. That's different from there are no meals.
  const dietAdviceGenerated = lunch && some(lunch.attendingChildren, ({ meal }) => meal);

  if (lunch.closed) {
    return (
      <Typography className={`${closedSpiel} ${printFontSize}`}>
        <InfoNextToText className="hide-for-print" />
        &nbsp; Nursery was closed for lunch on {label}.
      </Typography>
    );
  } else if (lunch && lunch.specialMeals === 0) {
    return (
      <Typography className={`${closedSpiel} ${printFontSize}`}>
        <InfoNextToText className="hide-for-print" />
        &nbsp; You had 0 children that required a special meal.
      </Typography>
    );
  } else if (!dietAdviceGenerated) {
    return (
      <Typography className={`${closedSpiel} ${printFontSize}`}>
        <InfoNextToText className="hide-for-print" />
        &nbsp; Diet Advice has not been generated yet. Diet Advice is generated once the deadline
        has passed.
      </Typography>
    );
  } else {
    const regMealMap = groupBy(regularMealComponents, "name");
    const mealLength = regularMealComponents.length;
    // It should never be negative so we don't guard against it.
    const diff = maxComponentCount - mealLength;
    const padding = [...Array(diff).keys()];
    const columnStyle = { width: "100px" };
    return (
      <TableContainer className={table}>
        <Table stickyHeader size="small" padding="checkbox">
          <TableHead>
            <TableRow style={{ pageBreakInside: "avoid" }}>
              <TableCell style={{ width: "85px" }}>
                <Typography variant="subtitle2">Child Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Sensitivities</Typography>
              </TableCell>
              {regularMealComponents.map((mealComponent) => {
                const { name, id, sensitivities } = mealComponent;
                const compSens = sensitivities.map(({ name }) => name).join(", ");
                const title = compSens ? `Contains: ${compSens}` : `Contains no sensitivities`;
                return (
                  <TableCell style={columnStyle} align="center" key={id}>
                    <Tooltip title={title} placement="top-start">
                      <Typography variant="subtitle2" className={printFontSize}>
                        {name}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                );
              })}
              {padding.map((x, index) => {
                // Not ideal to use index here, but I think it is the same as not and I don't
                // have a uniq id to give it. The index should be the same between renders because
                // the length of the meal isn't changing.
                return <TableCell style={columnStyle} align="center" key={index} />;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {lunch.attendingChildren.map((child) => {
              const { id, name, meal, sensitivities } = child;
              return (
                <TableRow key={id} hover style={{ pageBreakInside: "avoid" }}>
                  <TableCell>
                    <Typography className={printFontSize}>{name}</Typography>
                  </TableCell>
                  <TableCell style={{ maxWidth: "200px" }}>
                    <Typography
                      variant="body1"
                      className={printFontSize}
                      style={{ color: pink[500] }}
                    >
                      {sensitivities.map(({ name }) => name).join(", ")}
                    </Typography>
                  </TableCell>
                  {/* The only case for a meal not being there is when a lunch menu has no subs on
                      it, and so the child can eat nothing on the menu. In reality that should all
                      be fixed long before this page is viewed but we show that it hasn't been
                      generated for completeness.
                  */}
                  {meal
                    ? meal.pastAvailableMealComponents.map(({ mealComponent }) => {
                        const { id, name, sensitivities } = mealComponent;

                        if (regMealMap[name]) {
                          const compSens = regMealMap[name][0].sensitivities
                            .map(({ name }) => name)
                            .join(", ");
                          const title = compSens
                            ? `Contains: ${compSens}`
                            : `Contains no sensitivities`;
                          return (
                            <TableCell align="center" key={id}>
                              <Tooltip
                                title={title}
                                className={printFontSize}
                                placement="top-start"
                              >
                                <DoneIcon style={{ color: green[500] }} />
                              </Tooltip>
                            </TableCell>
                          );
                        } else {
                          const compSens = sensitivities.map(({ name }) => name).join(", ");
                          const title = compSens
                            ? `Contains: ${compSens}`
                            : `Contains no sensitivities`;

                          return (
                            <TableCell align="center" key={id}>
                              <Tooltip title={title} placement="top-start">
                                <Typography variant="body1" className={printFontSize}>
                                  {name}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                          );
                        }
                      })
                    : regularMealComponents.map((mealComponent) => {
                        return (
                          <TableCell key={mealComponent.id}>Diet Advice Not Generated</TableCell>
                        );
                      })}
                  {padding.map((x, index) => {
                    // Not ideal to use index here, but I think it is the same as not and I don't
                    // have a uniq id to give it. The index should be the same between renders because
                    // the length of the meal isn't changing.
                    return (
                      <TableCell style={columnStyle} align="center" key={index}>
                        -
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export const DietAdviceDayPanelTea = (props) => {
  const { label, tea, maxComponentCount, regularMealComponents } = props;
  const { closedSpiel, table, printFontSize } = useStyles();
  const dietAdviceGenerated = tea && some(tea.attendingChildren, ({ meal }) => meal);
  if (tea.closed) {
    return (
      <Typography className={`${closedSpiel} ${printFontSize}`}>
        <InfoNextToText className="hide-for-print" />
        &nbsp; Nursery was closed for tea on {label}.
      </Typography>
    );
  } else if (tea && tea.specialMeals === 0) {
    return (
      <Typography className={`${closedSpiel} ${printFontSize}`}>
        <InfoNextToText className="hide-for-print" />
        &nbsp; You had 0 children that required a special meal.
      </Typography>
    );
  } else if (!dietAdviceGenerated) {
    return (
      <Typography className={`${closedSpiel} ${printFontSize}`}>
        <InfoNextToText className="hide-for-print" />
        &nbsp; Diet Advice has not been generated yet. Diet Advice is generated once the deadline
        has passed.
      </Typography>
    );
  } else {
    // This will not work when there are multiple components that are the same... which happens
    // a lot for Tea when there are multiple things that cannot be eaten.
    const regMealMap = groupBy(regularMealComponents, "name");
    const mealLength = regularMealComponents.length;
    // It should never be negative so we don't guard against it.
    const diff = maxComponentCount - mealLength;
    const padding = [...Array(diff).keys()];
    const columnStyle = { width: "100px" };
    return (
      <TableContainer className={table}>
        <Table stickyHeader size="small" padding="checkbox">
          <TableHead>
            <TableRow style={{ pageBreakInside: "avoid" }}>
              <TableCell style={{ width: "85px" }}>
                <Typography variant="subtitle2">Child Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Sensitivities</Typography>
              </TableCell>
              {regularMealComponents.map((mealComponent) => {
                const { name, id, sensitivities } = mealComponent;
                const compSens = sensitivities.map(({ name }) => name).join(", ");
                const title = compSens ? `Contains: ${compSens}` : `Contains no sensitivities`;
                return (
                  <TableCell style={columnStyle} align="center" key={id}>
                    <Tooltip title={title} placement="top-start">
                      <Typography variant="subtitle2" className={printFontSize}>
                        {name}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                );
              })}
              {padding.map((x, index) => {
                // Not ideal to use index here, but I think it is the same as not and I don't
                // have a uniq id to give it. The index should be the same between renders because
                // the length of the meal isn't changing.
                return <TableCell style={columnStyle} align="center" key={index} />;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tea.attendingChildren.map((child) => {
              const { id, name, meal, sensitivities } = child;
              /* Meal can be null in the event that a child cannot eat anything on the Tea menu */
              /* Which while ideally shouldn't happen apparently can....*/

              if (!meal) {
                return (
                  <TableRow key={id} hover style={{ pageBreakInside: "avoid" }}>
                    <TableCell>
                      <Typography className={printFontSize} variant="body1">
                        {name}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ maxWidth: "150px" }}>
                      <Typography
                        variant="body1"
                        className={printFontSize}
                        style={{ color: pink[500] }}
                      >
                        {sensitivities.map(({ name }) => name).join(", ")}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className={printFontSize}>
                        Child cannot eat any of the components on the Tea Menu today. Alternatives
                        can be purchased separately via Hungry Monsters.
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              }
              return (
                <TableRow key={id} hover style={{ pageBreakInside: "avoid" }}>
                  <TableCell>
                    <Typography className={printFontSize} variant="body1">
                      {name}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ maxWidth: "150px" }}>
                    <Typography
                      variant="body1"
                      className={printFontSize}
                      style={{ color: pink[500] }}
                    >
                      {sensitivities.map(({ name }) => name).join(", ")}
                    </Typography>
                  </TableCell>
                  {/* This has to be the same order as the reg meal */}
                  {meal.pastAvailableMealComponents.map(({ id, mealComponent }) => {
                    const { name, sensitivities } = mealComponent;

                    if (regMealMap[name]) {
                      const compSens = regMealMap[name][0].sensitivities
                        .map(({ name }) => name)
                        .join(", ");
                      const title = compSens
                        ? `Contains: ${compSens}`
                        : `Contains no sensitivities`;
                      return (
                        <TableCell align="center" key={id}>
                          <Tooltip title={title} className={printFontSize} placement="top-start">
                            <DoneIcon style={{ color: green[500] }} />
                          </Tooltip>
                        </TableCell>
                      );
                    } else {
                      const compSens = sensitivities.map(({ name }) => name).join(", ");
                      const title = compSens
                        ? `Contains: ${compSens}`
                        : `Contains no sensitivities`;

                      return (
                        <TableCell align="center" key={id}>
                          <Tooltip title={title} placement="top-start">
                            <Typography variant="body1" className={printFontSize}>
                              {name}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      );
                    }
                  })}
                  {padding.map((x, index) => {
                    // Not ideal to use index here, but I think it is the same as not and I don't
                    // have a uniq id to give it. The index should be the same between renders because
                    // the length of the meal isn't changing.
                    return (
                      <TableCell style={columnStyle} align="center" key={index}>
                        <Typography variant="body1" className={printFontSize}>
                          -
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

const PrintCSS = styled.div`
  @media print {
    orientation: landscape;
    .hide-for-print {
      display: none;
    }
    @page :first {
      margin-top: 0px;
    }
    @page {
      margin: 50px 0px 50px 0px;
    }
  }
`;

const NURSERY_NAME = gql`
  query Nursery($id: ID!) {
    nursery(id: $id) {
      id
      name
    }
  }
`;

export const NurseryDietAdvicePage = (props) => {
  const params = useParams();

  return (
    <GraphqlQuery query={NURSERY_NAME} variables={{ id: params.nurseryId }} withLoading>
      {({ data, error }) => {
        if (error) {
          return (
            <React.Fragment>
              <Alerts.Popup
                title="Error"
                message="Could not find a nursery with that ID check the url is correct."
                doAlert={Boolean(error.message)}
              />
              <CentredErrorIcon />
            </React.Fragment>
          );
        }

        return <ActualNurseryDietAdvicePage nurseryName={data.nursery.name} {...props} />;
      }}
    </GraphqlQuery>
  );
};

const LunchAdvice = (props) => {
  const { params, paper, pageBreak } = props;
  return (
    <GraphqlQuery
      fetchPolicy="network-only"
      query={LUNCH_MEAL}
      variables={params}
      withError
      withLoading
    >
      {({ data, error }) => {
        if (error) {
          return <CentredErrorIcon />;
        }

        const {
          oneWeeksOrders: { lunchOrders },
        } = data;

        const maxComponentCount = Math.max(
          ...lunchOrders.map(({ regularMeal }) => {
            if (!regularMeal) {
              return 0;
            }
            switch (regularMeal.__typename) {
              case "AvailableMeal":
                return regularMeal.availableMealComponents.length || 0;
              case "PastAvailableMeal":
                return regularMeal.pastAvailableMealComponents.length || 0;
              default:
                throw new Error("unsupported meal type");
            }
          }),
        );

        if (lunchOrders.length > 0) {
          return (
            <Paper classes={{ root: paper }}>
              {lunchOrders.length > 0 &&
                lunchOrders.map((lunchOrder) => {
                  const label = dayjs(lunchOrder.date, "YYYY-MM-DD").format("dddd DD/MM/YY");

                  const regularMealComponents = () => {
                    if (!lunchOrder.regularMeal) {
                      return [];
                    }
                    switch (lunchOrder.regularMeal.__typename) {
                      case "AvailableMeal":
                        return lunchOrder.regularMeal.availableMealComponents.map(
                          ({ mealComponent: c }) => c,
                        );
                      case "PastAvailableMeal":
                        return lunchOrder.regularMeal.pastAvailableMealComponents.map(
                          ({ mealComponent: c }) => c,
                        );
                      default:
                        throw new Error("unsupported meal type");
                    }
                  };
                  return (
                    <div
                      style={{
                        pageBreakInside: "avoid",
                        breakAfter: pageBreak ? "page" : "auto",
                      }}
                      key={lunchOrder.id}
                    >
                      <Typography variant="button">{label}</Typography>
                      <DietAdviceDayPanelLunch
                        maxComponentCount={maxComponentCount}
                        regularMealComponents={regularMealComponents()}
                        label={label}
                        lunch={lunchOrder}
                        key={lunchOrder.id}
                      />
                    </div>
                  );
                })}
            </Paper>
          );
        } else {
          return (
            <Typography style={{ textAlign: "center", marginTop: "30px" }}>
              <InfoNextToText />
              &nbsp; Lunch Orders have not been created for this week.
            </Typography>
          );
        }
      }}
    </GraphqlQuery>
  );
};

const TeaDietAdvice = (props) => {
  const { paper, params, pageBreak } = props;
  return (
    <GraphqlQuery
      fetchPolicy="network-only"
      query={TEA_MEAL}
      variables={params}
      withError
      withLoading
    >
      {({ data, error }) => {
        if (error) {
          return <CentredErrorIcon />;
        }

        const {
          oneWeeksOrders: { teaOrders },
        } = data;
        const maxComponentCount = Math.max(
          ...teaOrders.map(({ regularMeal }) => {
            if (!regularMeal) {
              return 0;
            }
            switch (regularMeal.__typename) {
              case "AvailableMeal":
                return regularMeal.availableMealComponents.length || 0;
              case "PastAvailableMeal":
                return regularMeal.pastAvailableMealComponents.length || 0;
              default:
                throw new Error("unsupported meal type");
            }
          }),
        );

        if (teaOrders.length > 0) {
          return (
            <Paper classes={{ root: paper }}>
              {teaOrders.length > 0 &&
                teaOrders.map((teaOrder) => {
                  const label = dayjs(teaOrder.date, "YYYY-MM-DD").format("dddd DD/MM/YY");
                  const regularMealComponents = () => {
                    if (!teaOrder.regularMeal) {
                      return [];
                    }
                    switch (teaOrder.regularMeal.__typename) {
                      case "AvailableMeal":
                        return teaOrder.regularMeal.availableMealComponents.map(
                          ({ mealComponent: c }) => c,
                        );
                      case "PastAvailableMeal":
                        return teaOrder.regularMeal.pastAvailableMealComponents.map(
                          ({ mealComponent: c }) => c,
                        );
                      default:
                        throw new Error("unsupported meal type");
                    }
                  };
                  return (
                    <div
                      style={{
                        pageBreakInside: "avoid",
                        breakAfter: pageBreak ? "page" : "auto",
                      }}
                      key={teaOrder.id}
                    >
                      <Typography variant="button">{label}</Typography>
                      <DietAdviceDayPanelTea
                        label={label}
                        tea={teaOrder}
                        regularMealComponents={regularMealComponents()}
                        maxComponentCount={maxComponentCount}
                        key={teaOrder.id}
                      />
                    </div>
                  );
                })}
            </Paper>
          );
        } else {
          return (
            <Typography style={{ textAlign: "center", marginTop: "30px" }}>
              <InfoNextToText />
              &nbsp; Tea Orders have not been created for this week.
            </Typography>
          );
        }
      }}
    </GraphqlQuery>
  );
};

const ActualNurseryDietAdvicePage = (props) => {
  const { nurseryName } = props;
  const params = useParams();
  const [printHeading, setPrintHeading] = React.useState(`Diet Advice - Lunch - ${nurseryName}`);
  const [pageBreak, setPageBreak] = React.useState(false);

  const { paper } = useStyles();
  const tabs = [
    { label: "Lunch", disabled: false },
    { label: "Tea", disabled: false },
  ];

  return (
    <PrintCSS>
      <PageHeading
        heading={`Diet Advice - ${nurseryName}`}
        maxWidth="lg"
        printLabel={printHeading}
      >
        <DatePickerSection
          type="weekly"
          maxDate={dayjs().add(3, "month").startOf("isoWeek")}
          id="date-picker-section"
          earliestDate="2020-03-23"
          disableArrowKeys={false}
        />
        <FormControlLabel
          style={{ marginBottom: "15px" }}
          className="hide-for-print"
          label="Print Each Day On A New Page"
          control={
            <Checkbox
              variant="outlined"
              value={pageBreak}
              onChange={() => setPageBreak(!pageBreak)}
            />
          }
        />
        <OrderTabs
          onTabSwitch={(tabIndex) => {
            setPrintHeading(`Diet Advice - ${tabs[tabIndex].label} - ${nurseryName}`);
          }}
          tabs={tabs}
        >
          <LunchAdvice params={params} paper={paper} pageBreak={pageBreak} />
          <TeaDietAdvice paper={paper} params={params} pageBreak={pageBreak} />
        </OrderTabs>
      </PageHeading>
    </PrintCSS>
  );
};
