import { gql } from "@apollo/client";
import React from "react";
import dayjs from "dayjs_with_plugins";
import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import { InfoNextToText } from "components/InfoNextToText";
import { useTheme } from "@mui/material/styles";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { media } from "media";
import {
  Container,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";

const StartEndDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.sm`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  `}
`;

const P = styled.p`
  font-size: 16px;
  position: relative;
  top: 16px;
  ${media.sm`
    top: 0;
    margin-bottom: 0;
  `}
`;

const CREATE_MENU = gql`
  mutation CreateMenu($input: CreateMenuInput!) {
    createMenu(input: $input) {
      id
      name
    }
  }
`;

const useStyles = makeStyles({
  inputStyles: { marginBottom: "15px", width: "66%" },
  container: { paddingTop: "30px", maxWidth: "750px" },
});

export const AddMenu = (props) => {
  const { onCancel, onSubmit, open, refetchQueries } = props;
  const [menuName, updateMenuName] = React.useState("");
  const defaultState = { activeFrom: null, activeUntil: null };
  const [dates, setDates] = React.useState(defaultState);
  const { inputStyles, container } = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const resetForm = () => {
    updateMenuName("");
    setDates(defaultState);
  };
  return (
    <Dialog disableEscapeKeyDown fullScreen={fullScreen} fullWidth maxWidth="md" open={open}>
      <Container className={container}>
        <DialogTitle>
          <Typography variant="h4" component="h4">
            Create A Menu
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            className={inputStyles}
            required
            autoFocus
            label="Enter menu name..."
            value={menuName}
            onChange={(event) => updateMenuName(event.target.value)}
          />
        </DialogContent>
        <DialogContent>
          <Typography variant="body2" component="p">
            <InfoNextToText /> A Menu consists of one or more sub menus. Each sub menu lasts one
            week. The menu cycles through each sub menu before repeating, for the whole time it is
            active.
          </Typography>
          <br />
          <Typography variant="body2" component="p">
            For example if you created sub menus A, B and C, then for the entire time the menu is
            active, the first week Menu A would be active, then B then C, then A again and so on.
          </Typography>
          <br />
          <Typography variant="body2" component="p">
            You can set the date range a menu is active for below. The start date wil always be a
            Monday and the end date will always be a Sunday. After that you can create the sub
            menus.
          </Typography>
          <br />
        </DialogContent>
        <DialogContent>
          <Typography variant="h5">Set Active Dates</Typography>
        </DialogContent>
        <DialogContent>
          <StartEndDate>
            <MobileDatePicker
              clearable
              variant="dialog"
              label="Start Date"
              format="MMMM Do YYYY"
              minDate={dayjs()}
              value={dates.activeFrom}
              onChange={(activeFrom) => {
                if (activeFrom) {
                  setDates({ ...dates, activeFrom: activeFrom });
                } else {
                  setDates(defaultState);
                }
              }}
            />
            <P>To</P>
            <MobileDatePicker
              clearable
              disabled={!dates.activeFrom}
              allowKeyboardControl
              variant="dialog"
              minDate={dates.activeFrom}
              label="End date"
              format="MMMM Do YYYY"
              value={dates.activeUntil}
              onChange={(activeUntil) => {
                if (activeUntil) {
                  setDates({ ...dates, activeUntil: activeUntil });
                } else {
                  setDates({ ...dates, activeUntil });
                }
              }}
            />
          </StartEndDate>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              resetForm();
              onCancel();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <GraphqlMutation mutation={CREATE_MENU} withError>
            {(createMenu) => {
              const variables = {
                input: {
                  name: menuName,
                  activeFrom: dates.activeFrom?.format("YYYY-MM-DD"),
                  activeUntil: dates.activeUntil?.format("YYYY-MM-DD"),
                },
              };
              const submit = async () => {
                const data = await createMenu({ variables, refetchQueries });
                if (!data.data) {
                  return;
                }
                resetForm();
                onSubmit();
              };
              return (
                <Button
                  disabled={!menuName || !dates.activeFrom || !dates.activeUntil}
                  onClick={submit}
                  color="primary"
                >
                  Submit
                </Button>
              );
            }}
          </GraphqlMutation>
        </DialogActions>
      </Container>
    </Dialog>
  );
};
