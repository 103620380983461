import React from "react";
import { gql } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { TextInput } from "components/inputs/Inputs";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  Typography,
  useMediaQuery,
} from "@mui/material";

const CREATE_TIN = gql`
  mutation CreateTin($input: CreateTinInput!) {
    createTin(input: $input) {
      id
      name
    }
  }
`;

const useStyles = makeStyles({
  dialogContent: { display: "flex", flexDirection: "column" },
  inputStyle: { width: "100%", maxWidth: "350px", marginBottom: "15px" },
});

export const AddTinModal = (props) => {
  const { open, onCancel, refetchQueries, onSubmit } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { inputStyle, dialogContent } = useStyles();
  const [name, updateName] = React.useState();
  const resetForm = () => updateName();

  return (
    <Dialog scroll="paper" disableEscapeKeyDown fullScreen={fullScreen} fullWidth open={open}>
      <DialogTitle>
        <Typography variant="h4" component="h4">
          Add Tin
        </Typography>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <TextInput
          required
          autoFocus
          variant="outlined"
          className={inputStyle}
          label="Name..."
          value={name}
          onChange={(event) => updateName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            onCancel();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <GraphqlMutation mutation={CREATE_TIN} withError>
          {(createTin) => {
            const submit = async () => {
              if (!name) return;
              const variables = { input: { name } };
              const result = await createTin({ variables, refetchQueries });
              if (result.data) {
                resetForm();
                onSubmit();
              }
              return result;
            };

            return (
              <Button disabled={!name} onClick={submit} color="primary">
                Create
              </Button>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </Dialog>
  );
};
