import { gql } from "@apollo/client";

export const ACTIVE_MEAL_COMPONENTS = gql`
  query MealComponents($active: Boolean!) {
    mealComponents(active: $active) {
      id
      name
      sensitivityInformation(present: $active) {
        id
        sensitivityName
        present
      }
    }
  }
`;

export const CURRENT_MENU = gql`
  query CurrentSubMenu($date: Date!) {
    currentSubMenu(date: $date) {
      id
      name
      order
      menuId
      currentlyActive
      uniqMealComponents {
        id
        name
      }
      meals {
        ... on LunchMeal {
          id
          type
          dayName
          componentsWithSubstitutions {
            id
            columnNumber
            mealComponent {
              id
              name
            }
            substitutions {
              id
              order
              substitutionId
              substitution {
                id
                name
              }
            }
          }
        }
        ... on TeaMeal {
          id
          type
          dayName
          componentsWithSubstitutions {
            id
            columnNumber
            mealComponent {
              id
              name
            }
            substitutions {
              id
              order
              substitutionId
              substitution {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const SUB_MENU = gql`
  query SubMenu($id: ID!) {
    subMenu(id: $id) {
      id
      name
      order
      menuId
      currentlyActive
      uniqMealComponents {
        id
        name
      }
      meals {
        ... on LunchMeal {
          id
          type
          dayName
          componentsWithSubstitutions {
            id
            columnNumber
            mealComponent {
              id
              name
            }
            substitutions {
              id
              order
              substitutionId
              substitution {
                id
                name
              }
            }
          }
        }
        ... on TeaMeal {
          id
          type
          dayName
          componentsWithSubstitutions {
            id
            columnNumber
            mealComponent {
              id
              name
            }
            substitutions {
              id
              order
              substitutionId
              substitution {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_SUB_MENU = gql`
  mutation UpdateSubMenu($input: UpdateSubMenuInput!) {
    updateSubMenu(input: $input) {
      id
      name
      order
      menuId
      currentlyActive
      meals {
        ... on LunchMeal {
          id
          type
          dayName
          componentsWithSubstitutions {
            id
            columnNumber
            mealComponent {
              id
              name
            }
            substitutions {
              id
              order
              substitutionId
              substitution {
                id
                name
              }
            }
          }
        }
        ... on TeaMeal {
          id
          type
          dayName
          componentsWithSubstitutions {
            id
            columnNumber
            mealComponent {
              id
              name
            }
            substitutions {
              id
              order
              substitutionId
              substitution {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

// Week will always be the present only.

export const CHILDREN_NOT_SERVED_LUNCH_ON_MENU = gql`
  query ChildrenNotServedForWeek($date: Date!, $subMenuId: ID!) {
    childrenNotServedForLunchForWeek(date: $date, subMenuId: $subMenuId) {
      id
      name
      datesNotServedLunch
      inedibleComponentsForChildOnMenu(subMenuId: $subMenuId) {
        id
        name
      }
      nursery {
        id
        name
        address
      }
      sensitivities {
        id
        name
      }
    }
  }
`;

// This just returns data in the wrong format completely.
export const CHILDREN_NOT_SERVED_TEA_ON_MENU = gql`
  query ChildrenNotServedForTeaForWeek($date: Date!, $subMenuId: ID!) {
    childrenNotServedForTeaForWeek(date: $date, subMenuId: $subMenuId) {
      id
      name
      datesNotServedTea
      inedibleComponentsForChildOnMenu(subMenuId: $subMenuId) {
        id
        name
      }
      nursery {
        id
        name
        address
      }
      sensitivities {
        id
        name
      }
    }
  }
`;

// These both a PAST queries meaning they are only for the day and the current menu.
// we don't ask for the week, or

export const CHILDREN_NOT_SERVED_LUNCH_FOR_DAY = gql`
  query PastChildrenNotServedForDayLunch($date: Date!) {
    pastChildrenNotServedForLunchForDay(date: $date) {
      id
      name
      datesNotServedLunch
      nursery {
        id
        name
        address
      }
      sensitivities {
        id
        name
      }
    }
  }
`;

export const CHILDREN_NOT_SERVED_TEA_FOR_DAY = gql`
  query PastChildrenNotServedForDayTea($date: Date!) {
    pastChildrenNotServedForTeaForDay(date: $date) {
      id
      name
      datesNotServedTea
      nursery {
        id
        name
        address
      }
      sensitivities {
        id
        name
      }
    }
  }
`;

export const CREATE_SUB_MENU = gql`
  mutation CreateSubMenu($input: CreateSubMenuInput!) {
    createSubMenu(input: $input) {
      id
      name
      order
      menuId
      meals {
        ... on LunchMeal {
          id
          type
          dayName
          componentsWithSubstitutions {
            id
            columnNumber
            mealComponent {
              id
              name
            }
            substitutions {
              id
              order
              substitutionId
              substitution {
                id
                name
              }
            }
          }
        }
        ... on TeaMeal {
          id
          type
          dayName
          componentsWithSubstitutions {
            id
            columnNumber
            mealComponent {
              id
              name
            }
            substitutions {
              id
              order
              substitutionId
              substitution {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_SUB_MENU = gql`
  mutation DeleteSubMenu($id: ID!) {
    deleteSubMenu(id: $id) {
      id
      name
      currentlyActive
    }
  }
`;

export const ORDER_SUB_MENUS = gql`
  mutation OrderSubMenu($input: OrderSubMenuInput!) {
    orderSubMenus(input: $input) {
      id
      subMenus {
        id
        order
        currentlyActive
      }
    }
  }
`;

export const SUB_MENUS = gql`
  query Menu($id: ID!) {
    menu(id: $id) {
      id
      name
      activeFrom
      activeUntil
      subMenus {
        order
        id
        name
        currentlyActive
      }
    }
  }
`;
