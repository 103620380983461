import { gql } from "@apollo/client";
import React from "react";
import dayjs from "dayjs_with_plugins";
import { OrderTabs } from "components/navigation/OrderTabs";
import { PageHeading } from "components/PageHeading";
import { DatePickerSection } from "components/DatePickerSection";
import { LunchOrder } from "containers/orders/LunchOrder";
import { TeaOrder } from "containers/orders/TeaOrder";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";

const NURSERY = gql`
  query nursery($id: ID!) {
    nursery(id: $id) {
      id
      name
      dateJoined
    }
  }
`;

/**
We do a query to the backend using params from the url. But if the url has been written wrong we
want to 404, not show a graphql error. This component does a query for the data needed for a
NurseryOrder page and redirects to a 404 page if that query errors. The slight wrinkle is it will
404 for _any_ error, like a network error. Would be good to just capture the case where we can't
find a response, or params are incorrect but meh.

I wonder if there is a generic component in here somewhere.
**/

export const NurseryOrderPage = () => {
  const params = useParams();
  const [thereIsAModalOpen, setThereIsAModalOpen] = React.useState(false);
  const openModal = () => setThereIsAModalOpen(true);
  const closeModal = () => setThereIsAModalOpen(false);

  return (
    <GraphqlQuery query={NURSERY} variables={{ id: params.nurseryId }}>
      {({ data, error }) => {
        if (error) {
          return <CentredErrorIcon />;
        }

        return (
          <PageHeading maxWidth="md" heading="Orders" headingStyle={{ marginBottom: "0px" }}>
            <Typography variant="h4" style={{ marginBottom: "20px" }}>
              {data.nursery.name}
            </Typography>
            <DatePickerSection
              type="weekly"
              earliestDate={data.nursery.dateJoined}
              disableArrowKeys={thereIsAModalOpen}
              maxDate={dayjs().add(3, "month").startOf("isoWeek")}
            />
            <OrderTabs
              disableKeyboardShortcuts={thereIsAModalOpen}
              tabs={[
                // These are always false because you could still have an order open that needs
                // fulfilling after your provider for lunches has been removed.
                { label: "Lunch", disabled: false },
                { label: "Tea", disabled: false },
              ]}
            >
              <LunchOrder onCloseModal={closeModal} onOpenModal={openModal} params={params} />
              <TeaOrder onCloseModal={closeModal} onOpenModal={openModal} params={params} />
            </OrderTabs>
          </PageHeading>
        );
      }}
    </GraphqlQuery>
  );
};
