import { gql } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import { useParams } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import dayjs from "dayjs_with_plugins";
import { PageHeading } from "components/PageHeading";
import { DatePickerSection } from "components/DatePickerSection";
import { InfoNextToText } from "components/InfoNextToText";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Paper,
  Button,
  Link,
  Typography,
  TableBody,
  Table,
  TableCell,
  TableRow,
  FormControlLabel,
  Checkbox,
  IconButton,
  TableHead,
} from "@mui/material";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { OrderTabs } from "components/navigation/OrderTabs";
import makeStyles from "@mui/styles/makeStyles";
import { AddAdjustmentModal } from "containers/invoices/AddAdjustmentModal";
import { Alerts } from "components/alerts/Alerts";

const NURSERY = gql`
  query nursery($id: ID!) {
    nursery(id: $id) {
      id
      name
    }
  }
`;

const XERO_URL = gql`
  query XeroLoginUrl($feRedirectUrl: String!) {
    xeroLoginUrl(feRedirectUrl: $feRedirectUrl)
  }
`;

const IS_LOGGED_IN = gql`
  query IsUserLoggedInToXero {
    isUserLoggedInToXero
  }
`;

const DISCONNECT_FROM_XERO = gql`
  mutation Disconnect {
    disconectFromXero
  }
`;

const HM_INVOICE = gql`
  query Invoice($date: Date!, $nurseryId: ID!) {
    hungryMonstersInvoice(date: $date, nurseryId: $nurseryId) {
      id
      startDate
      endDate
      vatPercentage
      includeVat
      total
      lineItems {
        id
        description
        quantity
        unitCost
        total
        deletable
      }
    }
  }
`;

const HT_INVOICE = gql`
  query Invoice($date: Date!, $nurseryId: ID!) {
    helensTeasInvoice(date: $date, nurseryId: $nurseryId) {
      id
      startDate
      endDate
      vatPercentage
      includeVat
      total
      lineItems {
        id
        description
        quantity
        unitCost
        total
        deletable
      }
    }
  }
`;

const DELETE_LINE_ITEM = gql`
  mutation Delete($id: ID!) {
    deleteLineItem(id: $id) {
      id
    }
  }
`;

const DELETE_HT_LINE_ITEM = gql`
  mutation Delete($id: ID!) {
    deleteHtLineItem(id: $id) {
      id
    }
  }
`;

const UPSERT_INVOICE = gql`
  mutation UpsertInvoice($input: UpsertInvoiceInput!) {
    upsertHungryMonstersInvoice(input: $input) {
      id
      startDate
      endDate
      vatPercentage
      includeVat
      total
      lineItems {
        id
        description
        quantity
        unitCost
        total
        deletable
      }
    }
  }
`;

const UPSERT_HT_INVOICE = gql`
  mutation UpsertInvoice($input: UpsertHtInvoiceInput!) {
    upsertHelensTeasInvoice(input: $input) {
      id
      startDate
      endDate
      vatPercentage
      includeVat
      total
      lineItems {
        id
        description
        quantity
        unitCost
        total
        deletable
      }
    }
  }
`;

const PUSH_HT_TO_XERO = gql`
  mutation PushHtInvoice($invoiceId: ID!) {
    pushHtInvoiceToXero(invoiceId: $invoiceId) {
      id
    }
  }
`;

const PUSH_HM_TO_XERO = gql`
  mutation PushInvoice($invoiceId: ID!) {
    pushHmInvoiceToXero(invoiceId: $invoiceId) {
      id
    }
  }
`;

const NULLIFY_HM_XERO_INVOICE = gql`
  mutation NillifyHmXeroIds($id: ID!) {
    nillifyHmXeroIds(id: $id) {
      id
      startDate
      endDate
      vatPercentage
      includeVat
      lineItems {
        id
        description
        quantity
        unitCost
      }
    }
  }
`;

const NULLIFY_HT_XERO_INVOICE = gql`
  mutation NillifyHtXeroIds($id: ID!) {
    nillifyHtXeroIds(id: $id) {
      id
      startDate
      endDate
      vatPercentage
      includeVat
      lineItems {
        id
        description
        quantity
        unitCost
      }
    }
  }
`;

const Flexin = styled.div`
  max-width: 850px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
`;

const XeroButtons = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`;

const useStyles = makeStyles({ formLabel: { "& span": { fontSize: "14px" } } });

const NullifyButton = (props) => {
  const { mutation, id } = props;

  return (
    <GraphqlMutation mutation={mutation} withError withLoading>
      {(nullify, { loading }) => {
        const onClick = async () => {
          if (
            window.confirm(
              `This will remove the connection between the invoice and Xero.

Only do this if you have deleted this specific invoice on Xero,
otherwise you risk creating multiple invoices for the same month.`,
            )
          ) {
            await nullify({ variables: { id } });
          }
        };
        return (
          <Button variant="outlined" disabled={loading} onClick={onClick}>
            Nullify Xero Ids
          </Button>
        );
      }}
    </GraphqlMutation>
  );
};

const XeroButton = (props) => {
  const { isUserLoggedInToXero } = props;
  return (
    <XeroButtons>
      <GraphqlMutation mutation={DISCONNECT_FROM_XERO} withError>
        {(disconnect) => {
          const variables = {};
          const onClick = async () => {
            await disconnect({
              variables,
              refetchQueries: [getOperationName(IS_LOGGED_IN)],
            });
          };
          return (
            <Button
              disabled={!isUserLoggedInToXero}
              style={{ marginRight: "5px" }}
              color="secondary"
              variant="outlined"
              onClick={onClick}
            >
              Disconnect From Xero
            </Button>
          );
        }}
      </GraphqlMutation>

      <GraphqlQuery
        query={XERO_URL}
        variables={{ feRedirectUrl: window.location.href }}
        withError
        withLoading
      >
        {({ data, error }) => {
          if (error) return <CentredErrorIcon />;
          return (
            <Button disabled={isUserLoggedInToXero} variant="outlined" color="primary">
              {isUserLoggedInToXero ? (
                "Connect to Xero"
              ) : (
                <Link href={data.xeroLoginUrl}>Connect to Xero</Link>
              )}
            </Button>
          );
        }}
      </GraphqlQuery>
    </XeroButtons>
  );
};

const HelensTeasInvoice = (props) => {
  const { formLabel } = useStyles();
  const { params, setOpenModal, openModal, isUserLoggedInToXero } = props;
  const [publishedPopup, setPublishedPopup] = React.useState(false);

  return (
    <GraphqlQuery query={HT_INVOICE} variables={params} withError>
      {({ data, error }) => {
        if (error) return <CentredErrorIcon />;

        if (!data.helensTeasInvoice) {
          return (
            <Paper style={{ padding: "30px" }}>
              <Typography style={{ margin: "0px auto" }}>
                <InfoNextToText />
                &nbsp; No invoices have been generated yet.
              </Typography>
            </Paper>
          );
        }

        const { id, lineItems, vatPercentage, total, includeVat } = data.helensTeasInvoice;

        return (
          <Paper style={{ padding: "30px" }}>
            <Typography style={{ marginBottom: "15px" }} variant="h5">
              H.T. Data
            </Typography>
            <Typography variant="body1" style={{ maxWidth: "850px", margin: "0 auto 30px auto" }}>
              Below you can see the most up to date invoice data for the nursery this month. Once
              you are certain it has everything in it, including any adjustments you may wish to
              make, you can connect to Xero and push this invoice into Xero. Below you can see the
              invoice that Xero currently has.
            </Typography>
            <div
              style={{
                textAlign: "center",
                maxWidth: "850px",
                margin: "0 auto 30px auto",
              }}
            >
              <Typography variant="button" style={{ marginRight: "5px" }}>
                NB: If you delete the invoice in Xero press this button!
              </Typography>
              <NullifyButton mutation={NULLIFY_HT_XERO_INVOICE} id={id} />
            </div>
            <Table size="small" style={{ maxWidth: "850px", margin: "0 auto" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Unit Cost</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lineItems.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell style={item.deletable ? { paddingLeft: "0px" } : {}}>
                        {item.deletable && (
                          <GraphqlMutation mutation={DELETE_HT_LINE_ITEM} withError>
                            {(removeLineItem, { loading }) => {
                              const onDelete = async () => {
                                const confirm = window.confirm("Remove Line Item?");
                                if (confirm) {
                                  const variables = { id: item.id };
                                  await removeLineItem({
                                    variables,
                                    refetchQueries: [{ query: HT_INVOICE, variables: params }],
                                  });
                                }
                              };
                              return (
                                <IconButton
                                  size="small"
                                  style={{ paddingLeft: "16px", paddingRight: "16px" }}
                                  onClick={onDelete}
                                  disabled={loading}
                                >
                                  <DeleteIcon style={{ height: "16px" }} />
                                </IconButton>
                              );
                            }}
                          </GraphqlMutation>
                        )}
                        {item.description}
                      </TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>£{item.unitCost}</TableCell>
                      <TableCell>£{item.total}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Flexin>
              <Button
                style={{ marginTop: "10px", width: "214px" }}
                variant="outlined"
                color="primary"
                onClick={() => setOpenModal(true)}
              >
                Add Adjustment +
              </Button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  width: "100%",
                  maxWidth: "850px",
                  margin: "0 auto",
                }}
              >
                <GraphqlMutation mutation={UPSERT_HT_INVOICE} withError>
                  {(upsert, { loading }) => {
                    const onChange = async () => {
                      const lineItemsVars = lineItems.map(
                        ({ id, description, quantity, unitCost }) => ({
                          id,
                          description,
                          quantity,
                          unitCost,
                        }),
                      );

                      const variables = {
                        input: {
                          id,
                          includeVat: !includeVat,
                          lineItems: lineItemsVars,
                          ...params,
                        },
                      };
                      await upsert({ variables });
                    };

                    return (
                      <FormControlLabel
                        style={{
                          fontSize: "14px",
                          marginTop: "4px",
                          marginBottom: "4px",
                        }}
                        classes={{ root: formLabel }}
                        label={`Include VAT ? (${vatPercentage * 100}%)`}
                        control={
                          <Checkbox
                            disabled={loading}
                            size="small"
                            variant="outlined"
                            checked={includeVat}
                            onChange={onChange}
                          />
                        }
                      />
                    );
                  }}
                </GraphqlMutation>
                <Table style={{ width: "auto" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ paddingTop: "0px" }} align="right">
                        <Typography variant="button">Total:</Typography>
                      </TableCell>
                      <TableCell style={{ paddingTop: "0px" }} align="left">
                        <Typography variant="h5">£{total}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Flexin>
            <XeroButton isUserLoggedInToXero={isUserLoggedInToXero} />
            <GraphqlMutation mutation={PUSH_HT_TO_XERO} withError withLoading>
              {(push, { loading }) => {
                const onClick = async () => {
                  const result = await push({ variables: { invoiceId: id } });
                  if (result.data) {
                    setPublishedPopup(true);
                  }
                };

                return (
                  <Button
                    style={{ marginBottom: "15px" }}
                    disbaled={!isUserLoggedInToXero || loading}
                    color="secondary"
                    variant="outlined"
                    onClick={onClick}
                  >
                    Push Invoice To Xero
                  </Button>
                );
              }}
            </GraphqlMutation>
            {publishedPopup && (
              <Alerts.Popup
                onClose={() => setPublishedPopup(false)}
                severity="success"
                title="Successfully saved to Xero."
                doAlert={publishedPopup}
                autoHideDuration={3000}
                message="Invoice has been pushed. Head to Xero to double check everything looks right."
              />
            )}
            {openModal && (
              <AddAdjustmentModal
                open={openModal}
                mutation={UPSERT_HT_INVOICE}
                invoice={data.helensTeasInvoice}
                onSubmit={() => setOpenModal(false)}
                onCancel={() => setOpenModal(false)}
                refetchQueries={[]}
                {...params}
              />
            )}
          </Paper>
        );
      }}
    </GraphqlQuery>
  );
};

const HungryMonstersInvoice = (props) => {
  const { formLabel } = useStyles();
  const [publishedPopup, setPublishedPopup] = React.useState(false);

  const { params, setOpenModal, openModal, isUserLoggedInToXero } = props;
  return (
    <GraphqlQuery query={HM_INVOICE} variables={params} withError>
      {({ data, error }) => {
        if (error) return <CentredErrorIcon />;

        if (!data.hungryMonstersInvoice) {
          return (
            <Paper style={{ padding: "30px" }}>
              <Typography style={{ margin: "0px auto" }}>
                <InfoNextToText />
                &nbsp; No invoices have been generated yet.
              </Typography>
            </Paper>
          );
        }

        const { id, lineItems, vatPercentage, total, includeVat } = data.hungryMonstersInvoice;

        return (
          <Paper style={{ padding: "30px" }}>
            <Typography style={{ marginBottom: "15px" }} variant="h5">
              H.M. Data
            </Typography>
            <Typography variant="body1" style={{ maxWidth: "850px", margin: "0 auto 15px auto" }}>
              Below you can see the most up to date invoice data for the nursery this month. Once
              you are certain it has everything in it, including any adjustments you may wish to
              make, you can connect to Xero and push this invoice into Xero. Below you can see the
              invoice that Xero currently has.
            </Typography>
            <div
              style={{
                textAlign: "center",
                maxWidth: "850px",
                margin: "0 auto 30px auto",
              }}
            >
              <Typography variant="button" style={{ marginRight: "5px" }}>
                NB: If you delete the invoice in Xero press this button!
              </Typography>
              <NullifyButton mutation={NULLIFY_HM_XERO_INVOICE} id={id} />
            </div>
            <Table size="small" style={{ maxWidth: "850px", margin: "0 auto" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Unit Cost</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lineItems.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell style={item.deletable ? { paddingLeft: "0px" } : {}}>
                        {item.deletable && (
                          <GraphqlMutation mutation={DELETE_LINE_ITEM} withError>
                            {(removeLineItem, { loading }) => {
                              const onDelete = async () => {
                                const confirm = window.confirm("Remove Line Item?");
                                if (confirm) {
                                  const variables = { id: item.id };
                                  await removeLineItem({
                                    variables,
                                    refetchQueries: [{ query: HM_INVOICE, variables: params }],
                                  });
                                }
                              };
                              return (
                                <IconButton
                                  size="small"
                                  style={{ paddingLeft: "16px", paddingRight: "16px" }}
                                  onClick={onDelete}
                                  disabled={loading}
                                >
                                  <DeleteIcon style={{ height: "16px" }} />
                                </IconButton>
                              );
                            }}
                          </GraphqlMutation>
                        )}
                        {item.description}
                      </TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>£{item.unitCost}</TableCell>
                      <TableCell>£{item.total}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Flexin>
              <Button
                style={{ marginTop: "10px", width: "214px" }}
                variant="outlined"
                color="primary"
                onClick={() => setOpenModal(true)}
              >
                Add Adjustment +
              </Button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  width: "100%",
                  maxWidth: "850px",
                  margin: "0 auto",
                }}
              >
                <GraphqlMutation mutation={UPSERT_INVOICE} withError>
                  {(upsert, { loading }) => {
                    const onChange = async () => {
                      const lineItemsVars = lineItems.map(
                        ({ id, description, quantity, unitCost }) => ({
                          id,
                          description,
                          quantity,
                          unitCost,
                        }),
                      );

                      const variables = {
                        input: {
                          id,
                          includeVat: !includeVat,
                          lineItems: lineItemsVars,
                          ...params,
                        },
                      };
                      await upsert({ variables });
                    };

                    return (
                      <FormControlLabel
                        style={{
                          fontSize: "14px",
                          marginTop: "4px",
                          marginBottom: "4px",
                        }}
                        classes={{ root: formLabel }}
                        label={`Include VAT ? (${vatPercentage * 100}%)`}
                        control={
                          <Checkbox
                            disabled={loading}
                            size="small"
                            variant="outlined"
                            checked={includeVat}
                            onChange={onChange}
                          />
                        }
                      />
                    );
                  }}
                </GraphqlMutation>
                <Table style={{ width: "auto" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ paddingTop: "0px" }} align="right">
                        <Typography variant="button">Total:</Typography>
                      </TableCell>
                      <TableCell style={{ paddingTop: "0px" }} align="left">
                        <Typography variant="h5">£{total}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Flexin>
            <XeroButton isUserLoggedInToXero={isUserLoggedInToXero} />
            <GraphqlMutation mutation={PUSH_HM_TO_XERO} withError withLoading>
              {(push, { loading }) => {
                const onClick = async () => {
                  const result = await push({ variables: { invoiceId: id } });
                  if (result.data) {
                    setPublishedPopup(true);
                  }
                };

                return (
                  <Button
                    style={{ marginBottom: "15px" }}
                    disbaled={!isUserLoggedInToXero || loading}
                    color="secondary"
                    variant="outlined"
                    onClick={onClick}
                  >
                    Push Invoice To Xero
                  </Button>
                );
              }}
            </GraphqlMutation>
            {publishedPopup && (
              <Alerts.Popup
                onClose={() => setPublishedPopup(false)}
                severity="success"
                title="Successfully saved to Xero."
                doAlert={publishedPopup}
                autoHideDuration={3000}
                message="Invoice has been pushed. Head to Xero to double check everything looks right."
              />
            )}
            {openModal && (
              <AddAdjustmentModal
                open={openModal}
                mutation={UPSERT_INVOICE}
                invoice={data.hungryMonstersInvoice}
                onSubmit={() => setOpenModal(false)}
                onCancel={() => setOpenModal(false)}
                refetchQueries={[]}
                {...params}
              />
            )}
          </Paper>
        );
      }}
    </GraphqlQuery>
  );
};

export const NurseryInvoice = () => {
  const params = useParams();
  const [openModal, setOpenModal] = React.useState(false);
  const [openHtModal, setHtOpenModal] = React.useState(false);

  return (
    <GraphqlQuery query={NURSERY} variables={{ id: params.nurseryId }} withError withLoading>
      {({ data, error }) => {
        if (error) {
          return <CentredErrorIcon />;
        }
        const { nursery } = data;

        return (
          <GraphqlQuery fetchPolicy="no-cache" query={IS_LOGGED_IN} withError withLoading>
            {({ data, error }) => {
              if (error) {
                return <CentredErrorIcon />;
              }
              const { isUserLoggedInToXero } = data;

              return (
                <PageHeading maxWidth="lg" heading={`Invoice - ${nursery.name}`}>
                  <DatePickerSection
                    type="monthly"
                    disableArrowKeys={openModal || openHtModal}
                    maxDate={dayjs().add(6, "month").startOf("month")}
                  />
                  <OrderTabs
                    disableKeyboardShortcuts={true}
                    tabs={[
                      { label: "Hungry Monsters", disabled: false },
                      { label: "Helen's Teas", disabled: false },
                    ]}
                  >
                    <HungryMonstersInvoice
                      params={{ date: params.date, nurseryId: params.nurseryId }}
                      setOpenModal={setHtOpenModal}
                      openModal={openHtModal}
                      isUserLoggedInToXero={isUserLoggedInToXero}
                    />
                    <HelensTeasInvoice
                      params={{ date: params.date, nurseryId: params.nurseryId }}
                      setOpenModal={setOpenModal}
                      openModal={openModal}
                      isUserLoggedInToXero={isUserLoggedInToXero}
                    />
                  </OrderTabs>
                </PageHeading>
              );
            }}
          </GraphqlQuery>
        );
      }}
    </GraphqlQuery>
  );
};
