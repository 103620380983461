import { gql } from "@apollo/client";
import React from "react";
import { H3 } from "components/typography/H3";
import { UserContext } from "contexts";
import makeStyles from "@mui/styles/makeStyles";
import { TextInput } from "components/inputs/Inputs";
import {
  Typography,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Button,
  IconButton,
} from "@mui/material";
import { Checkbox } from "components/inputs/Inputs";
import { AddNurseryContactModal } from "containers/nurserySettings/AddNurseryContactModal";
import AddIcon from "@mui/icons-material/Add";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const useStyles = makeStyles({
  marginBottom: { marginBottom: "15px" },
  marginBottomLg: { marginBottom: "30px" },
  reminderBtn: { margin: "0 auto 15px auto", display: "inline-block" },
  mailIcon: { position: "relative", top: "6px", marginRight: "5px" },
  deleteIcon: { position: "relative", bottom: "2px" },
});

const RE_SEND_VERIFICATION_EMAIL = gql`
  mutation SendVerificationEmail($nurseryContactId: ID!) {
    sendVerificationEmail(nurseryContactId: $nurseryContactId)
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetPassword($userId: ID) {
    resetPassword(userId: $userId)
  }
`;

const DELETE_USER = gql`
  mutation DeleteNurseryContact($id: ID!) {
    deleteNurseryContact(id: $id) {
      id
    }
  }
`;

const UPDATE_NAME = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      name
    }
  }
`;

const TOGGLE_REMINDER = gql`
  mutation updateNurseryContact($input: UpdateNurseryContactInput!) {
    updateNurseryContact(input: $input) {
      id
      shouldReceiveReminders
    }
  }
`;

const ContactCard = (props) => {
  const { refetchQueries, loggedInUser } = props;
  const { id, userId, name, email, shouldReceiveReminders, agreedToReceiveReminders } =
    props.contact;
  const { mailIcon, reminderBtn, deleteIcon } = useStyles();
  const [verificationSent, setVerificationSent] = React.useState(false);
  const [passwordReset, setPasswordReset] = React.useState(false);
  const [userName, updateUserName] = React.useState(null);

  React.useEffect(() => {
    updateUserName(name);
  }, [name, updateUserName]);

  const cannotUpdateName =
    // Basically can only update your own name
    (loggedInUser.role === "nursery" && loggedInUser.id !== userId) ||
    (loggedInUser.role === "delivery_driver" && loggedInUser.id !== userId);

  return (
    <Grid item>
      <Card>
        <CardContent>
          <Grid container justifyContent="space-between" alignItems="center">
            <GraphqlMutation mutation={UPDATE_NAME} withError>
              {(updateName) => {
                const onBlur = async (event) => {
                  const input = { id: userId, name: event.target.value };
                  const result = await updateName({ variables: { input }, refetchQueries });
                  // Reset the form if there is an error.
                  if (result.errors) {
                    updateUserName(name);
                  }
                };
                return (
                  <TextInput
                    disabled={cannotUpdateName}
                    onChange={(event) => updateUserName(event.target.value)}
                    onBlur={onBlur}
                    // It should basically only be null when we first load but then as soon
                    // as we change it it userName takes over. Not actually sure if this is
                    // okay to do, you are probably mean to use useEffect or some shit.
                    value={userName}
                  />
                );
              }}
            </GraphqlMutation>

            <GraphqlMutation mutation={DELETE_USER} withError>
              {(deleteUser) => {
                const onClick = async () => {
                  const variables = { id };
                  await deleteUser({ variables, refetchQueries });
                };
                return (
                  <IconButton className={deleteIcon} onClick={onClick} size="large">
                    <DeleteOutlinedIcon />
                  </IconButton>
                );
              }}
            </GraphqlMutation>
          </Grid>
        </CardContent>
        <CardContent>
          <Typography>
            <MailOutlineIcon className={mailIcon} />
            {email}
          </Typography>
          <FormControlLabel
            control={
              <GraphqlMutation mutation={TOGGLE_REMINDER} withError>
                {(toggleNotifications) => {
                  const variables = {
                    input: { id, shouldReceiveReminders: !shouldReceiveReminders },
                  };
                  const onChange = async () => {
                    await toggleNotifications({ variables, refetchQueries });
                  };
                  return <Checkbox checked={shouldReceiveReminders} onChange={onChange} />;
                }}
              </GraphqlMutation>
            }
            label="Receive order reminders?"
          />
        </CardContent>
        <Grid container align="center">
          {/* We may want to think about preventing smashing this button repeatedly somehow... */}
          <GraphqlMutation mutation={RESET_PASSWORD} withError>
            {(resetPassword) => {
              const onClick = async () => {
                const variables = { userId };
                const result = await resetPassword({ variables, refetchQueries });
                if (result.data) {
                  setPasswordReset(true);
                }
              };

              return (
                <Button
                  disabled={passwordReset}
                  variant="outlined"
                  className={reminderBtn}
                  onClick={onClick}
                >
                  Reset password
                </Button>
              );
            }}
          </GraphqlMutation>
        </Grid>
        {!agreedToReceiveReminders && (
          <Grid container align="center">
            {/* We may want to think about preventing smashing this button repeatedly somehow... */}
            <GraphqlMutation mutation={RE_SEND_VERIFICATION_EMAIL} withError>
              {(resendVerification) => {
                const onClick = async () => {
                  const variables = { nurseryContactId: id };
                  const result = await resendVerification({ variables, refetchQueries });
                  if (result.data) {
                    setVerificationSent(true);
                  }
                };

                return (
                  <Button
                    disabled={verificationSent}
                    variant="outlined"
                    className={reminderBtn}
                    onClick={onClick}
                  >
                    Re-send verification email
                  </Button>
                );
              }}
            </GraphqlMutation>
          </Grid>
        )}
      </Card>
    </Grid>
  );
};

export const NurseryContacts = (props) => {
  const { nurseryContacts, nurseryId, refetchQueries } = props;
  const { marginBottom, marginBottomLg } = useStyles();
  const [addContactOpen, setAddContactOpen] = React.useState(false);

  const openAddContactModal = () => setAddContactOpen(true);
  const closeAddContactModal = () => setAddContactOpen(false);
  const canAddMore = nurseryContacts.length < 4;
  const user = React.useContext(UserContext);

  return (
    <React.Fragment>
      <H3 className={marginBottom}>Nursery Contacts</H3>
      <Typography className={marginBottom} variant="body1">
        Contacts here will be notified when orders are due and when we auto-submit orders. You
        must have at least one user who can receive notifications. New contacts must verify their
        email address by clicking the link that will be sent to them before they can receive
        reminders.
      </Typography>
      <Grid className={marginBottom} container spacing={3}>
        {nurseryContacts.map((contact) => {
          return (
            <ContactCard
              loggedInUser={user}
              refetchQueries={refetchQueries}
              contact={contact}
              key={contact.id}
            />
          );
        })}
      </Grid>
      {canAddMore && (
        <React.Fragment>
          <Button
            className={marginBottomLg}
            variant="outlined"
            color="primary"
            aria-label="add"
            onClick={openAddContactModal}
          >
            Add Contact&nbsp;&nbsp;
            <AddIcon />
          </Button>
          <AddNurseryContactModal
            open={addContactOpen}
            nurseryId={nurseryId}
            onCancel={closeAddContactModal}
            onSubmit={closeAddContactModal}
            refetchQueries={refetchQueries}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
