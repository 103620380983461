import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import { TextInput } from "components/inputs/Inputs";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { H3 } from "components/typography/H3";
import { Providers } from "containers/nurserySettings/Providers";
import { useTheme } from "@mui/material/styles";
import { ContractSelect } from "containers/nurserySettings/Contract";

import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  Typography,
  useMediaQuery,
} from "@mui/material";

const CREATE_NURSERY = gql`
  mutation CreateNursery($input: CreateNurseryInput!) {
    createNursery(input: $input) {
      id
      name
      lunchOrderMinimum
      teaOrderMinimum
      address
      phoneNumber
      currentLunchProvider
      currentTeaProvider
      defaultLunchCostPerMeal
      contractType
      nurseryContacts {
        id
        userId
        shouldReceiveReminders
        agreedToReceiveReminders
        name
        email
      }
    }
  }
`;

const ProviderWrapper = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 0 auto 30px auto;
  height: 800px;
`;

const useStyles = makeStyles({
  dialogContent: { display: "flex", flexDirection: "column" },
  lastInput: { width: "100%", maxWidth: "350px", marginBottom: "30px" },
  inputStyle: { width: "100%", maxWidth: "350px", marginBottom: "15px" },
  heading: { marginLeft: "30px", marginBottom: "15px" },
});

const Inputs = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const AddNurseryModal = (props) => {
  const { open, onCancel, refetchQueries, onSubmit } = props;
  const { inputStyle, dialogContent, lastInput, heading } = useStyles();

  const [name, updateName] = React.useState();
  const [address, updateAddress] = React.useState();
  const [phoneNumber, setPhoneNumber] = React.useState();
  const [contactName, updateContactName] = React.useState();
  const [email, updateEmail] = React.useState();
  const [contractType, setContractType] = React.useState();
  const [currentTeaProvider, selectTeaProvider] = React.useState();
  const [currentLunchProvider, selectLunchProvider] = React.useState();
  const [providerDetails, setProviderDetails] = React.useState({});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const onProviderDetailsChange = (field, value) => {
    setProviderDetails({ ...providerDetails, [field]: value });
  };

  const nursery = {
    name,
    address,
    phoneNumber,
    nurseryContact: { name: contactName, email },
    currentLunchProvider,
    currentTeaProvider,
    ...providerDetails,
    contractType,
  };

  const resetForm = () => {
    updateName();
    updateAddress();
    setPhoneNumber();
    updateContactName();
    updateEmail();
    selectTeaProvider();
    selectLunchProvider();
    setProviderDetails();
    setContractType();
  };
  const missingRequiredFields =
    !email || !name || !contactName || !address || !contractType || !phoneNumber || !contactName;

  // Just a very simple email validator.
  const emailError = Boolean(email) && !/^\S+@\S+\.\S+$/.test(email);
  const emailHelper = emailError ? "Invalid email" : "";
  return (
    <Dialog scroll="paper" disableEscapeKeyDown fullScreen={fullScreen} fullWidth open={open}>
      <DialogTitle>
        <Typography variant="h4" component="h4">
          Add Nursery
        </Typography>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <H3 className={heading}>Nursery</H3>
        <Inputs>
          <TextInput
            required
            variant="outlined"
            className={inputStyle}
            label="Name..."
            value={name}
            onChange={(event) => updateName(event.target.value)}
          />
          <TextInput
            required
            multiline
            variant="outlined"
            label="Address..."
            className={inputStyle}
            value={address}
            onChange={(event) => updateAddress(event.target.value)}
          />
          <TextInput
            required
            variant="outlined"
            label="Phone Number..."
            className={lastInput}
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
          />
        </Inputs>
        <H3 className={heading}>Primary Contact</H3>
        <Inputs>
          <TextInput
            required
            variant="outlined"
            className={inputStyle}
            label="Name..."
            value={contactName}
            onChange={(event) => updateContactName(event.target.value)}
          />
          <TextInput
            required
            variant="outlined"
            label="Enter a valid email..."
            className={lastInput}
            value={email}
            helperText={emailHelper}
            error={emailError}
            onChange={(event) => updateEmail(event.target.value)}
          />
        </Inputs>
        <ProviderWrapper>
          <Providers
            nursery={nursery}
            variant="outlined"
            selectTeaProvider={(event) => {
              const value = event.target.value === "" ? null : event.target.value;
              selectTeaProvider(value);
            }}
            selectLunchProvider={(event) => {
              const value = event.target.value === "" ? null : event.target.value;
              selectLunchProvider(value);
            }}
            onTeaProviderDetailsChange={onProviderDetailsChange}
            onLunchProviderDetailsChange={onProviderDetailsChange}
          />
        </ProviderWrapper>
        <Inputs>
          <ContractSelect
            nullable
            className={inputStyle}
            contractType={contractType}
            onChange={(value) => setContractType(value)}
          />
        </Inputs>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            onCancel();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <GraphqlMutation mutation={CREATE_NURSERY} withError>
          {(createNursery) => {
            const submit = async () => {
              if (emailError) return;

              const variables = { input: { ...nursery } };
              const result = await createNursery({ variables, refetchQueries });
              if (result.data) {
                resetForm();
                onSubmit();
              }
              return result;
            };

            return (
              <Button
                disabled={emailError || missingRequiredFields}
                onClick={submit}
                color="primary"
              >
                Create
              </Button>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </Dialog>
  );
};
