import React from "react";
import dayjs from "dayjs_with_plugins";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { EditSubMenuModal } from "containers/menus/EditSubMenu";
import { Dialog, useMediaQuery } from "@mui/material";
import { CURRENT_MENU } from "containers/menus/queries";

const useStyles = makeStyles({
  dialog: { padding: "30px 0px 30px 0px" },
});

export const ResolveUnresolvedModal = (props) => {
  const { date, open } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { dialog } = useStyles();

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      maxWidth="lg"
      open={open}
      PaperProps={{ className: dialog }}
    >
      <GraphqlQuery
        fetchPolicy="network-only"
        query={CURRENT_MENU}
        variables={{ date }}
        withError
      >
        {({ data, error }) => {
          if (error) {
            return <CentredErrorIcon />;
          }

          const { currentSubMenu: subMenu } = data;
          return (
            <EditSubMenuModal
              subMenu={subMenu}
              {...props}
              date={dayjs().utc(date, "YYYY-MM-DD", true)}
              chooseDate={false}
            />
          );
        }}
      </GraphqlQuery>
    </Dialog>
  );
};
