import { gql } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { PageHeading } from "components/PageHeading";
import dayjs from "dayjs_with_plugins";
import { DatePickerSection } from "components/DatePickerSection";
import { Typography, TableBody, TableRow, TableCell, Table, TableHead } from "@mui/material";
import { CentredErrorIcon } from "components/CentredErrorIcon";

const LUNCH_ITEMS = gql`
  query UniqueLunchDeliveryComponents($date: Date!) {
    uniqueLunchDeliveryComponents(date: $date) {
      id
      name
      temperature(date: $date)
      packedAt(date: $date)
      packedBy(date: $date)
    }
  }
`;

const TEA_ITEMS = gql`
  query UniqueTeaDeliveryComponents($date: Date!) {
    uniqueTeaDeliveryComponents(date: $date) {
      id
      name
      temperature(date: $date)
      packedAt(date: $date)
      packedBy(date: $date)
    }
  }
`;

const Row = (props) => {
  const { mealComponent } = props;
  const { name, id, packedBy, packedAt, temperature } = mealComponent;

  return (
    <TableRow key={id}>
      <TableCell>{name}</TableCell>
      <TableCell>{packedAt ? dayjs(packedAt).format("LLL") : "-"}</TableCell>
      <TableCell>{packedBy}</TableCell>
      <TableCell>{temperature}</TableCell>
    </TableRow>
  );
};

export const ReadOnlyTemperaturesPage = () => {
  const { date } = useParams();
  return (
    <PageHeading heading="Temperatures">
      {/* Random earliest date but not wrong. No significance, just better to pick something. */}
      <DatePickerSection type="daily" earliestDate="2020-03-23" disableArrowKeys={false} />
      <Typography style={{ marginBottom: "30px" }}>
        Here you can record the temperatures for all food items being delivered today. Each food
        item should have a temperature and you should mark who is recording the temperatures and
        at what time the food items were packed. Past temperatures are not editable.
      </Typography>
      <GraphqlQuery query={LUNCH_ITEMS} variables={{ date }} withLoading withError>
        {({ data: lunchData, error }) => {
          if (error) return <CentredErrorIcon />;

          return (
            <GraphqlQuery query={TEA_ITEMS} variables={{ date }} withLoading withError>
              {({ data: teaData, error }) => {
                if (error) return <CentredErrorIcon />;

                return (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Meal Component</TableCell>
                        <TableCell>Packed At</TableCell>
                        <TableCell>Packed By</TableCell>
                        <TableCell>Temp.</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lunchData.uniqueLunchDeliveryComponents.map((mealComponent) => {
                        return <Row key={mealComponent.id} mealComponent={mealComponent} />;
                      })}
                      <TableRow>
                        <TableCell>
                          <Typography
                            style={{ padding: "10px 0px", display: "inline-block" }}
                            variant="button"
                          >
                            Tea Components
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      {teaData.uniqueTeaDeliveryComponents.map((mealComponent) => {
                        return <Row key={mealComponent.id} mealComponent={mealComponent} />;
                      })}
                    </TableBody>
                  </Table>
                );
              }}
            </GraphqlQuery>
          );
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};
