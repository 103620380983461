import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import { PageHeading } from "components/PageHeading";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { DragAndDropTable } from "components/DragAndDropTable";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { AddSensitivityModal } from "containers/sensitivities/AddSensitivityModal";
import { EditSensitivityModal } from "containers/sensitivities/EditSensitivityModal";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import AddIcon from "@mui/icons-material/Add";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { MEAL_COMPONENTS } from "pages/admin/MealComponents";
import { ACTIVE_MEAL_COMPONENTS } from "containers/menus/queries";
import { ACTIVE_SENSITIVITIES } from "containers/orders/EditChildModal";
import { ALL_ACTIVE_SENSITIVITIES } from "pages/admin/MealComponents";
import {
  Table,
  TableContainer,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  Link,
  Chip,
  TableHead,
  Button,
} from "@mui/material";
import { isEqual } from "lodash";

export const SENSITIVITIES = gql`
  query Sensitivities {
    sensitivities {
      id
      name
      order
      active
      readOnly
      mealComponentInformation(present: true) {
        id
        mealName
        present
        sensitivityId
        mealComponentId
      }
    }
  }
`;

const REORDER = gql`
  mutation OrderSensitivities($input: OrderInput!) {
    orderSensitivities(input: $input) {
      id
      name
      order
      active
      readOnly
      mealComponentInformation(present: true) {
        id
        mealName
        present
        sensitivityId
        mealComponentId
      }
    }
  }
`;

export const ALL_ACTIVE_MEAL_COMPONENTS = gql`
  query MealComponents($active: Boolean) {
    mealComponents(active: $active) {
      id
      name
      active
      sensitivityInformation {
        id
        sensitivityName
        present
        sensitivityId
        mealComponentId
      }
    }
  }
`;

const TableWrapper = styled.div`
  background-color: white;
  margin-bottom: 30px;
  max-height: 66vh;
`;

const useStyles = makeStyles({
  icon: { height: "20px", position: "relative" },
  info: { marginBottom: "15px" },
  moveIcon: { height: "15px", position: "relative", top: "4px", cursor: "move" },
});

const TheRow = (props) => {
  const { dragRef, sensitivity, openEditModal, classes, opacity } = props;

  return (
    <TableRow ref={dragRef}>
      <TableCell style={{ cursor: "move", opacity, width: "56px" }}>
        <OpenWithIcon className={classes.moveIcon} />
      </TableCell>
      <TableCell>
        <Chip
          clickable={false}
          size="small"
          variant="outlined"
          label={sensitivity.active ? "active" : "inactive"}
          color={sensitivity.active ? "primary" : "secondary"}
        />
      </TableCell>
      <TableCell>
        <Chip
          clickable={false}
          size="small"
          variant="outlined"
          label={sensitivity.readOnly ? "read only" : "selectable"}
          color={sensitivity.readOnly ? "secondary" : "primary"}
        />
      </TableCell>
      <TableCell style={{ width: "140px" }}>
        <Link
          style={{ textDecoration: "underline" }}
          href="#"
          onClick={(event) => {
            event.preventDefault();
            openEditModal(sensitivity);
          }}
        >
          {sensitivity.name}
        </Link>
      </TableCell>
      <TableCell>
        {/* Should probably filter on the BE like we do for MCs */}
        {sensitivity.mealComponentInformation
          .filter(({ present }) => present)
          .map(({ mealName, id }) => {
            return (
              <Chip
                clickable={false}
                key={id}
                size="small"
                variant="outlined"
                label={mealName}
                color="secondary"
              />
            );
          })}
      </TableCell>
    </TableRow>
  );
};
/*dragRef, sensitivity, openEditModal, classes, opacity*/

const OptimizedRow = React.memo(TheRow, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.sensitivity, nextProps.sensitivity) &&
    prevProps.dragRef === nextProps.dragRef
  );
});

export const Sensitivities = () => {
  const classes = useStyles();
  const [open, setCreateModal] = React.useState(false);
  const [editModalOpen, setEditModal] = React.useState(false);
  const [editingSensitivity, setSenToEdit] = React.useState("");

  const openEditModal = (sensitivity) => {
    setSenToEdit(sensitivity);
    setEditModal(true);
  };

  const openCreateModal = () => setCreateModal(true);
  const closeCreateModal = () => setCreateModal(false);
  const closeEditModal = () => setEditModal(false);

  const refetchQueries = [
    { query: SENSITIVITIES, variables: {} },
    { query: MEAL_COMPONENTS, variables: {} },
    { query: ACTIVE_MEAL_COMPONENTS, variables: { active: true } },
    { query: ACTIVE_SENSITIVITIES, variables: { active: true } },
    { query: ALL_ACTIVE_SENSITIVITIES, variables: { active: true } },
    { query: ALL_ACTIVE_MEAL_COMPONENTS, variables: { active: true } },
  ];

  return (
    <PageHeading maxWidth="lg" heading="Sensitivities">
      <Typography variant="body1" className={classes.info}>
        Drag and drop sensitivities in the table below to reorder them. This will set the order in
        which they appear to the nurseries.
      </Typography>
      <Typography variant="body1" className={classes.info}>
        WARNING - Inactive sensitivities will not be checked for when generating diet advice, and
        they will not be available for nurseries to select as an allergen for children when they
        make an order. Marking a sensitivity inactive is a way of deleting it without losing the
        information that it was used on a past order.
      </Typography>
      <Typography variant="body1" className={classes.info}>
        Read only sensitivities are checked for when generating diet advice, but they are not
        available for a nursery to select when they are creating an order. Instead an admin may
        apply the sensitivity to a specific child. The nursery will see it has been applied but
        they will not be able to remove it or add the sensitivity to any other children. This
        allows one off sensitivities to be added without having to offer them to everyone as an
        option.
      </Typography>
      <GraphqlQuery query={SENSITIVITIES} variables={{}} withLoading withError>
        {({ data, error }) => {
          if (error) {
            return <CentredErrorIcon />;
          }

          return (
            <TableContainer component={TableWrapper}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell style={{ width: "56px" }}>Active?</TableCell>
                    <TableCell style={{ width: "75px" }}>Read Only?</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Is in</TableCell>
                  </TableRow>
                </TableHead>
                <GraphqlMutation mutation={REORDER}>
                  {(reorder) => {
                    const saveOrder = async (sensitivities) => {
                      const newSens = sensitivities.map(({ id, name, order }) => ({
                        id,
                        name,
                        order,
                      }));
                      await reorder({
                        variables: { input: { sensitivities: newSens } },
                        update: (store, { data: { orderSensitivities } }) => {
                          // Manually writing to the store as nothing else seemed to work!
                          // This is instead of a refetch, more efficient too
                          store.writeQuery({
                            query: SENSITIVITIES,
                            variables: {},
                            data: { sensitivities: orderSensitivities },
                          });
                        },
                      });
                    };

                    return (
                      <DragAndDropTable
                        dropComponent={TableBody}
                        data={data.sensitivities}
                        saveOrder={saveOrder}
                        cardType="sensitivity"
                      >
                        {({ datum: sensitivity, isDragging, dragRef }) => {
                          const opacity = isDragging ? 0 : 1;

                          return (
                            <OptimizedRow
                              openEditModal={openEditModal}
                              sensitivity={sensitivity}
                              opacity={opacity}
                              dragRef={dragRef}
                              classes={classes}
                            />
                          );
                        }}
                      </DragAndDropTable>
                    );
                  }}
                </GraphqlMutation>
              </Table>
            </TableContainer>
          );
        }}
      </GraphqlQuery>
      <Button variant="outlined" color="primary" aria-label="add" onClick={openCreateModal}>
        Add Sensitivity&nbsp;&nbsp;
        <AddIcon />
      </Button>
      <GraphqlQuery
        query={ALL_ACTIVE_MEAL_COMPONENTS}
        variables={{ active: true }}
        withLoading={false}
        withError
      >
        {({ data }) => {
          return (
            <React.Fragment>
              {open && (
                <AddSensitivityModal
                  open={open}
                  onCancel={closeCreateModal}
                  onSubmit={closeCreateModal}
                  refetchQueries={refetchQueries}
                  mealComponents={data.mealComponents}
                />
              )}
              {editModalOpen && (
                <EditSensitivityModal
                  id={editingSensitivity.id}
                  open={editModalOpen}
                  onCancel={closeEditModal}
                  onSubmit={closeEditModal}
                  refetchQueries={refetchQueries}
                  mealComponents={data?.mealComponents}
                />
              )}
            </React.Fragment>
          );
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};
