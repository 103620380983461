import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export function RequireAuth({ children }) {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    } else {
      const fn = async () => {
        await loginWithRedirect({
          appState: { targetUrl: window.location.pathname },
        });
      };
      fn();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return isAuthenticated === true ? children : null;
}
