import React from "react";
import { PageHeading } from "components/PageHeading";
import styled from "styled-components";
import { gql } from "@apollo/client";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { Loading } from "components/Loading";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ReactMarkdown from "react-markdown";
import { NewTabLink } from "components/NewTabLink";

const FAQS = gql`
  query Questions {
    questionCategories {
      id
      name
      order
      questions {
        id
        text
        order
        answer {
          id
          text
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  noBefore: { "&:before": { content: "none" } },
  overflow: { overflow: "auto" },
});

const QuestionDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Answer = (props) => {
  const { answer } = props;

  return (
    <div style={{ width: "100%" }}>
      {answer && (
        <div
          style={{
            width: "100%",
            borderRadius: "4px",
            borderTop: "1px solid rgba(0, 0, 0, 0.23)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "0px 10px",
            overflow: "auto",
          }}
        >
          <ReactMarkdown components={{ a: NewTabLink }}>{answer.text}</ReactMarkdown>
        </div>
      )}
    </div>
  );
};

export const Faqs = () => {
  const { noBefore, overflow } = useStyles();

  return (
    <PageHeading maxWidth="md" heading="FAQs">
      <GraphqlQuery query={FAQS} variables={{}}>
        {({ loading, data, error }) => {
          if (error) return <CentredErrorIcon />;
          if (loading) return <Loading />;

          const { questionCategories } = data;

          return questionCategories.map((cat) => {
            return (
              <Accordion
                key={cat.id}
                className={noBefore}
                style={{
                  backgroundColor: "#fafafa",
                  marginBottom: "15px",
                  boxShadow: "none",
                  border: "1px solid #757575",
                }}
              >
                <AccordionSummary expandIcon={<ExpandLessIcon />}>
                  <Typography variant="button">{cat.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuestionDiv>
                    {cat.questions.map((question) => {
                      return (
                        <Accordion
                          key={question.id}
                          style={{ marginBottom: "15px", width: "100%" }}
                        >
                          <AccordionSummary
                            classes={{ content: overflow }}
                            style={{ overflow: "auto" }}
                            expandIcon={<ExpandLessIcon />}
                          >
                            <Typography
                              style={{ width: "100%", overflow: "auto" }}
                              variant="button"
                            >
                              <b>Q:</b>&nbsp;{question.text}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Answer answer={question.answer} />
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </QuestionDiv>
                </AccordionDetails>
              </Accordion>
            );
          });
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};
