import React from "react";
import { Alerts } from "components/alerts/Alerts";

export const NotResolvedAlert = (props) => {
  const { childCount, handleClose, open } = props;

  if (childCount === 0) {
    return (
      <Alerts.Popup
        onClose={handleClose}
        severity="success"
        title="No unresolved children"
        doAlert={open}
        message="The menu would not leave out any of the children who are eating this week."
      />
    );
  } else {
    return (
      <Alerts.Popup
        onClose={handleClose}
        severity="warning"
        title={`${childCount} unresolved children this week`}
        doAlert={open}
        message="The menu would leave out some of the children who are eating this week. Please check each day to see which ones are affected."
      />
    );
  }
};
