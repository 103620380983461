import React from "react";
import styled from "styled-components";
import { H1 } from "components/typography/H1";
import { NavBar } from "components/navigation/NavBar";
import makeStyles from "@mui/styles/makeStyles";
import { Container } from "@mui/material";

const useStyles = makeStyles({
  headingStyles: {
    marginBottom: "30px",
    marginTop: "30px",
    fontWeight: "400",
    fontSize: "36px",
  },
  printStyles: {
    fontSize: "24px",
    marginTop: "30px",
    fontWeight: "400",
    marginBottom: "30px",
  },
});

const Footer = styled.div`
  height: 50px;
`;

const PrintOnly = styled.div`
  display: none;
  @media print {
    display: block;
  }
`;

export const PageHeading = (props) => {
  const {
    heading,
    noHeadingOnPrint = false,
    children,
    maxWidth = "lg",
    headingStyle = {},
    footer = true,
    printLabel = "",
  } = props;
  const { headingStyles, printStyles } = useStyles();
  return (
    <React.Fragment>
      <NavBar />
      <Container maxWidth={maxWidth}>
        {!noHeadingOnPrint && (
          <PrintOnly>
            <H1 className={printStyles} style={headingStyle}>
              {printLabel ? printLabel : heading}
            </H1>
          </PrintOnly>
        )}
        <H1 className={`${headingStyles} hide-for-print`} style={headingStyle}>
          {heading}
        </H1>
        {children}
      </Container>
      {footer && <Footer className="hide-for-print" />}
    </React.Fragment>
  );
};
