import { gql } from "@apollo/client";
import React from "react";
import dayjs from "dayjs_with_plugins";
import styled from "styled-components";
import { PageHeading } from "components/PageHeading";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { DatePickerSection } from "components/DatePickerSection";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { useParams } from "react-router-dom";
import { Loading } from "components/Loading";
import { sortBy, find } from "lodash";
import { InfoNextToText } from "components/InfoNextToText";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from "@mui/material";
import { pink } from "@mui/material/colors";

const PORTION_INFO = gql`
  query DeliveryForNursery($nurseryId: ID!, $date: Date!) {
    deliveryForNursery(nurseryId: $nurseryId, date: $date) {
      ... on NoDelivery {
        message
      }
      ... on Delivery {
        id
        lunchDelivery {
          id
          regularMealItems(date: $date) {
            id
            count
            supplied
            lunchDeliveryPortions {
              id
              count
              tinId
              tinName
              lunchDeliveryItemId
            }
            mealComponent {
              id
              name
              portions(date: $date) {
                id
                portionSize
                portionUnit
                tinId
                count
              }
            }
          }
        }
      }
    }
  }
`;

const COMPONENT_TOTALS_FOR_NURSERY = gql`
  query NurseryTotals($date: Date!, $nurseryId: ID!) {
    kitchenTotalsForDateAndNursery(date: $date, nurseryId: $nurseryId) {
      id
      name
      total
      tinCounts {
        id
        tinId
        numberOfTins
        tinCapacity
      }
      portions(date: $date) {
        id
        portionSize
        portionUnit
      }
    }
  }
`;

// We have to query for tins separately because there may be more tins than those that are included
// in the portions.
const TINS = gql`
  query Tins {
    tins {
      id
      name
    }
  }
`;

const LUNCH_ORDER = gql`
  query LunchOrder($nurseryId: ID!, $date: Date!) {
    lunchOrder(date: $date, nurseryId: $nurseryId) {
      ... on EditableLunchOrder {
        id
        totalMeals
      }
      ... on NonEditableLunchOrder {
        id
        totalMeals
      }
    }
  }
`;

const CHILDREN_EATING_SPECIAL_MEALS = gql`
  query ChildrenEatingSpecialMeals($nurseryId: ID!, $date: Date!) {
    notEatingAtLeastOneRegHotComponent(date: $date, nurseryId: $nurseryId)
  }
`;

const TableWrapper = styled.div`
  background-color: white;
  margin-bottom: 15px;
  max-height: 66vh;
`;

const PortionInformationTable = (props) => {
  const { tins, regularMealItems, date, nurseryId } = props;

  const suppliedToolTip =
    "If there are two or more hot components and a child " +
    "cannot eat at least on of them, we portion their whole meal separately. " +
    "This helps prevent cross contamination between components during transit." +
    "Substitutes for non hot components are not mentioned here - see the diet advice for that.";

  return (
    <TableContainer component={TableWrapper}>
      <Table stickyHeader size="small">
        <TableHead className="small-print-heading">
          <TableRow>
            <TableCell>Meal Component</TableCell>
            <TableCell align="center">Portion Size</TableCell>
            <TableCell align="center">Meals Ordered</TableCell>
            <Tooltip enterDelay={200} leaveDelay={0} title={suppliedToolTip}>
              <TableCell align="center" style={{ width: "150px" }}>
                Less Substitutes (supplied cold)
              </TableCell>
            </Tooltip>
            <TableCell align="center">Required Hot</TableCell>
            {tins.map(({ name, id }) => {
              return (
                <TableCell align="center" key={id}>
                  {name} Tin
                </TableCell>
              );
            })}
            <TableCell align="center">Supplied Hot</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <GraphqlQuery
            query={COMPONENT_TOTALS_FOR_NURSERY}
            withLoading={false}
            variables={{ date, nurseryId }}
          >
            {({ data, error, loading }) => {
              if (loading)
                return (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Loading />
                    </TableCell>
                  </TableRow>
                );
              if (error)
                return (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <CentredErrorIcon />
                    </TableCell>
                  </TableRow>
                );
              const { kitchenTotalsForDateAndNursery } = data;

              const numbers = kitchenTotalsForDateAndNursery.reduce((acc, comp) => {
                const totalPortions = comp.total;
                const tinnedPortions = comp.tinCounts.reduce((acc, tinCount) => {
                  if (tinCount) {
                    return acc + tinCount.numberOfTins * tinCount.tinCapacity;
                  } else {
                    return acc;
                  }
                }, 0);
                const specialPortions = totalPortions - tinnedPortions;

                const data = { [comp.id]: { ...acc, specialPortions, totalPortions } };
                return { ...acc, ...data };
              }, {});

              return kitchenTotalsForDateAndNursery.map((comp) => {
                const specialPortions = numbers[comp.id].specialPortions;
                const [firstPortion] = comp.portions;
                // Right. I'm not fully sure why, but it seems like we can have regularMealItems
                // that have not been portioned. On one level this makes sense - special meals
                // are sometimes portioned separately and so are not put into a tin. BUT I'm
                // not sure I am at the bottom of it because I'd expect to have seen this a lot
                // more. Also, does it mean we have 0, or are we meant to not show the row at all

                // regularMealItems.mealComponent.name == Creamy Tomato, Chicken & Broccoli Sauce
                // I guess the Q is why does this appear at all in the list of kitchenTotalsForDateAndNursery
                // comp.name == Creamy Tomato & Broccoli Sauce
                // Check date ~[2023-06-08] , Paintpots 1 for an example of what we are talking about...
                const lunchDelItem = find(
                  regularMealItems,
                  (i) => i.mealComponent.name === comp.name,
                );

                return (
                  <TableRow hover key={comp.id}>
                    <TableCell style={{ width: "180px" }}>{comp.name}</TableCell>
                    <TableCell align="center">
                      {firstPortion && firstPortion.portionSize && firstPortion.portionUnit
                        ? `${firstPortion.portionSize} ${firstPortion.portionUnit}`
                        : "-"}
                    </TableCell>
                    <GraphqlQuery
                      fetchPolicy="network-only"
                      query={LUNCH_ORDER}
                      withLoading={false}
                      variables={{ date, nurseryId }}
                    >
                      {({ data: lunch, error, loading }) => {
                        if (loading)
                          return (
                            <TableCell>
                              <Loading />
                            </TableCell>
                          );
                        if (error)
                          return (
                            <TableCell>
                              <CentredErrorIcon />
                            </TableCell>
                          );
                        return (
                          <TableCell align="center">{lunch.lunchOrder.totalMeals}</TableCell>
                        );
                      }}
                    </GraphqlQuery>
                    <GraphqlQuery
                      fetchPolicy="network-only"
                      query={CHILDREN_EATING_SPECIAL_MEALS}
                      withLoading={false}
                      variables={{ date, nurseryId }}
                    >
                      {({ data: d, error, loading }) => {
                        if (loading)
                          return (
                            <TableCell>
                              <Loading />
                            </TableCell>
                          );
                        if (error)
                          return (
                            <TableCell>
                              <CentredErrorIcon />
                            </TableCell>
                          );

                        return (
                          <TableCell align="center">
                            {d.notEatingAtLeastOneRegHotComponent || 0}
                          </TableCell>
                        );
                      }}
                    </GraphqlQuery>
                    {/* this means the number of children without subs effectively */}
                    <TableCell align="center">
                      {lunchDelItem ? lunchDelItem.count - specialPortions : 0}
                    </TableCell>
                    {tins.map((tin) => {
                      const tinCount = find(comp.tinCounts, (tc) => tc.tinId === tin.id);

                      if (tinCount && tinCount.tinCapacity && tinCount.numberOfTins) {
                        return (
                          <TableCell align="center" key={tin.id}>
                            <Tooltip
                              enterDelay={200}
                              leaveDelay={0}
                              title={`This tin fits ${tinCount.tinCapacity} portions of ${comp.name} per tin`}
                            >
                              <Typography variant="body2">
                                <span style={{ fontSize: "16px", color: pink[500] }}>
                                  {tinCount.numberOfTins}
                                </span>{" "}
                                x {tinCount.tinCapacity} portions
                              </Typography>
                            </Tooltip>
                          </TableCell>
                        );
                      } else {
                        return (
                          <React.Fragment key={tin.id}>
                            <TableCell align="center"> - </TableCell>
                          </React.Fragment>
                        );
                      }
                    })}
                    {/* comp.total is supplied for the nursery/mc/date */}
                    {/*supplied is the total hot (inc round ups) plus subs. so total total */}
                    {/* that means to get hot supplied we need total - subs (if there are any) */}
                    <TableCell align="center">{comp.total - specialPortions}</TableCell>
                  </TableRow>
                );
              });
            }}
          </GraphqlQuery>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const NURSERY = gql`
  query Nursery($id: ID!) {
    nursery(id: $id) {
      id
      name
    }
  }
`;

const NurseryPortionBreakdown = (props) => {
  const { date, nursery, tins } = props;

  return (
    <React.Fragment>
      <Typography className="small-print-heading" variant="h5" component="h5">
        {dayjs(date, "YYYY-MM-DD", true).format("dddd")}
      </Typography>
      <GraphqlQuery
        fetchPolicy="network-only"
        variables={{ date, nurseryId: nursery.id }}
        query={PORTION_INFO}
        withError
        withLoading
      >
        {({ data, error, loading }) => {
          if (error) return <CentredErrorIcon />;
          if (loading) return <Loading />;
          const { deliveryForNursery } = data;

          switch (deliveryForNursery.__typename) {
            case "Delivery":
              return (
                <PortionInformationTable
                  tins={tins}
                  date={date}
                  nurseryId={nursery.id}
                  regularMealItems={deliveryForNursery.lunchDelivery.regularMealItems}
                />
              );
            case "NoDelivery":
              return (
                <Typography style={{ marginBottom: "30px" }}>
                  <InfoNextToText />
                  &nbsp; There are no deliveries for today.
                </Typography>
              );
            default:
              return <CentredErrorIcon />;
          }
        }}
      </GraphqlQuery>
    </React.Fragment>
  );
};

const PrintCSS = styled.div`
  @media print {
    td,
    th {
      padding: 0px;
    }
    orientation: landscape;
    .hide-for-print {
      display: none;
    }
    .small-print-heading {
      font-size: 16px;
    }
    .show-on-print {
      display: block;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    @page :first {
      margin-top: 10px;
    }
    @page {
      margin: 10px 0px 0px 0px;
    }
  }
`;

const OnlyOnPrint = styled.div`
  display: none;
`;

export const NurseryPortionInfo = () => {
  const { date, nurseryId } = useParams();
  const monday = dayjs(date, "YYYY-MM-DD", true).startOf("isoWeek");
  const dates = [0, 1, 2, 3, 4].map((diff) => {
    return monday.add(dayjs.duration({ days: diff }));
  });

  return (
    <PrintCSS>
      <GraphqlQuery query={NURSERY} variables={{ id: nurseryId }}>
        {({ data }) => {
          const { nursery } = data;
          return (
            <PageHeading
              className="hide-for-print"
              maxWidth="xl"
              noHeadingOnPrint={true}
              heading={`Portion Information - ${nursery.name}`}
            >
              <Typography
                style={{ marginBottom: "30px", maxWidth: "750px" }}
                className="hide-for-print"
              >
                Below is information about the number of hot meal component portions you can
                expect for the week. You can see how many of each tin you should expect to receive
                as well as the portion size for each hot meal component. You may end up with more
                portions than you orderd because we always round up to a full tin.
              </Typography>
              <OnlyOnPrint className="show-on-print">
                <Typography>{`Portion Information - ${nursery.name}`}</Typography>
              </OnlyOnPrint>
              <DatePickerSection
                className="hide-for-print"
                type="weekly"
                disableArrowKeys={false}
                maxDate={dayjs().add(6, "month").startOf("month")}
              />
              <GraphqlQuery
                fetchPolicy="network-only"
                variables={{}}
                query={TINS}
                withError
                withLoading
              >
                {({ data: tinData, error: tinError }) => {
                  if (tinError) return <CentredErrorIcon />;
                  const tins = sortBy(tinData.tins, (t) => t.name);
                  return dates.map((d) => {
                    return (
                      <NurseryPortionBreakdown
                        tins={tins}
                        key={`${nursery.id}${d.format("YYYY-MM-DD")}`}
                        nursery={nursery}
                        date={d.format("YYYY-MM-DD")}
                      />
                    );
                  });
                }}
              </GraphqlQuery>
            </PageHeading>
          );
        }}
      </GraphqlQuery>
    </PrintCSS>
  );
};
