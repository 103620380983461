import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import { InfoNextToText } from "components/InfoNextToText";
import { H3 } from "components/typography/H3";
import { useTheme } from "@mui/material/styles";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Alerts } from "components/alerts/Alerts";
import { useGraphqlSubscription } from "graphqlUtils/useGraphqlSubscription";
import { ALL_ACTIVE_SENSITIVITIES } from "pages/admin/MealComponents";
import {
  Container,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Typography,
  Chip,
  useMediaQuery,
  Button,
  IconButton,
} from "@mui/material";

const CREATE_MEAL_COMPONENT = gql`
  mutation CreateMealComponent($input: CreateMealComponentInput!) {
    createMealComponent(input: $input) {
      id
      name
      active
      sensitivityInformation {
        id
        sensitivityName
        present
      }
    }
  }
`;

const SENSITIVITIES_SUBSCRIPTION = gql`
  subscription SensitivitiesSubscription {
    sensitivitiesSubscription {
      id
      name
      order
      mealComponentInformation {
        id
        mealName
        present
      }
    }
  }
`;

const useStyles = makeStyles({
  inputStyles: { marginBottom: "15px", width: "66%" },
  nameBlurb: { marginBottom: "20px" },
  container: { paddingTop: "30px", maxWidth: "750px" },
  noBtn: { marginLeft: "50px", maxHeight: "35px" },
  yesBtn: { marginRight: "50px", maxHeight: "35px" },
  chip: { marginRight: "15px", marginBottom: "10px" },
});

const Form = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
`;

const NextPrev = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const AddMealComponentModal = (props) => {
  const { open, sensitivities, onCancel, onSubmit, refetchQueries } = props;
  const { nameBlurb, inputStyles, container, noBtn, yesBtn, chip } = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [sensIndex, setSensIndex] = React.useState(0);
  const [mealName, updateName] = React.useState("");
  const [moreSensToCheck, setNewSensitivityToCheck] = React.useState(false);
  const [sensitivityList, setSensitivityList] = React.useState(sensitivities);
  const [sensitivityInformation, setSensitivityInfo] = React.useState({});
  const [focused, setFocused] = React.useState(false);

  useGraphqlSubscription({
    subscription: SENSITIVITIES_SUBSCRIPTION,
    variables: {},
    onSubscriptionData: ({ client, subscriptionData }) => {
      setNewSensitivityToCheck(false);
      setNewSensitivityToCheck(true);

      client.writeQuery({
        query: ALL_ACTIVE_SENSITIVITIES,
        variables: { active: true },
        data: { sensitivities: subscriptionData.data.sensitivitiesSubscription },
      });
    },
  });

  React.useEffect(() => {
    setSensitivityList(sensitivities);
  }, [sensitivities, setSensitivityList]);

  // eslint-disable-next-line
  const activeSensitivity = sensitivityList[sensIndex] || {};
  const checked = sensitivityInformation[activeSensitivity.id]
    ? sensitivityInformation[activeSensitivity.id].present
    : null;

  const setActiveComponent = React.useCallback(
    (present) => {
      const mealInfo = {
        [activeSensitivity.id]: {
          sensitivityId: activeSensitivity.id,
          sensitivityName: activeSensitivity.name,
          present,
        },
      };

      setSensitivityInfo({ ...sensitivityInformation, ...mealInfo });
    },
    [activeSensitivity, setSensitivityInfo, sensitivityInformation],
  );

  const maxIndex = sensitivityList.length - 1;
  const haveNotAnsweredCurrent = React.useCallback(() => {
    if (sensitivityList.length === 0) {
      return false;
    } else {
      return (
        sensitivityInformation[activeSensitivity.id] === null ||
        sensitivityInformation[activeSensitivity.id] === undefined
      );
    }
  }, [sensitivityList, sensitivityInformation, activeSensitivity]);

  const onLastPage = sensIndex === maxIndex;

  const next = React.useCallback(() => {
    if (sensIndex >= maxIndex || haveNotAnsweredCurrent()) {
      return;
    }
    const newIndex = sensIndex + 1;
    if (newIndex < maxIndex) {
      setSensIndex(newIndex);
    } else {
      setSensIndex(maxIndex);
    }
  }, [sensIndex, setSensIndex, maxIndex, haveNotAnsweredCurrent]);

  const yes = React.useCallback(() => {
    setActiveComponent(true);
  }, [setActiveComponent]);

  const no = React.useCallback(() => {
    setActiveComponent(false);
  }, [setActiveComponent]);

  const previous = React.useCallback(() => {
    if (sensIndex <= 0) {
      return;
    }
    const minIndex = 0;
    const newIndex = sensIndex - 1;
    if (newIndex > minIndex) {
      setSensIndex(newIndex);
    } else {
      setSensIndex(minIndex);
    }
  }, [setSensIndex, sensIndex]);

  // Add event listeners for key presses so we can left / right arrow through orders
  React.useEffect(() => {
    function downHandler({ key }) {
      if (focused) {
        return;
      }
      if (key === "ArrowLeft") {
        previous();
      }
      if (key === "ArrowRight") {
        next();
      }
      if (key === "y") {
        yes();
      }
      if (key === "n") {
        no();
      }
    }

    if (open) {
      window.addEventListener("keydown", downHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
      };
    }
  }, [next, previous, open, yes, no, focused]);

  const resetForm = () => {
    setSensitivityInfo({});
    updateName("");
    setSensIndex(0);
  };

  const sensitivityInfo = Object.entries(sensitivityInformation).map((key_value) => {
    return key_value[1];
  });

  return (
    <Dialog disableEscapeKeyDown fullScreen={fullScreen} fullWidth maxWidth="md" open={open}>
      <Container className={container}>
        <DialogTitle>
          <Typography variant="h4" component="h4">
            Add a Meal Component
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            className={inputStyles}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            label="Enter meal name..."
            value={mealName}
            onChange={(event) => updateName(event.target.value)}
          />
        </DialogContent>
        <DialogContent className={nameBlurb}>
          <H3>Sensitivities</H3>
          <Typography variant="body2" component="p">
            <InfoNextToText /> When you add a meal component you must check all of the currently
            active sensitivities and tell us if the meal contains any of them.
          </Typography>
          <Typography variant="body2" component="p">
            You can use the arrow keys to move through the list of sensitivities.
          </Typography>
        </DialogContent>
        <DialogContent>
          {sensitivityInfo
            .filter(({ present }) => present)
            .map(({ sensitivityId, sensitivityName }) => {
              return (
                <Chip
                  clickable={false}
                  className={chip}
                  key={sensitivityId}
                  variant="outlined"
                  color="primary"
                  label={sensitivityName}
                />
              );
            })}
        </DialogContent>
        <DialogContent>
          <Form>
            <H3>
              Does {mealName ? mealName : "________"} contain {activeSensitivity.name}?
            </H3>
          </Form>
        </DialogContent>
        <DialogContent>
          <NextPrev>
            <IconButton disabled={sensIndex <= 0} onClick={previous} size="large">
              <ArrowBackIosIcon />
            </IconButton>
            <Button
              onClick={no}
              disabled={sensitivityList.length === 0}
              variant="contained"
              className={noBtn}
              color={checked === false ? "secondary" : "primary"}
            >
              No
            </Button>
            <Button
              onClick={yes}
              disabled={sensitivityList.length === 0}
              variant="contained"
              className={yesBtn}
              color={checked ? "secondary" : "primary"}
            >
              Yes
            </Button>
            <IconButton
              disabled={sensIndex >= maxIndex || haveNotAnsweredCurrent()}
              onClick={next}
              size="large"
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </NextPrev>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              resetForm();
              onCancel();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <GraphqlMutation mutation={CREATE_MEAL_COMPONENT} withError>
            {(createMealComponent) => {
              const variables = {
                input: {
                  name: mealName,
                  sensitivityInformation: sensitivityInfo.map(({ sensitivityId, present }) => ({
                    sensitivityId,
                    present,
                  })),
                },
              };
              const submit = async () => {
                const data = await createMealComponent({ variables, refetchQueries });
                if (!data.data) {
                  return;
                }
                resetForm();
                onSubmit();
              };
              const warningMessage = `It looks like a sensitivity was added while you were adding a meal component.\
We've added the sensitivity to the list of allergens to check. You will need to give an answer before you can save this meal.`;
              return (
                <React.Fragment>
                  <Button
                    disabled={haveNotAnsweredCurrent() || !onLastPage}
                    onClick={submit}
                    color="primary"
                  >
                    Submit
                  </Button>
                  <Alerts.Popup
                    message={warningMessage}
                    doAlert={moreSensToCheck}
                    autoHideDuration={12000}
                    severity="warning"
                  />
                </React.Fragment>
              );
            }}
          </GraphqlMutation>
        </DialogActions>
      </Container>
    </Dialog>
  );
};
