import styled from "styled-components";
import WarningIcon from "@mui/icons-material/ReportProblemOutlined";

/**
 * The default icon sits a little higher than the text. This just makes it
 * so the icon can sit next to the text horizontally no problem.
 */

export const WarningNextToText = styled(WarningIcon)`
  position: relative;
  top: 6px;
`;
