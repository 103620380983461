import React from "react";
import { Grid } from "@mui/material";
import { DaySection } from "containers/menus/DaySection";

export const LunchMenu = (props) => {
  const {
    options,
    onChange,
    meals,
    mondayHighlight,
    tuesdayHighlight,
    wednesdayHighlight,
    thursdayHighlight,
    fridayHighlight,
    componentBorders,
    updateBorders,
  } = props;

  const update = (targetIndex) => (componentsWithSubstitutions) => {
    const newMeals = meals.map((m, index) => {
      if (index === targetIndex) {
        return { ...m, componentsWithSubstitutions };
      } else {
        return m;
      }
    });
    onChange(newMeals);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DaySection
          label="Monday"
          updateBorders={updateBorders}
          componentBorders={componentBorders}
          highlight={mondayHighlight}
          componentsWithSubstitutions={meals[0].componentsWithSubstitutions}
          options={options}
          onChange={update(0)}
        />
      </Grid>
      <Grid item xs={12}>
        <DaySection
          label="Tuesday"
          updateBorders={updateBorders}
          componentBorders={componentBorders}
          highlight={tuesdayHighlight}
          componentsWithSubstitutions={meals[1].componentsWithSubstitutions}
          options={options}
          onChange={update(1)}
        />
      </Grid>
      <Grid item xs={12}>
        <DaySection
          label="Wednesday"
          updateBorders={updateBorders}
          componentBorders={componentBorders}
          highlight={wednesdayHighlight}
          componentsWithSubstitutions={meals[2].componentsWithSubstitutions}
          options={options}
          onChange={update(2)}
        />
      </Grid>
      <Grid item xs={12}>
        <DaySection
          label="Thursday"
          updateBorders={updateBorders}
          componentBorders={componentBorders}
          highlight={thursdayHighlight}
          componentsWithSubstitutions={meals[3].componentsWithSubstitutions}
          options={options}
          onChange={update(3)}
        />
      </Grid>
      <Grid item xs={12}>
        <DaySection
          label="Friday"
          updateBorders={updateBorders}
          componentBorders={componentBorders}
          highlight={fridayHighlight}
          componentsWithSubstitutions={meals[4].componentsWithSubstitutions}
          options={options}
          onChange={update(4)}
        />
      </Grid>
    </Grid>
  );
};
