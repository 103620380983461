import React from "react";
import dayjs from "dayjs_with_plugins";
import styled from "styled-components";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { media } from "media";
import { Button, NativeSelect, FormControl, InputLabel } from "@mui/material";

const DatePickers = styled.div`
  width: 100%;
`;

const AddDateRngBtn = styled.div`
  margin-top: 20px;
  text-align: center;
  ${media.sm`
    text-align: left;
  `}
`;

const StartEndDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.sm`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 550px;
  `}
`;

const P = styled.p`
  font-size: 16px;
  position: relative;
  top: 16px;
  ${media.sm`
    top: 0;
    margin-bottom: 0;
  `}
`;

export const StartEndDateSelector = (props) => {
  const { onSubmit } = props;
  const defaultState = { startDate: null, endDate: null };
  const [dates, setDates] = React.useState(defaultState);

  return (
    <DatePickers>
      <StartEndDate>
        <MobileDatePicker
          closeOnSelect={false}
          clearable
          variant="dialog"
          minDate={dayjs()}
          label="Start Date"
          format="MMMM Do YYYY"
          value={dates.startDate}
          onChange={(startDate) => {
            if (startDate) {
              setDates({ ...dates, startDate: startDate });
            } else {
              setDates(defaultState);
            }
          }}
        />
        <P>To</P>
        <MobileDatePicker
          closeOnSelect={false}
          clearable
          disabled={!dates.startDate}
          variant="dialog"
          minDate={dates.startDate}
          label="End date"
          format="MMMM Do YYYY"
          value={dates.endDate}
          onChange={(endDate) => {
            if (endDate) {
              return setDates({ ...dates, endDate: endDate });
            } else {
              setDates({ ...dates, endDate });
            }
          }}
        />
      </StartEndDate>
      {dates.startDate && dates.endDate && (
        <AddDateRngBtn>
          <Button
            variant="outlined"
            color="primary"
            onClick={async () => {
              await onSubmit(dates);
            }}
          >
            Confirm Dates
          </Button>
        </AddDateRngBtn>
      )}
    </DatePickers>
  );
};

export const DaysOpenForm = (props) => {
  const { onSubmit, disableSubmit } = props;
  const defaultState = { startDate: null, endDate: null };
  const [dates, setDates] = React.useState(defaultState);
  const [openFor, setOpenFor] = React.useState("");

  return (
    <DatePickers>
      <StartEndDate>
        <MobileDatePicker
          closeOnSelect={false}
          clearable
          variant="dialog"
          minDate={dayjs()}
          label="Start Date"
          format="MMMM Do YYYY"
          value={dates.startDate}
          onChange={(startDate) => {
            if (startDate) {
              setDates({ ...dates, startDate: startDate });
            } else {
              setDates(defaultState);
            }
          }}
        />
        <P>To</P>
        <MobileDatePicker
          closeOnSelect={false}
          clearable
          disabled={!dates.startDate}
          variant="dialog"
          minDate={dates.startDate}
          label="End date"
          format="MMMM Do YYYY"
          value={dates.endDate}
          onChange={(endDate) => {
            if (endDate) {
              return setDates({ ...dates, endDate: endDate });
            } else {
              setDates({ ...dates, endDate });
            }
          }}
        />
        <FormControl style={{ marginTop: "15px", width: "257px" }}>
          <InputLabel>Open For</InputLabel>
          <NativeSelect onChange={(event) => setOpenFor(event.target.value)} value={openFor}>
            <option value="" />
            <option value="LUNCH">Lunch</option>
            <option value="TEA">Tea</option>
            <option value="LUNCH_AND_TEA">Lunch And Tea</option>
          </NativeSelect>
        </FormControl>
      </StartEndDate>
      {dates.startDate && dates.endDate && openFor && (
        <AddDateRngBtn>
          <Button
            variant="outlined"
            color="primary"
            disabled={disableSubmit}
            onClick={async () => {
              const result = await onSubmit({ ...dates, openFor });

              if (result.data) {
                setDates(defaultState);
                setOpenFor("");
              }
            }}
          >
            Confirm Dates
          </Button>
        </AddDateRngBtn>
      )}
    </DatePickers>
  );
};

export const DaysClosedForm = (props) => {
  const { onSubmit, disableSubmit } = props;
  const defaultState = { startDate: null, endDate: null };
  const [dates, setDates] = React.useState(defaultState);
  const [closedFor, setClosedFor] = React.useState("");
  return (
    <DatePickers>
      <StartEndDate>
        <MobileDatePicker
          variant="dialog"
          minDate={dayjs()}
          label="Start Date"
          format="MMMM Do YYYY"
          value={dates.startDate}
          onChange={(startDate) => {
            if (startDate) {
              setDates({ ...dates, startDate: startDate });
            } else {
              setDates(defaultState);
            }
          }}
        />
        <P>To</P>
        <MobileDatePicker
          disabled={!dates.startDate}
          variant="dialog"
          minDate={dates.startDate}
          label="End date"
          format="MMMM Do YYYY"
          value={dates.endDate}
          onChange={(endDate) => {
            if (endDate) {
              return setDates({ ...dates, endDate: endDate });
            } else {
              setDates({ ...dates, endDate });
            }
          }}
        />
        <FormControl style={{ marginTop: "15px", width: "257px" }}>
          <InputLabel>Closed For</InputLabel>
          <NativeSelect onChange={(event) => setClosedFor(event.target.value)} value={closedFor}>
            <option value="" />
            <option value="LUNCH">Lunch</option>
            <option value="TEA">Tea</option>
            <option value="LUNCH_AND_TEA">Lunch And Tea</option>
          </NativeSelect>
        </FormControl>
      </StartEndDate>
      {dates.startDate && dates.endDate && closedFor && (
        <AddDateRngBtn>
          <Button
            variant="outlined"
            color="primary"
            disabled={disableSubmit}
            onClick={async () => {
              const result = await onSubmit({ ...dates, closedFor });

              if (result.data) {
                setDates(defaultState);
                setClosedFor("");
              }
            }}
          >
            Confirm Dates
          </Button>
        </AddDateRngBtn>
      )}
    </DatePickers>
  );
};
