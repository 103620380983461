import { gql } from "@apollo/client";
import React from "react";
import dayjs from "dayjs_with_plugins";
import makeStyles from "@mui/styles/makeStyles";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { PageHeading } from "components/PageHeading";
import { DatePickerSection } from "components/DatePickerSection";
import { useParams } from "react-router-dom";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { InfoNextToText } from "components/InfoNextToText";
import { Typography, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

const DELIVERY = gql`
  query Delivery($nurseryId: ID!, $date: Date!) {
    deliveryForNursery(nurseryId: $nurseryId, date: $date) {
      ... on NoDelivery {
        message
      }
      ... on Delivery {
        id
        signature {
          color
          time
          x
          y
        }
        lunchDelivery {
          id
          items {
            id
            temperature
            packedAt
            packedBy
            count
            mealComponent {
              id
              name
            }
          }
        }
        teaDelivery {
          id
          items {
            id
            temperature
            packedAt
            packedBy
            count
            mealComponent {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  table: { marginBottom: "30px" },
});

const ReadOnlyDeliveries = (props) => {
  const { deliveryForNursery } = props;
  const { table } = useStyles();

  return (
    <Table size="small" className={table}>
      <TableHead>
        <TableRow>
          <TableCell>Meal Component</TableCell>
          <TableCell>Packed At</TableCell>
          <TableCell>Packed By</TableCell>
          <TableCell>Temp. (Celcius)</TableCell>
          <TableCell>Expected</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {deliveryForNursery.lunchDelivery &&
          deliveryForNursery.lunchDelivery.items.map((item) => {
            const { temperature, packedAt, count, mealComponent, packedBy } = item;

            const packedTime = packedAt ? dayjs(packedAt).format("LT") : "--";
            const prettyTemp = temperature ? temperature : "--";
            const prettyBy = packedBy ? packedBy : "--";

            return (
              <TableRow key={item.id}>
                <TableCell>{mealComponent.name}</TableCell>
                <TableCell>{packedTime}</TableCell>
                <TableCell>{prettyBy}</TableCell>
                <TableCell>{prettyTemp}</TableCell>
                <TableCell>{count}</TableCell>
              </TableRow>
            );
          })}
        {deliveryForNursery.teaDelivery &&
          deliveryForNursery.teaDelivery.items.map((item) => {
            const { temperature, packedAt, count, mealComponent, packedBy } = item;

            const packedTime = packedAt ? dayjs(packedAt).format("LT") : "--";
            const prettyTemp = temperature ? temperature : "--";
            const prettyBy = packedBy ? packedBy : "--";
            return (
              <TableRow key={item.id}>
                <TableCell>{mealComponent.name}</TableCell>
                <TableCell>{packedTime}</TableCell>
                <TableCell>{prettyBy}</TableCell>
                <TableCell>{prettyTemp}</TableCell>
                <TableCell>{count}</TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

export const DeliveryFormPage = () => {
  const { date, nurseryId } = useParams();

  return (
    <PageHeading maxWidth="md" heading="Delivery Forms">
      <DatePickerSection type="daily" disableArrowKeys={false} />
      <NurseryDeliveryForm date={date} nurseryId={nurseryId} />
    </PageHeading>
  );
};

const NurseryDeliveryForm = (props) => {
  const { date, nurseryId } = props;
  return (
    <GraphqlQuery query={DELIVERY} withError withLoading variables={{ date, nurseryId }}>
      {({ data, error }) => {
        if (error) {
          return <CentredErrorIcon />;
        }
        const { deliveryForNursery } = data;
        switch (deliveryForNursery.__typename) {
          case "Delivery":
            return <ReadOnlyDeliveries deliveryForNursery={deliveryForNursery} />;
          case "NoDelivery":
            return (
              <Typography>
                <InfoNextToText />
                &nbsp; There are no deliveries for today.
              </Typography>
            );
          default:
            return <CentredErrorIcon />;
        }
      }}
    </GraphqlQuery>
  );
};
