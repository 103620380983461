import { gql } from "@apollo/client";
import React from "react";
import dayjs from "dayjs_with_plugins";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { PageHeading } from "components/PageHeading";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { DatePickerSection } from "components/DatePickerSection";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import { Typography } from "@mui/material";
import { PrintableDeliveryForm } from "pages/deliveryDriver/PrintableDeliveryForm";

const NURSERIES_WITH_DELIVERIES = gql`
  query NurseriesWithDeliveries($date: Date!) {
    nurseriesWithDeliveries(date: $date) {
      id
      name
      address
      phoneNumber
    }
  }
`;

const PrintCSS = styled.div`
  @media print {
    orientation: portrait;
    .hide-for-print {
      display: none;
    }
    @page :first {
      margin-top: 50px;
    }
    @page {
      margin: 50px 0px 50px 0px;
    }
  }
`;

export const DeliveryFormsPage = () => {
  const { date } = useParams();
  return (
    <PrintCSS>
      <PageHeading noHeadingOnPrint heading="Printable Delivery Forms" className="hide-for-print">
        {/* Random earliest date but not wrong. No significance, just better to pick something. */}
        <DatePickerSection
          type="weekly"
          maxDate={dayjs().utc().add(3, "month").startOf("isoWeek")}
          earliestDate="2020-03-23"
          disableArrowKeys={false}
        />
        <Typography style={{ marginBottom: "30px" }} className="hide-for-print">
          Below are the delivery forms for all nurseries that have deliveries due today. They will
          be formatted one to a page when you print them.
        </Typography>
        <GraphqlQuery
          query={NURSERIES_WITH_DELIVERIES}
          variables={{ date }}
          withLoading
          withError
        >
          {({ data, error }) => {
            if (error) return <CentredErrorIcon />;
            return data.nurseriesWithDeliveries.map((n) => {
              return (
                <PrintableDeliveryForm nursery={n} date={date} nurseryId={n.id} key={n.id} />
              );
            });
          }}
        </GraphqlQuery>
      </PageHeading>
    </PrintCSS>
  );
};
