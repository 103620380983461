import { createTheme, responsiveFontSizes } from "@mui/material/styles";
// import { pink } from '@mui/material/colors';

// The default Material UI Breakpoints are:
// sm: 600px
// md: 960px
// lg: 1280px
// xl: 1920px
const breakpoints = {
  keys: ["xs", "sm", "md", "lg", "xl"],
  values: {
    xs: 480,
    sm: 600,
    md: 800,
    lg: 1280,
    xl: 1920,
  },
};

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "rgb(63, 81, 181)",
      },
      secondary: {
        main: "rgb(233, 30, 99)",
      },
    },
    breakpoints,
    // This disables transitions which just makes things feel a bit snappier.
    // Also it's faster on mobile.
    transitions: {
      // So we have `transition: none;` everywhere
      create: () => "none",
    },
  }),
);
