import { gql } from "@apollo/client";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { H3 } from "components/typography/H3";
import { Typography, Button } from "@mui/material";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";

const RESET_PASSWORD = gql`
  mutation ResetPassword {
    resetPassword
  }
`;

const useStyles = makeStyles({
  h3Style: {
    marginBottom: "15px",
  },
  subText: {
    marginBottom: "15px",
  },
  marginBottom: { marginBottom: "30px" },
});

export const PasswordReset = () => {
  const { subText, h3Style, marginBottom } = useStyles();
  const [clickedReset, clickReset] = React.useState(false);

  return (
    <React.Fragment>
      <H3 className={h3Style}>Account Actions</H3>
      <Typography className={subText} variant="body1">
        This will send you an email with a link that you can follow to reset your password.
      </Typography>
      {/* on click trigger mutation and set some state that says we've already triggered one */}
      {/* This is just a v poor persons rate limit. */}
      <GraphqlMutation mutation={RESET_PASSWORD} withError>
        {(resetPassword) => {
          const onClick = async () => {
            const result = await resetPassword();
            if (result.data) {
              clickReset(true);
            }
          };

          return (
            <Button
              className={marginBottom}
              variant="outlined"
              color="primary"
              aria-label="add"
              disabled={clickedReset}
              onClick={onClick}
            >
              Change Password
            </Button>
          );
        }}
      </GraphqlMutation>
    </React.Fragment>
  );
};
