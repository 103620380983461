import React from "react";
import dayjs from "dayjs_with_plugins";
import { useAuth0 } from "@auth0/auth0-react";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import { UserContext } from "contexts";
import SettingsIcon from "@mui/icons-material/Settings";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import LocalPizzaIcon from "@mui/icons-material/LocalPizza";
import PersonIcon from "@mui/icons-material/Person";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import GroupIcon from "@mui/icons-material/Group";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import PrintIcon from "@mui/icons-material/Print";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import GridOnOutlinedIcon from "@mui/icons-material/GridOnOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import KitchenIcon from "@mui/icons-material/Kitchen";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },
  menuButton: { marginRight: theme.spacing(2) },
  title: { flexGrow: 1 },
  list: { width: 250 },
}));

const NurseryNav = (props) => {
  const classes = useStyles();
  const { nurseryId, open, closeNav, openNav, logout } = props;
  return (
    <div className={`${classes.root} hide-for-print`}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            className={classes.menuButton}
            aria-label="menu"
            onClick={openNav}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={open} onClose={closeNav}>
            <List className={classes.list}>
              <ListItemButton component={Link} to="/">
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
              <ListItemButton
                component={Link}
                to={`/nursery/${nurseryId}/date/${dayjs()
                  .startOf("isoWeek")
                  .format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <FastfoodIcon />
                </ListItemIcon>
                <ListItemText primary="Orders" />
              </ListItemButton>
              <ListItemButton
                component={Link}
                to={`/diet-advice/${nurseryId}/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <MenuBookIcon />
                </ListItemIcon>
                <ListItemText primary="Diet Advice" />
              </ListItemButton>
              <ListItemButton
                component={Link}
                to={`/delivery-forms/${nurseryId}/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <AirportShuttleIcon />
                </ListItemIcon>
                <ListItemText primary="Delivery Forms" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to={`/temperatures/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <AcUnitIcon />
                </ListItemIcon>
                <ListItemText primary="Temperatures" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to={`/nursery/${nurseryId}/portion-information/date/${dayjs()
                  .startOf("isoWeek")
                  .format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Portion Info" />
              </ListItemButton>

              <ListItemButton component={Link} to="/current-menu">
                <ListItemIcon>
                  <RestaurantMenuIcon />
                </ListItemIcon>
                <ListItemText primary="Current Menu" />
              </ListItemButton>

              <ListItemButton component={Link} to="/faqs">
                <ListItemIcon>
                  <LiveHelpOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="FAQs" />
              </ListItemButton>

              <ListItemButton component={Link} to="/hungry-monsters-closures">
                <ListItemIcon>
                  <CancelOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="HM Closures" />
              </ListItemButton>

              <ListItemButton component={Link} to="/settings">
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </List>
          </Drawer>
          <Typography variant="h6" className={classes.title}>
            Hungry Monsters
          </Typography>
          <Button color="inherit" onClick={() => logout()}>
            Log out
          </Button>
        </Toolbar>
      </AppBar>
      {/* This is because we have a fixed position navbar, we want this much space before the page starts */}
      <Toolbar />
    </div>
  );
};

const AdminNav = (props) => {
  const classes = useStyles();
  const { open, closeNav, openNav, logout } = props;
  return (
    <div className={`${classes.root} hide-for-print`}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            className={classes.menuButton}
            aria-label="menu"
            onClick={openNav}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={open} onClose={closeNav}>
            <List className={classes.list}>
              <ListItemButton component={Link} to="/">
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home - Nursery View" />
              </ListItemButton>

              <ListItemButton component={Link} to="/upload-pdf">
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Set Home PDF" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to={`/orders-summary/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <FastfoodIcon />
                </ListItemIcon>
                <ListItemText primary="Orders Summary" />
              </ListItemButton>

              <ListItemButton component={Link} to="/nurseries">
                <ListItemIcon>
                  <ChildFriendlyIcon />
                </ListItemIcon>
                <ListItemText primary="Nurseries" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to={`/nurseries/orders/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <ChildFriendlyIcon />
                </ListItemIcon>
                <ListItemText primary="Nurseries - Orders" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to={`/nurseries/diet-advice/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <MenuBookIcon />
                </ListItemIcon>
                <ListItemText primary="Nursery - Diet Advice" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to={`/nurseries/portion-information/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <ChildFriendlyIcon />
                </ListItemIcon>
                <ListItemText primary="Nurseries - Portion Info" />
              </ListItemButton>

              <ListItemButton component={Link} to="/nurseries/settings">
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Nurseries - Settings" />
              </ListItemButton>

              <ListItemButton component={Link} to={`/sensitivities`}>
                <ListItemIcon>
                  <LocalPharmacyIcon />
                </ListItemIcon>
                <ListItemText primary="Sensitivities" />
              </ListItemButton>

              <ListItemButton component={Link} to="/sensitivities-check">
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="Sensitivities Check" />
              </ListItemButton>

              <ListItemButton component={Link} to={`/meal-components`}>
                <ListItemIcon>
                  <LocalPizzaIcon />
                </ListItemIcon>
                <ListItemText primary="Meal Components" />
              </ListItemButton>

              <ListItemButton component={Link} to={`/tins`}>
                <ListItemIcon>
                  <CheckBoxOutlineBlankOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Tins" />
              </ListItemButton>

              <ListItemButton component={Link} to={`/portion-sizes`}>
                <ListItemIcon>
                  <GridOnOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Portion Sizes" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to={`/kitchen-distribution-report/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <KitchenIcon />
                </ListItemIcon>
                <ListItemText primary="Kitchen Distribution Report" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to={`/packing-reports/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Packing Report" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to={`/delivery-forms/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <PrintIcon />
                </ListItemIcon>
                <ListItemText primary="Printable Delivery Forms" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to={`/printable-diet-advice/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <PrintIcon />
                </ListItemIcon>
                <ListItemText primary="Printable Diet Advice" />
              </ListItemButton>

              <ListItemButton component={Link} to={`/menus`}>
                <ListItemIcon>
                  <MenuBookIcon />
                </ListItemIcon>
                <ListItemText primary="Menus" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to={`/past-menus/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <MenuBookIcon />
                </ListItemIcon>
                <ListItemText primary="Past Menus" />
              </ListItemButton>

              <ListItemButton component={Link} to="/invoice-groups">
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Invoice Groups" />
              </ListItemButton>
              <ListItemButton
                component={Link}
                to={`/invoices/date/${dayjs().startOf("month").format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <AccountBalanceIcon />
                </ListItemIcon>
                <ListItemText primary="Invoices" />
              </ListItemButton>

              <ListItemButton component={Link} to={`/user-management`}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="User Management" />
              </ListItemButton>

              <ListItemButton component={Link} to="/preview-faqs">
                <ListItemIcon>
                  <LiveHelpOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Preview FAQs" />
              </ListItemButton>

              <ListItemButton component={Link} to="/faqs">
                <ListItemIcon>
                  <LiveHelpOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="FAQs" />
              </ListItemButton>

              <ListItemButton component={Link} to="/settings">
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="HM Settings" />
              </ListItemButton>
            </List>
          </Drawer>
          <Typography variant="h6" className={classes.title}>
            Hungry Monsters
          </Typography>
          <Button color="inherit" onClick={() => logout()}>
            Log out
          </Button>
        </Toolbar>
      </AppBar>
      {/* This is because we have a fixed position navbar, we want this much space before the page starts */}
      <Toolbar />
    </div>
  );
};

const DriverNav = (props) => {
  const classes = useStyles();
  const { open, closeNav, openNav, logout } = props;
  return (
    <div className={`${classes.root} hide-for-print`}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            className={classes.menuButton}
            aria-label="menu"
            onClick={openNav}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={open} onClose={closeNav}>
            <List className={classes.list}>
              <ListItemButton
                component={Link}
                to={`/temperatures/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <AcUnitIcon />
                </ListItemIcon>
                <ListItemText primary="Temperatures" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to={`/delivery-forms/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <AirportShuttleIcon />
                </ListItemIcon>
                <ListItemText primary="Delivery Forms" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to={`/printable-diet-advice/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <PrintIcon />
                </ListItemIcon>
                <ListItemText primary="Printable Diet Advice" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to={`/kitchen-distribution-report/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <KitchenIcon />
                </ListItemIcon>
                <ListItemText primary="Kitchen Distribution Report" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to={`/packing-reports/date/${dayjs().format("YYYY-MM-DD")}`}
              >
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Packing Report" />
              </ListItemButton>
            </List>
          </Drawer>
          <Typography variant="h6" className={classes.title}>
            Hungry Monsters
          </Typography>
          <Button color="inherit" onClick={() => logout()}>
            Log out
          </Button>
        </Toolbar>
      </AppBar>
      {/* This is because we have a fixed position navbar, we want this much space before the page starts */}
      <Toolbar />
    </div>
  );
};

export const NavBar = function ButtonAppBar() {
  const { logout } = useAuth0();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const user = React.useContext(UserContext);
  const { role, nurseryId } = user;

  const setOpen = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setIsMenuOpen(open);
  };

  switch (role) {
    case "admin":
      return (
        <AdminNav
          open={isMenuOpen}
          closeNav={setOpen(false)}
          openNav={setOpen(true)}
          logout={logout}
        />
      );
    case "nursery":
      return (
        <NurseryNav
          nurseryId={nurseryId}
          open={isMenuOpen}
          closeNav={setOpen(false)}
          openNav={setOpen(true)}
          logout={logout}
        />
      );
    case "delivery_driver":
      return (
        <DriverNav
          open={isMenuOpen}
          closeNav={setOpen(false)}
          openNav={setOpen(true)}
          logout={logout}
        />
      );
    default:
      return null;
  }
};
