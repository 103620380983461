import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { TextInput } from "components/inputs/Inputs";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { useTheme } from "@mui/material/styles";

import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  useMediaQuery,
  DialogActions,
  Typography,
} from "@mui/material";

const CREATE_DRIVER = gql`
  mutation CreateDriver($input: CreateDriverInput!) {
    createDeliveryDriver(input: $input) {
      id
      name
      role
      email
    }
  }
`;
const Div = styled.div`
  margin: 0 auto 20px auto;
`;

export const AddDriverModal = (props) => {
  const { onCancel, refetchQueries, open, onSubmit } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [name, updateName] = React.useState("");
  const [email, updateEmail] = React.useState("");
  // Just a very simple email validator.
  const emailError = Boolean(email) && !/^\S+@\S+\.\S+$/.test(email);
  const emailHelper = emailError ? "Invalid email" : "";

  return (
    <Dialog disableEscapeKeyDown fullScreen={fullScreen} fullWidth maxWidth="md" open={open}>
      <DialogTitle>
        <Typography variant="h4" component="h4">
          Add Delivery Driver
        </Typography>
      </DialogTitle>
      <Div>
        <DialogContent>
          <TextInput
            required
            label="Enter a name..."
            variant="outlined"
            value={name}
            onChange={(event) => updateName(event.target.value)}
          />
        </DialogContent>
        <DialogContent>
          <TextInput
            required
            label="Enter a valid email..."
            variant="outlined"
            value={email}
            helperText={emailHelper}
            error={emailError}
            onChange={(event) => updateEmail(event.target.value)}
          />
        </DialogContent>
      </Div>
      <DialogContent>
        <Typography variant="body1" component="p">
          Once created an email will be sent {name ? `to ${name}` : ""} with instructions on how
          to sign up, so make sure the email is correct.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            updateName("");
            updateEmail("");
            onCancel();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <GraphqlMutation mutation={CREATE_DRIVER} withError>
          {(createDriver) => {
            const submit = async () => {
              if (emailError) return;

              const variables = { input: { name, email } };
              const result = await createDriver({ variables, refetchQueries });
              if (result.data) {
                updateName("");
                updateEmail("");
                onSubmit();
              }
              return result;
            };

            return (
              <Button disabled={emailError || !email || !name} onClick={submit} color="primary">
                Create
              </Button>
            );
          }}
        </GraphqlMutation>
      </DialogActions>
    </Dialog>
  );
};
