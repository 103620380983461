import { gql } from "@apollo/client";
import React from "react";
import { PageHeading } from "components/PageHeading";
import makeStyles from "@mui/styles/makeStyles";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import styled from "styled-components";
import dayjs from "dayjs_with_plugins";
import { Link } from "react-router-dom";
import { LazyGraphqlQuery } from "graphqlUtils/LazyGraphqlQuery";
import Autocomplete from "@mui/material/Autocomplete";
import { media } from "media";
import { MobileDatePicker } from "@mui/x-date-pickers";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  TableHead,
  Divider,
  TextField,
  Link as MaterialUiLink,
  Typography,
  Button,
} from "@mui/material";
import { Loading } from "components/Loading";

export const SENSITIVITIES = gql`
  query Sensitivities {
    sensitivities {
      id
      name
      order
      active
      readOnly
    }
  }
`;

// This lets us check to see if a sensitivity has been used within a given time frame.
// "Used" means something like "A child who ate had the sensitivity in the time frame"
const CHECK_SENSITIVITY = gql`
  query SensitivitiesCheck($input: SensitivityCheckInput!) {
    sensitivitiesCheck(input: $input) {
      id
      childName
      nurseryID
      nurseryName
      latestOrderDate
    }
  }
`;

const TableWrapper = styled.div`
  background-color: white;
  margin: 0 auto 30px auto;
  max-height: 66vh;
  max-width: 750px;
`;

const FormWrapper = styled.div`
  margin: 0 auto;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const useStyles = makeStyles({
  selectFormStyles: { maxWidth: "221px", width: "100%" },
  info: {
    margin: "0 auto 0 auto",
    maxWidth: "750px",
  },
  autoCompleteStyles: {
    width: "100%",
    maxWidth: "320px",
    marginBottom: "15px",
  },
  searchBtnStyles: {
    alignSelf: "flex-end",
    marginLeft: "30px",
  },
  btn: { marginBottom: "15px", marginRight: "15px" },
});

const StartEndDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  ${media.sm`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 550px;
  `}
`;
const P = styled.p`
  font-size: 16px;
  position: relative;
  top: 16px;
  margin: 0 20px;
  ${media.sm`
    top: 0;
    margin-bottom: 0;
  `}
`;

const SelectAndSearchBtnWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 505px;
  margin-bottom: 30px;
  justify-content: flex-start;
  ${media.sm`
    justify-content: space-between;
  `}
`;

export const DateRange = (props) => {
  const { onStartDateChange, startDate, endDate, onEndDateChange } = props;

  return (
    <div style={{ marginBottom: "15px" }}>
      <StartEndDate>
        <MobileDatePicker
          timezone="UTC"
          clearable
          variant="dialog"
          // This is roughly the start of the data in the app.
          minDate={dayjs("2020-03-01", "YYYY-MM-DD", true)}
          label="Start Date"
          format="DD/MM/YYYY"
          value={startDate}
          onChange={onStartDateChange}
        />
        <P>To</P>
        <MobileDatePicker
          clearable
          timezone="UTC"
          disabled={!startDate}
          variant="dialog"
          minDate={startDate}
          label="End date"
          format="DD/MM/YYYY"
          value={endDate}
          onChange={onEndDateChange}
        />
      </StartEndDate>
    </div>
  );
};

const ChildInfo = (props) => {
  const { childInfo, orderType, sensitivity, startDate, endDate } = props;
  if (childInfo.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={3} align="center">
          <Typography>
            {`No ${orderType} children found with ${sensitivity.name} sensitivity between ${dayjs(
              startDate,
            ).format("DD/MM/YY")} and ${dayjs(endDate).format("DD/MM/YY")}`}
          </Typography>
        </TableCell>
      </TableRow>
    );
  } else {
    return childInfo.map((child) => {
      // Need to be child ID! Needs to be unique per child der.
      const { id, childName, nurseryID, nurseryName, latestOrderDate } = child;
      const dayjsDate = dayjs(latestOrderDate);
      return (
        <TableRow key={id}>
          <TableCell>{childName}</TableCell>
          <TableCell>
            <Link target="_blank" to={`/nursery/${nurseryID}`}>
              <MaterialUiLink component="p">{nurseryName}</MaterialUiLink>
            </Link>
          </TableCell>
          <TableCell>
            <Link
              target="_blank"
              to={`/nursery/${nurseryID}/date/${dayjsDate.format("YYYY-MM-DD")}`}
            >
              <MaterialUiLink component="p">{`${dayjsDate.format(
                "Do MMMM YYYY",
              )} (${dayjsDate.format("dddd")})`}</MaterialUiLink>
            </Link>
          </TableCell>
        </TableRow>
      );
    });
  }
};

export const SensitivitiesCheck = () => {
  const classes = useStyles();
  const { autoCompleteStyles, searchBtnStyles, selectFormStyles } = useStyles();
  const [selected, setSelected] = React.useState(null);
  const [orderType, setOrderType] = React.useState("LUNCH");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const onStartDateChange = (startDate) => {
    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate(null);
    }
  };
  const onEndDateChange = (endDate) => {
    if (endDate) {
      setEndDate(endDate);
    } else {
      setEndDate(null);
    }
  };

  return (
    <PageHeading maxWidth="lg" heading="Sensitivities Check">
      <Typography variant="body1" className={classes.info}>
        Below you can search to see if there were any children who attended a lunch or tea that
        were allergic to a given sensitivity within the given time range.
      </Typography>
      <Divider style={{ margin: "20px auto 30px auto", maxWidth: "750px" }} />
      <GraphqlQuery
        // This just ensures if we add / make inactive a sensitivity it shows up
        // right away.. Easier than faffing with subscriptions etc.
        fetchPolicy="network-only"
        query={SENSITIVITIES}
        variables={{}}
        withError
      >
        {({ data, error }) => {
          if (error) {
            return <CentredErrorIcon />;
          }

          return (
            <LazyGraphqlQuery withLoading={false} withError query={CHECK_SENSITIVITY}>
              {(queryFun, { data: d, error: e, loading }) => {
                if (e) {
                  return;
                }

                const executeSearch = async () => {
                  if (selected && orderType && startDate && endDate) {
                    const variables = {
                      input: {
                        startDate: startDate.format("YYYY-MM-DD"),
                        endDate: endDate.format("YYYY-MM-DD"),
                        orderType,
                        sensitivity: selected.name,
                      },
                    };
                    await queryFun({ variables });
                  } else {
                    return;
                  }
                };

                const child_info = d?.sensitivitiesCheck;
                const getOptionLabel = (option) => {
                  if (option.name) {
                    return `${option.name} ${option.active ? "" : " (inactive)"} ${
                      option.readOnly ? "(read only)" : ""
                    }`;
                  } else {
                    return "";
                  }
                };

                return (
                  <React.Fragment>
                    <FormWrapper>
                      <Autocomplete
                        disableClearable
                        blurOnSelect
                        className={autoCompleteStyles}
                        filterSelectedOptions
                        value={selected}
                        options={data.sensitivities}
                        size="small"
                        getOptionLabel={getOptionLabel}
                        onChange={(_event, values) => {
                          setSelected(values);
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              required
                              label="Select sensitivities to search for"
                              variant="outlined"
                              placeholder="Type to filter the list"
                            />
                          );
                        }}
                      />
                      <div style={{ maxWidth: "750px" }}>
                        <DateRange
                          startDate={startDate}
                          endDate={endDate}
                          onStartDateChange={onStartDateChange}
                          onEndDateChange={onEndDateChange}
                        />
                      </div>
                      <SelectAndSearchBtnWrapper>
                        <FormControl className={selectFormStyles}>
                          <InputLabel required>Contract type</InputLabel>
                          <Select
                            native
                            value={orderType || ""}
                            onChange={(event) => setOrderType(event.target.value)}
                          >
                            <option value="LUNCH">Lunch</option>
                            <option value="TEA">Tea</option>
                          </Select>
                        </FormControl>
                        {loading ? (
                          <Loading className={searchBtnStyles} />
                        ) : (
                          <Button
                            className={searchBtnStyles}
                            onClick={executeSearch}
                            variant="outlined"
                            color="secondary"
                            disabled={!selected || !startDate || !endDate || !orderType}
                          >
                            Search
                          </Button>
                        )}
                      </SelectAndSearchBtnWrapper>
                    </FormWrapper>
                    <TableContainer component={TableWrapper}>
                      <Table stickyHeader size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Child Name</TableCell>
                            <TableCell>Nursery</TableCell>
                            <TableCell>Latest Order Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {child_info && (
                            <ChildInfo
                              orderType={orderType}
                              sensitivity={selected}
                              startDate={startDate}
                              endDate={endDate}
                              childInfo={child_info}
                            />
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </React.Fragment>
                );
              }}
            </LazyGraphqlQuery>
          );
        }}
      </GraphqlQuery>
    </PageHeading>
  );
};
