import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import dayjs from "dayjs_with_plugins";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { LazyGraphqlQuery } from "graphqlUtils/LazyGraphqlQuery";
import Autocomplete from "@mui/material/Autocomplete";
import { CentredErrorIcon } from "components/CentredErrorIcon";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Link as MaterialUiLink,
  Chip,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { Loading } from "components/Loading";

export const SENSITIVITIES = gql`
  query Sensitivities {
    sensitivities {
      id
      name
      order
      active
      readOnly
    }
  }
`;

const SEARCH_CHILDREN = gql`
  query SearchForChildrenWithSensitivities($input: SearchChildrenInput!) {
    searchChildren(input: $input) {
      id
      name
      nursery {
        id
        name
        phoneNumber
      }
    }
  }
`;

const TableWrapper = styled.div`
  background-color: white;
  margin-bottom: 30px;
  max-height: 66vh;
`;

const useStyles = makeStyles({
  space: { marginBottom: "30px" },
  smallSpace: { marginBottom: "15px" },
  chip: { marginRight: "3px", marginBottom: "5px" },
  btn: { marginBottom: "15px", marginRight: "15px" },
});

export const SensitivitiesSearch = (props) => {
  const { date } = props;
  const { space, smallSpace, chip, btn } = useStyles();
  const [selected, setSelected] = React.useState([]);

  return (
    <GraphqlQuery
      // This just ensures if we add / make inactive a sensitivity it shows up
      // right away.. Easier than faffing with subscriptions etc.
      fetchPolicy="network-only"
      query={SENSITIVITIES}
      variables={{}}
      withError
    >
      {({ data, error }) => {
        if (error) {
          return <CentredErrorIcon />;
        }

        return (
          <React.Fragment>
            <Typography className={smallSpace} variant="h5">
              Lunch and Tea Sensitivities Search
            </Typography>
            <Typography variant="body1" className={smallSpace}>
              Below you can search for all of the children who were eating lunch or tea on{" "}
              {dayjs(date).format("DD/MM/YY")} and who have any of the selected sensitivities.
            </Typography>
            <Typography variant="body1" className={smallSpace}>
              The sensitivities in blue are the currently active sensitivities. Those in pink are
              no longer active, but may have been on the date of the order.
            </Typography>
            <Typography variant="body1" className={smallSpace}>
              If you choose two sensitivities you will be returned all of the children that have
              one as well as all of the children who have the other.
            </Typography>
            <LazyGraphqlQuery withLoading={false} withError query={SEARCH_CHILDREN}>
              {(queryFun, { data: d, error: e, loading }) => {
                if (e) {
                  return;
                }
                const variables = {
                  input: { date, sensitivities: selected.map(({ id }) => id) },
                };
                const children = d ? d.searchChildren : [];

                return (
                  <React.Fragment>
                    <Button
                      className={btn}
                      onClick={() => setSelected([])}
                      variant="outlined"
                      disabled={loading || selected.length < 1}
                    >
                      Clear Selections
                    </Button>
                    {loading ? (
                      <Loading />
                    ) : (
                      <Button
                        className={smallSpace}
                        onClick={() => queryFun({ variables })}
                        variant="outlined"
                        disabled={selected.length < 1}
                      >
                        Search
                      </Button>
                    )}
                    {selected.length > 0 && (
                      <Grid container className={smallSpace}>
                        {selected.map(({ name, id, active, readOnly }) => {
                          const colour = active ? "primary" : "secondary";
                          return (
                            <Chip
                              onDelete={() =>
                                setSelected(selected.filter((sens) => sens.id !== id))
                              }
                              className={chip}
                              variant="outlined"
                              size="small"
                              key={id}
                              label={`${name}${active ? "" : " (inactive)"}${
                                readOnly ? " (read only)" : ""
                              }`}
                              color={colour}
                            />
                          );
                        })}
                      </Grid>
                    )}
                    <Autocomplete
                      multiple
                      disableClearable
                      blurOnSelect
                      className={space}
                      filterSelectedOptions
                      value={selected}
                      options={data.sensitivities}
                      size="small"
                      getOptionLabel={(option) => option.name}
                      onChange={(_event, values) => {
                        setSelected(values);
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            required
                            label="Select sensitivities to search for"
                            variant="outlined"
                            placeholder="Type to filter the list"
                          />
                        );
                      }}
                      slotProps={{
                        chip: { style: { display: "none" } },
                      }}
                    />
                    <TableContainer component={TableWrapper}>
                      <Table stickyHeader size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Nursery</TableCell>
                            <TableCell>Phone Number</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {children.map((child) => {
                            const { name, id, nursery } = child;
                            return (
                              <TableRow key={id}>
                                <TableCell>{name}</TableCell>
                                <TableCell>
                                  <Link target="_blank" to={`/nursery/${nursery.id}`}>
                                    <MaterialUiLink component="p">{nursery.name}</MaterialUiLink>
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  <MaterialUiLink
                                    component="a"
                                    href={`tel:${nursery.phoneNumber}`}
                                  >
                                    {nursery.phoneNumber}
                                  </MaterialUiLink>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </React.Fragment>
                );
              }}
            </LazyGraphqlQuery>
          </React.Fragment>
        );
      }}
    </GraphqlQuery>
  );
};
