import React from "react";
import styled from "styled-components";
import Fuzzysort from "fuzzysort";
import { FormControlLabel } from "@mui/material";
import { TextInput } from "components/inputs/Inputs";
import { Checkbox } from "components/inputs/Inputs";

const Flexbox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-bottom: 15px;
`;

/*
This lets us filter a table of nurseries by fuzzy searching the name of the nursery and by
selecting a checkbox which indicates whether the list of nurseries should include inactive
nurseries. By default we filter them out, but we query for all nurseries to keep the rest of
the operations in memory and not over the network.
*/
export const FilterableNurseriesTable = (props) => {
  const { data: allNurseries, children } = props;
  const [showInactive, setShowInactive] = React.useState(false);
  const [searchString, setSearchString] = React.useState("");
  const [nurseriesList, setNurseriesList] = React.useState(allNurseries);
  const [allActiveNurseries, setAllActiveNurseries] = React.useState([]);
  const [isFiltering, setIsFiltering] = React.useState(false);

  React.useEffect(() => {
    setAllActiveNurseries(allNurseries.filter((n) => n.isActive === true));
  }, [allNurseries, setAllActiveNurseries]);

  React.useEffect(() => {
    // We could just be like if search string is "" but this would allow for the case
    // of a search returning all results.
    if (showInactive) {
      setIsFiltering(allNurseries.length !== nurseriesList.length);
    } else {
      setIsFiltering(allActiveNurseries.length !== nurseriesList.length);
    }
  }, [allNurseries, showInactive, allActiveNurseries, setIsFiltering, nurseriesList]);

  React.useEffect(() => {
    const searchOpts = { keys: ["name"], threshold: -10000 };
    if (searchString.length === 0) {
      if (showInactive) {
        setNurseriesList(allNurseries);
      } else {
        setNurseriesList(allActiveNurseries);
      }
    } else {
      // we could use the nurseriesList each time which would speed up the search because you'd be
      // searching a smaller and smaller list each time... but it would require more space as we
      // would need to have all the previous lists so when we delete a letter we recover (ie step
      // back through time). This seems fraught with attack dangers like a long search string could
      // crash the app. So instead we always search the full data set.
      if (showInactive) {
        const searchResult = Fuzzysort.go(searchString, allNurseries, searchOpts);
        setNurseriesList(searchResult.map((result) => result.obj));
      } else {
        const searchResult = Fuzzysort.go(searchString, allActiveNurseries, searchOpts);
        setNurseriesList(searchResult.map((result) => result.obj));
      }
    }
  }, [allNurseries, allActiveNurseries, searchString, setNurseriesList, showInactive]);

  return (
    <>
      <Flexbox>
        <FormControlLabel
          label="Include Inactive?"
          control={
            <Checkbox
              color="secondary"
              checked={showInactive}
              onChange={() => setShowInactive(!showInactive)}
            />
          }
        />
        <TextInput
          style={{ width: "250px" }}
          variant="outlined"
          label="...Type to filter the nurseries"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
      </Flexbox>
      {children({ nurseriesList, isFiltering })}
    </>
  );
};
