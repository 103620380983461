import { gql } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import { FourOhFour } from "components/FourOhFour";
import { ChildrenWithSensitivities } from "containers/orders/ChildrenWithSensitivities";
import { GraphqlQuery } from "graphqlUtils/GraphqlQuery";
import { CreateNewTeaOrder } from "containers/orders/CreateNewTeaOrder";
import { GraphqlMutation } from "graphqlUtils/GraphqlMutation";
import { useGraphqlSubscription } from "graphqlUtils/useGraphqlSubscription";
import { Paper, Button, Typography } from "@mui/material";
import { every, some } from "lodash";
import { InfoNextToText } from "components/InfoNextToText";
import { TeaNumbersTable } from "containers/orders/TeaNumbersTable";
import { H3 } from "components/typography/H3";

const SubmitCancelButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 20px;
`;

const useStyles = makeStyles({
  paperStyles: { padding: "30px" },
  submitButton: { marginLeft: "20px" },
  spiel: { marginBottom: "30px" },
});

const SUBMIT_TEA_ORDER = gql`
  mutation SubmitTeaOrders($input: WeekDateNurseryInput!) {
    submitTeaOrders(input: $input) {
      ... on EditableTeaOrder {
        __typename
        id
      }
      ... on NonEditableTeaOrder {
        __typename
        id
      }
    }
  }
`;

const UNSUBMIT_TEA_ORDER = gql`
  mutation UnubmitTeaOrders($input: WeekDateNurseryInput!) {
    unsubmitTeaOrders(input: $input) {
      ... on EditableTeaOrder {
        __typename
        id
      }
      ... on NonEditableTeaOrder {
        __typename
        id
      }
    }
  }
`;

const DELETE_TEA_ORDER = gql`
  mutation DeleteTeaOrders($input: WeekDateNurseryInput!) {
    deleteTeaOrders(input: $input) {
      ... on EditableTeaOrder {
        id
      }
      ... on NonEditableTeaOrder {
        __typename
        id
      }
    }
  }
`;

const TEA_ORDERS = gql`
  query TeaOrdersByDate($nurseryId: ID!, $date: Date!) {
    oneWeeksOrders(date: $date, nurseryId: $nurseryId) {
      id
      childrenWithSensitivities {
        id
        name
        sensitivities {
          id
          name
          order
          readOnly
        }
      }
      teaOrders {
        ... on EditableTeaOrder {
          id
          date
          regularMeals
          specialMeals
          totalMeals
          closed
          submittedDatetime

          attendingChildren {
            id
            name
            closed
            childWithSensitivityId
          }
        }
        ... on NonEditableTeaOrder {
          id
          date
          regularMeals
          specialMeals
          totalMeals
          closed
          submittedDatetime
          attendingChildren {
            id
            name
            closed
            childWithSensitivityId
          }
        }
      }
    }
  }
`;

const OPEN_TIMES_SUBSCRIPTION = gql`
  subscription closed($nurseryId: ID!, $date: Date!) {
    openingTimes(nurseryId: $nurseryId, date: $date) {
      teaOrders {
        __typename
        ... on EditableTeaOrder {
          id
          closed
          regularMeals
          specialMeals
          totalMeals
          submittedDatetime

          attendingChildren {
            id
            closed
          }
        }
        ... on NonEditableTeaOrder {
          id
          closed
          regularMeals
          specialMeals
          totalMeals
          submittedDatetime

          attendingChildren {
            id
            closed
          }
        }
      }
    }
  }
`;

const UPDATE_CHILD_ATTENDANCE = gql`
  mutation UpdateTeaAttendance($input: UpdateTeaAttendanceInput!) {
    updateTeaAttendance(input: $input) {
      id
      name
      closed
      childWithSensitivityId
    }
  }
`;

export const TeaOrder = (props) => {
  const { params, onCloseModal, onOpenModal } = props;
  const { paperStyles, submitButton, spiel } = useStyles();
  const refetchQueries = [{ query: TEA_ORDERS, variables: params }];

  // This is really a OWO sub, and should be triggered by anything that changes an order
  // That way all changes propagate to every subscriber, and you see in real-time
  // admins adding children etc etc.
  useGraphqlSubscription({
    subscription: OPEN_TIMES_SUBSCRIPTION,
    variables: { nurseryId: params.nurseryId, date: params.date },
  });

  return (
    <GraphqlQuery query={TEA_ORDERS} variables={params} withLoading withError>
      {({ data, error }) => {
        if (error) {
          return <FourOhFour />;
        }
        const { oneWeeksOrders } = data;

        const allPast = every(
          oneWeeksOrders.teaOrders,
          (order) => order.__typename === "NonEditableTeaOrder",
        );

        const isSubmitted =
          some(
            oneWeeksOrders.teaOrders.filter(
              ({ __typename }) => __typename === "EditableTeaOrder",
            ),
            (order) => {
              // Admins need to be able to unsubmit once a thing has been auto-submitted to edit
              // it later. When we auto-submit we also put in a submitted datetime, so we can just
              // check for that here.
              return Boolean(order.submittedDatetime);
            },
          ) || allPast;

        const createPage = oneWeeksOrders.teaOrders.length === 0;
        const uniqueChildren = oneWeeksOrders.childrenWithSensitivities;
        const orderDays = ["Mon", "Tues", "Weds", "Thurs", "Fri"];

        const rows = uniqueChildren.map(({ id: childId, name, sensitivities }) => {
          const weekDays = oneWeeksOrders.teaOrders.reduce(
            (acc, { attendingChildren, id, closed, __typename, submittedDatetime }, index) => {
              const isIn = Boolean(
                attendingChildren.find((child) => {
                  return child.childWithSensitivityId === childId;
                }),
              );
              const weekday = orderDays[index];
              return {
                ...acc,
                [weekday]: {
                  isIn,
                  id,
                  closed,
                  __typename,
                  isSubmitted: Boolean(submittedDatetime),
                },
              };
            },
            {},
          );
          return { id: childId, sensitivities, name, ...weekDays };
        });

        return (
          <Paper elevation={1} square className={paperStyles}>
            {createPage ? (
              <CreateNewTeaOrder params={params} refetchQueries={refetchQueries} />
            ) : (
              <React.Fragment>
                <TeaNumbersTable oneWeeksOrders={oneWeeksOrders} />
                <H3>Children With Sensitivities</H3>
                <Typography className={spiel} variant="body2" component="p">
                  <InfoNextToText />
                  &nbsp; Although we don&apos;t provide substitutions for tea orders, it is
                  important that you still tell us what a child can and cannot eat, and which
                  children will be in so that we are sure not to give anything to a child that
                  they cannot eat.
                </Typography>
                <GraphqlMutation mutation={UPDATE_CHILD_ATTENDANCE}>
                  {(mutation) => {
                    const onAttendanceUpdate = async (teaOrderId, childId) => {
                      const response = await mutation({
                        refetchQueries,
                        variables: { input: { teaOrderId, childId } },
                      });
                      return response;
                    };
                    return (
                      <ChildrenWithSensitivities
                        params={params}
                        refetchQueries={refetchQueries}
                        rows={rows}
                        oneWeeksOrders={oneWeeksOrders}
                        onCloseModal={onCloseModal}
                        onOpenModal={onOpenModal}
                        onAttendanceUpdate={onAttendanceUpdate}
                        allPast={isSubmitted}
                      />
                    );
                  }}
                </GraphqlMutation>
                {isSubmitted ? (
                  <SubmitCancelButtons>
                    <GraphqlMutation mutation={UNSUBMIT_TEA_ORDER} withError>
                      {(unSubmitOrder) => {
                        const variables = {
                          input: { nurseryId: params.nurseryId, startDate: params.date },
                        };
                        return (
                          <Button
                            className={submitButton}
                            variant="contained"
                            color="primary"
                            aria-label="add"
                            onClick={() => unSubmitOrder({ variables, refetchQueries })}
                          >
                            Undo Submission
                          </Button>
                        );
                      }}
                    </GraphqlMutation>
                  </SubmitCancelButtons>
                ) : (
                  <SubmitCancelButtons>
                    <GraphqlMutation mutation={DELETE_TEA_ORDER} withError>
                      {(deleteOrder, { loading }) => {
                        const variables = {
                          input: { nurseryId: params.nurseryId, startDate: params.date },
                        };
                        return (
                          <Button
                            disabled={allPast || loading}
                            variant="outlined"
                            color="secondary"
                            aria-label="add"
                            onClick={() => {
                              if (window.confirm("Are you sure you want to delete the order?")) {
                                return deleteOrder({ variables, refetchQueries });
                              }
                            }}
                          >
                            Delete Order
                          </Button>
                        );
                      }}
                    </GraphqlMutation>
                    <GraphqlMutation mutation={SUBMIT_TEA_ORDER} withError>
                      {(submitOrder) => {
                        const variables = {
                          input: { nurseryId: params.nurseryId, startDate: params.date },
                        };
                        return (
                          <Button
                            className={submitButton}
                            variant="outlined"
                            color="primary"
                            aria-label="add"
                            onClick={() => submitOrder({ variables, refetchQueries })}
                          >
                            Submit Order
                          </Button>
                        );
                      }}
                    </GraphqlMutation>
                  </SubmitCancelButtons>
                )}
              </React.Fragment>
            )}
          </Paper>
        );
      }}
    </GraphqlQuery>
  );
};
